import 'react-tooltip/dist/react-tooltip.css'
import React, { useEffect, useState } from "react";
import {
  Field,
  Form,
  FormikProvider,
  useFormik,
} from "formik";
import * as Yup from "yup";
import BtnSpinner from "../../../../utils/BtnSpinner";
import { Back } from "../../../../assets/img";
import {
  CustomInputComponent,
  CustomSelectComponent,
  CustomCheckbox,
  CustomRadioComponent,
  SingleFileInputComponent,
} from "../../../../components/formik/FieldComponents";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { UPDATE_BANK_DETAILS, 
        UPDATE_BANK_DETAILS_IC, 
        UPDATE_BANK_DETAILS_HUF, 
        UPDATE_BANK_DETAILS_FC, 
        UPDATE_BANK_DETAILS_FCorp } from "../../../../store/actions";
import {
  FetchAllBanks,
  SaveBankInformation,
  FetchAcountTypes,
  GetUserDetailsUpdated
} from "../../../../api";
import { Tooltip } from 'react-tooltip';
import { getCMRRequiredKey, isRequired } from '../../../../utils/utils';
import { getConstants } from '../../../../utils/constants';

function  BankDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkUSerType = useSelector((state) => state.userDetails);
  const [accountType, setAccountType] = useState(null)
  const [showTIN, setShowTIN] = useState(false)
  const [bankData, setBankData] = useState(null)
  const [showDemat, setShowDemat] = useState(false)
  const [requiredFields, setRequiredFields] = useState([])
 
  const [checkDirectBank, setcheckDirectBank] = React.useState(true);

  const bankDetails = useSelector(
    (state) => state.indianCitizenship.bankDetails
  );
  const bankDetailsIC = useSelector(
    (state) => state.indianCorporate.bankDetails
  );
  const bankDetailsHUF = useSelector(
    (state) => state.indianhuf.bankDetails
  );
  const bankDetailsFC = useSelector(
    (state) => state.foreignCitizenship.bankDetails
  );
  const bankDetailsFCorp = useSelector(
    (state) => state.foreignCorporate.bankDetails
  );

  const { data: userData } = GetUserDetailsUpdated();
  const { data: banks } = FetchAllBanks();
  const { data: acountTypesData } = FetchAcountTypes({ accountType: checkUSerType.userType });
  const {mutateAsync: saveBankInformation,isLoading: isSaveBankInformationLoading,} = SaveBankInformation();

  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (userData != null) {
      setRequiredFields(userData.validation_fields)
      if (userData.data != null) {
        let data = userData.data
        let firstname = data.first_name;
        let middlename = data.middle_name;
        let lastname = data.last_name;
        let fullname = null;
        if (middlename == undefined || middlename?.trim()?.length == 0) {
          fullname = firstname + ' ' + lastname
        } else {
          fullname = firstname + ' ' + middlename + ' ' + lastname;
        }
        console.log("fullname", fullname)
        if (data.bankAccounts.length > 0) {
          let bank = data.bankAccounts[0];
          let bankName = null
          let ifscCode = null
          let swiftCode = null
          let accountType = null
          if (bank.account_type_id != null) {
            if (bank?.account_type_id == '63aac72be36fdf0309e81b61' || bank?.account_type_id == '63aac748e36fdf0309e81b65' || bank?.account_type_id == '63aac765e36fdf0309e81b6c' || bank?.account_type_id == '63aac76ce36fdf0309e81b6f' || bank?.account_type_id == '63aac75ae36fdf0309e81b69') {
              bankName = bank?.bank_id?._id
              ifscCode = bank?.ifsc_code
            } else {
              bankName = bank?.bank_name
              swiftCode = bank?.swift_code
            }
          } else {
            if (data?.user_individual_type == 'indian_citizen' || data?.user_individual_type == 'indian_corporate' || data?.user_individual_type == 'huf') {
              bankName = bank?.bank_id?._id
              ifscCode = bank?.ifsc_code
            } else {
              bankName = bank?.bank_name
              swiftCode = bank?.swift_code
            }
          }
          if (acountTypesData?.filter(item => item.value == bank?.account_type_id)?.length == 0) {
            accountType = null
          } else {
            accountType = bank?.account_type_id != null ? bank.account_type_id : null
          }

          let jointAccounts = bank.accounts;
          let firstJointHolder = null
          if (jointAccounts.length > 0) {
            firstJointHolder = jointAccounts[0];
            setjhDetailsCheckbox(true);
          }
          const docs = data?.documents?.filter(
            (doc) => doc.slug === "onboarding_documents"
          );
          console.log("docs", docs)
          let cmr = docs[0]?.documents?.filter(item => { return item.upload_type == "client_master_report" })
          console.log("cmr", cmr)

          if (checkUSerType.userType == "indian_citizen") {
            dispatch({
              type: UPDATE_BANK_DETAILS,
              payload: {
                bankName: bankName,
                accountHolderName: bank?.account_holder_name,
                accountNumber: bank?.bank_account_number,
                ifscCode: ifscCode,
                bankBranch: bank?.branch_name,
                accountType: accountType,
                jhDetailsCheckbox: jointAccounts.length > 0,
                bank_name: bankName,
                swift_code: swiftCode,
                tin_number: data.tin_number,
                jointHolderName: firstJointHolder?.joint_holder_name,
                jointHolderMobileNumber: firstJointHolder?.joint_holder_mobile_number,
                jointHolderEmail: firstJointHolder?.joint_holder_email,
                jointHolderPAN: firstJointHolder?.joint_holder_pan_number,
                // id_name: bank?.demat_account?.id_name,
                dp_id: bank?.demat_account?.dp_id,
                customer_id: bank?.demat_account?.customer_id,
                client_id: bank?.demat_account?.client_id,
                account_details_type: bank?.demat_account?.account_details_type,
                demat_name: fullname,
                fileCMR: cmr
              },
            });
          } else if (checkUSerType.userType == "indian_corporate") {
            dispatch({
              type: UPDATE_BANK_DETAILS_IC,
              payload: {
                bankName: bankName,
                accountHolderName: bank?.account_holder_name,
                accountNumber: bank?.bank_account_number,
                ifscCode: ifscCode,
                bankBranch: bank?.branch_name,
                accountType: accountType,
                jhDetailsCheckbox: jointAccounts.length > 0,
                bank_name: bankName,
                swift_code: swiftCode,
                tin_number: data.tin_number,
                jointHolderName: firstJointHolder?.joint_holder_name,
                jointHolderMobileNumber: firstJointHolder?.joint_holder_mobile_number,
                jointHolderEmail: firstJointHolder?.joint_holder_email,
                jointHolderPAN: firstJointHolder?.joint_holder_pan_number,
                // id_name: bank?.demat_account?.id_name,
                dp_id: bank?.demat_account?.dp_id,
                customer_id: bank?.demat_account?.customer_id,
                client_id: bank?.demat_account?.client_id,
                account_details_type: bank?.demat_account?.account_details_type,
                demat_name: fullname,
                fileCMR: cmr
              },
            });
          } else if (checkUSerType.userType == "huf") {
            dispatch({
              type: UPDATE_BANK_DETAILS_HUF,
              payload: {
                bankName: bankName,
                accountHolderName: bank?.account_holder_name,
                accountNumber: bank?.bank_account_number,
                ifscCode: ifscCode,
                bankBranch: bank?.branch_name,
                accountType: accountType,
                jhDetailsCheckbox: jointAccounts.length > 0,
                bank_name: bankName,
                swift_code: swiftCode,
                tin_number: data.tin_number,
                jointHolderName: firstJointHolder?.joint_holder_name,
                jointHolderMobileNumber: firstJointHolder?.joint_holder_mobile_number,
                jointHolderEmail: firstJointHolder?.joint_holder_email,
                jointHolderPAN: firstJointHolder?.joint_holder_pan_number,
                // id_name: bank?.demat_account?.id_name,
                dp_id: bank?.demat_account?.dp_id,
                customer_id: bank?.demat_account?.customer_id,
                client_id: bank?.demat_account?.client_id,
                account_details_type: bank?.demat_account?.account_details_type,
                demat_name: fullname,
                fileCMR: cmr
              },
            });
          } else if (checkUSerType.userType == "foreign_citizen") {
            dispatch({
              type: UPDATE_BANK_DETAILS_FC,
              payload: {
                bankName: bankName,
                accountHolderName: bank?.account_holder_name,
                accountNumber: bank?.bank_account_number,
                ifscCode: ifscCode,
                bankBranch: bank?.branch_name,
                accountType: accountType,
                jhDetailsCheckbox: jointAccounts.length > 0,
                bank_name: bankName,
                swift_code: swiftCode,
                tin_number: data.tin_number,
                jointHolderName: firstJointHolder?.joint_holder_name,
                jointHolderMobileNumber: firstJointHolder?.joint_holder_mobile_number,
                jointHolderEmail: firstJointHolder?.joint_holder_email,
                jointHolderPAN: firstJointHolder?.joint_holder_pan_number,
                // id_name: bank?.demat_account?.id_name,
                dp_id: bank?.demat_account?.dp_id,
                customer_id: bank?.demat_account?.customer_id,
                client_id: bank?.demat_account?.client_id,
                account_details_type: bank?.demat_account?.account_details_type,
                demat_name: fullname,
                fileCMR: cmr
              },
            });
          } else {
            dispatch({
              type: UPDATE_BANK_DETAILS_FCorp,
              payload: {
                bankName: bankName,
                accountHolderName: bank?.account_holder_name,
                accountNumber: bank?.bank_account_number,
                ifscCode: ifscCode,
                bankBranch: bank?.branch_name,
                accountType: accountType,
                jhDetailsCheckbox: jointAccounts.length > 0,
                bank_name: bankName,
                swift_code: swiftCode,
                tin_number: data.tin_number,
                jointHolderName: firstJointHolder?.joint_holder_name,
                jointHolderMobileNumber: firstJointHolder?.joint_holder_mobile_number,
                jointHolderEmail: firstJointHolder?.joint_holder_email,
                jointHolderPAN: firstJointHolder?.joint_holder_pan_number,
                // id_name: bank?.demat_account?.id_name,
                dp_id: bank?.demat_account?.dp_id,
                customer_id: bank?.demat_account?.customer_id,
                client_id: bank?.demat_account?.client_id,
                account_details_type: bank?.demat_account?.account_details_type,
                demat_name: fullname,
                fileCMR: cmr
              },
            });
          }
        }
      }
    }
  }, [userData])

  useEffect(() => {
    if (checkUSerType.userType == "indian_citizen") {
      setBankData(bankDetails)
    } else if (checkUSerType.userType == "indian_corporate") {
      setBankData(bankDetailsIC)
    } else if (checkUSerType.userType == "huf") {
      setBankData(bankDetailsHUF)
    } else if (checkUSerType.userType == "foreign_citizen") {
      setBankData(bankDetailsFC)
    } else {
      setBankData(bankDetailsFCorp)
    }
  }, [bankDetails, bankDetailsIC, bankDetailsHUF, bankDetailsFC, bankDetailsFCorp])


  // const BankDetailsValidationSchema = {
  //   accountType: Yup.string().required("Account Type is required"),

  //   bankName: issetValidationData
  //     ? Yup.string().required("Please Select bank")
  //     : Yup.string(),
  //   ifscCode: issetValidationData
  //     ? Yup.string().required("Please enter ifsc code")
  //     : Yup.string(),

  //   bank_name: !issetValidationData
  //     ? Yup.string().required("Please enter bank name")
  //     : Yup.string(),
  //   swift_code: !issetValidationData
  //     ? Yup.string().required("Please enter swift  code")
  //     : Yup.string(),
  //   tin_number: showTIN
  //     ? Yup.string().required("Please enter TIN")
  //     : Yup.string(),

  //   accountHolderName: Yup.string().required("Account holder name is required"),
  //   accountNumber: Yup.string().required("Account number is required"),
  //   bankBranch: Yup.string().required("Bank branch is required"),
  // };
 
  const [issetValidationData, setValidationData] = useState(true);

  const [isjhDetailsCheckbox, setjhDetailsCheckbox] = useState(false);

  const BankDetailsIndianValidationWithTINSchema = {
    accountType: Yup.string().required("Account Type is required"),

    bankName: Yup.string().required("Please Select bank"),
    ifscCode: Yup.string().required("Please enter ifsc code"),

    accountHolderName: Yup.string().required("Account holder name is required"),
    accountNumber: Yup.string().required("Account number is required"),
    bankBranch: Yup.string().required("Bank branch is required"),
    tin_number: Yup.string().required("Please enter TIN")
  };

  const BankDetailsForeignValidationWithTINSchema = {
    accountType: Yup.string().required("Account Type is required"),

    bank_name: Yup.string().required("Please enter bank name"),
    swift_code: Yup.string().required("Please enter swift  code"),

    accountHolderName: Yup.string().required("Account holder name is required"),
    accountNumber: Yup.string().required("Account number is required"),
    bankBranch: Yup.string().required("Bank branch is required"),
    tin_number: Yup.string().required("Please enter TIN")
  };

  const BankDetailsIndianValidationWithoutTINSchema = {
    accountType: Yup.string().required("Account Type is required"),

    bankName: Yup.string().required("Please Select bank"),
    ifscCode: Yup.string().required("Please enter ifsc code"),

    accountHolderName: Yup.string().required("Account holder name is required"),
    accountNumber: Yup.string().required("Account number is required"),
    bankBranch: Yup.string().required("Bank branch is required"),
  };

  const BankDetailsForeignValidationWithoutTINSchema = {
    accountType: Yup.string().required("Account Type is required"),

    bank_name: Yup.string().required("Please enter bank name"),
    swift_code: Yup.string().required("Please enter swift  code"),

    accountHolderName: Yup.string().required("Account holder name is required"),
    accountNumber: Yup.string().required("Account number is required"),
    bankBranch: Yup.string().required("Bank branch is required"),
  };

  const DemateDetailsValidationSchema = {
    // id_name: Yup.string().required("Name as per ID proof is required"),
    account_details_type: isRequired(requiredFields, "demat_account[account_details_type]") ? Yup.string().required("Account details is required") : Yup.string(),
    dp_id: isRequired(requiredFields, "demat_account[dp_id]") ? Yup.string().required("DP ID is required") : Yup.string(),
    customer_id: isRequired(requiredFields, "demat_account[customer_id]") ? Yup.string().when("account_details_type", {
      is: (account_details_type) => { return account_details_type == "NSDL" },
      then: () => Yup.string().required("Customer ID is required")
    }) : Yup.string(),
    client_id: isRequired(requiredFields, "demat_account[client_id]") ? Yup.string().when("account_details_type", {
      is: (account_details_type) => { return account_details_type == "CDSL" },
      then: () => Yup.string().required("Customer ID is required")
    }) : Yup.string(),
    demat_name: isRequired(requiredFields, "demat_account[demat_name]") ? Yup.string().required("Name as per Demat account is required") : Yup.string(),
    fileCMR: isRequired(requiredFields, getCMRRequiredKey(bankData?.accountType)) ? Yup.array().min(1, 'Client Master Report is required').required('Client Master Report is required') : Yup.array()
  }

  const BankDetailsIndianValidationSchema = showTIN ? BankDetailsIndianValidationWithTINSchema : BankDetailsIndianValidationWithoutTINSchema 

  const BankDetailsForeignValidationSchema = showTIN ? BankDetailsForeignValidationWithTINSchema : BankDetailsForeignValidationWithoutTINSchema 

  const BankDetailsValidationSchema = issetValidationData ? BankDetailsIndianValidationSchema : BankDetailsForeignValidationSchema

  const BankInfoValidationSchema = showDemat ? { ...BankDetailsValidationSchema, ...DemateDetailsValidationSchema } : BankDetailsValidationSchema

  const JointHolderDetailsValidationSchema = {
    jointHolderName: Yup.string().required("Account holder name is required"),
    jointHolderMobileNumber: Yup.string().required("Mobile number is required"),
    jointHolderEmail: Yup.string()
      .email("Enter valid email")
      .required("Email address is required"),
    jointHolderPAN: accountType != '64b0edd07bfb212e65f2d406' ? Yup.string().required("Pan number is required") : Yup.string(),
  };

  const getValidationSchema = () => {
    let validations = {}
    if (!isjhDetailsCheckbox) {
      validations = BankInfoValidationSchema
    } else {
      validations = {
        ...BankInfoValidationSchema,
        ...JointHolderDetailsValidationSchema,
      }
    }
    return Yup.object().shape(validations)
  }

  const formik = useFormik({
    initialValues: bankData,
    enableReinitialize: true,
    validationSchema: getValidationSchema(),
    onSubmit: (values) => {

      let bankPayload = new FormData();

      bankPayload.append("account_holder_name", values.accountHolderName)
      bankPayload.append("account_type_id", values.accountType)
      bankPayload.append("bank_account_number", values.accountNumber)
      bankPayload.append("branch_name", values.bankBranch)
      bankPayload.append("ifsc_code", values.ifscCode)
      // bankPayload.append("bank_name", values.bank_name)
      bankPayload.append("swift_code", values.swift_code)
      bankPayload.append("tin_number", values.tin_number)
      bankPayload.append("page", "3")
      bankPayload.append("re_submit", false)
      bankPayload.append("user_type", "investor")

      if (accountType == '63aac72be36fdf0309e81b61' || accountType == '63aac748e36fdf0309e81b65' || accountType == '63aac765e36fdf0309e81b6c' || accountType == '63aac76ce36fdf0309e81b6f' || accountType == '63aac75ae36fdf0309e81b69') {
        bankPayload.append("bank_id", values.bankName)
      } else {
        bankPayload.append("bank_name", values.bank_name)
      }

      if (Boolean(values.jhDetailsCheckbox)) {
        bankPayload.append("accounts[0][joint_holder_email]", values.jointHolderEmail)
        bankPayload.append("accounts[0][joint_holder_mobile_number]", values.jointHolderMobileNumber)
        bankPayload.append("accounts[0][joint_holder_name]", values.jointHolderName)
        bankPayload.append("accounts[0][joint_holder_pan_number]", values.jointHolderPAN)
        bankPayload.append("accounts[0][phone_code]", null)
      }

      // bankPayload.append("demat_account[id_name]", values.id_name != null && values.id_name != undefined && values.id_name != "undefined" ? values.id_name : "")
      bankPayload.append("demat_account[dp_id]", values.dp_id != null && values.dp_id != undefined && values.dp_id != "undefined" ? values.dp_id : "")
      bankPayload.append("demat_account[customer_id]", values.customer_id != null && values.customer_id != undefined && values.customer_id != "undefined" ? values.customer_id : "")
      bankPayload.append("demat_account[client_id]", values.client_id != null && values.client_id != undefined && values.client_id != "undefined" ? values.client_id : "")
      bankPayload.append("demat_account[account_details_type]", values.account_details_type)
      bankPayload.append("demat_account[demat_name]", values.demat_name != null && values.demat_name != undefined && values.demat_name != "undefined" ? values.demat_name : "")

      if (values?.fileCMR?.length > 0) {
        let file = values.fileCMR[0]
        if (file?.size) {
          bankPayload.append("name[]", "Client Master Report")
          bankPayload.append("documents", file);
          bankPayload.append("upload_type[]", "client_master_report");
        } else {
          // bankPayload.append("name[]", file.name);
          // bankPayload.append(
          //   `documents[${file.document_id}]`,
          //   ""
          // );
          // bankPayload.append("upload_type[]", file.upload_type);
        }
      }

      // if (values.bankName) {
      //   bankPayload.append("bank_id", values.bankName)
      // }
      saveBankInformation(
        {
          payload: bankPayload,
        },
        {
          onSuccess: () => {
            if (checkUSerType.userType == "indian_citizen") {
              dispatch({
                type: UPDATE_BANK_DETAILS,
                payload: {
                  bankName: values.bankName,
                  accountHolderName: values.accountHolderName,
                  accountNumber: values.accountNumber,
                  ifscCode: values.ifscCode,
                  bankBranch: values.bankBranch,
                  accountType: values.accountType,
                  jhDetailsCheckbox: values.jhDetailsCheckbox,
                  bank_name: values.bank_name,
                  swift_code: values.swift_code,
                  tin_number: values.tin_number,
                  jointHolderName:
                    (values.jhDetailsCheckbox && values.jointHolderName) || "",
                  jointHolderMobileNumber:
                    (values.jhDetailsCheckbox &&
                      values.jointHolderMobileNumber) ||
                    "",
                  jointHolderEmail:
                    (values.jhDetailsCheckbox && values.jointHolderEmail) || "",
                  jointHolderPAN:
                    (values.jhDetailsCheckbox && values.jointHolderPAN) || "",
                  // id_name: values.id_name,
                  dp_id: values.dp_id,
                  customer_id: values.customer_id,
                  client_id: values.client_id,
                  account_details_type: values.account_details_type,
                  demat_name: values?.demat_name,
                  fileCMR: values.fileCMR
                },
              });
            } else if (checkUSerType.userType == "indian_corporate") {
              dispatch({
                type: UPDATE_BANK_DETAILS_IC,
                payload: {
                  bankName: values.bankName,
                  accountHolderName: values.accountHolderName,
                  accountNumber: values.accountNumber,
                  ifscCode: values.ifscCode,
                  bankBranch: values.bankBranch,
                  accountType: values.accountType,
                  jhDetailsCheckbox: values.jhDetailsCheckbox,
                  bank_name: values.bank_name,
                  swift_code: values.swift_code,
                  tin_number: values.tin_number,
                  jointHolderName:
                    (values.jhDetailsCheckbox && values.jointHolderName) || "",
                  jointHolderMobileNumber:
                    (values.jhDetailsCheckbox &&
                      values.jointHolderMobileNumber) ||
                    "",
                  jointHolderEmail:
                    (values.jhDetailsCheckbox && values.jointHolderEmail) || "",
                  jointHolderPAN:
                    (values.jhDetailsCheckbox && values.jointHolderPAN) || "",
                  // id_name: values.id_name,
                  dp_id: values.dp_id,
                  customer_id: values.customer_id,
                  client_id: values.client_id,
                  account_details_type: values.account_details_type,
                  demat_name: values?.demat_name,
                  fileCMR: values.fileCMR
                },
              });
            } else if (checkUSerType.userType == "huf") {
              dispatch({
                type: UPDATE_BANK_DETAILS_HUF,
                payload: {
                  bankName: values.bankName,
                  accountHolderName: values.accountHolderName,
                  accountNumber: values.accountNumber,
                  ifscCode: values.ifscCode,
                  bankBranch: values.bankBranch,
                  accountType: values.accountType,
                  jhDetailsCheckbox: values.jhDetailsCheckbox,
                  bank_name: values.bank_name,
                  swift_code: values.swift_code,
                  tin_number: values.tin_number,
                  jointHolderName:
                    (values.jhDetailsCheckbox && values.jointHolderName) || "",
                  jointHolderMobileNumber:
                    (values.jhDetailsCheckbox &&
                      values.jointHolderMobileNumber) ||
                    "",
                  jointHolderEmail:
                    (values.jhDetailsCheckbox && values.jointHolderEmail) || "",
                  jointHolderPAN:
                    (values.jhDetailsCheckbox && values.jointHolderPAN) || "",
                  // id_name: values.id_name,
                  dp_id: values.dp_id,
                  customer_id: values.customer_id,
                  client_id: values.client_id,
                  account_details_type: values.account_details_type,
                  demat_name: values?.demat_name,
                  fileCMR: values.fileCMR
                },
              });
            } else if (checkUSerType.userType == "foreign_citizen") {
              dispatch({
                type: UPDATE_BANK_DETAILS_FC,
                payload: {
                  bankName: values.bankName,
                  accountHolderName: values.accountHolderName,
                  accountNumber: values.accountNumber,
                  ifscCode: values.ifscCode,
                  bankBranch: values.bankBranch,
                  accountType: values.accountType,
                  jhDetailsCheckbox: values.jhDetailsCheckbox,
                  bank_name: values.bank_name,
                  swift_code: values.swift_code,
                  tin_number: values.tin_number,
                  jointHolderName:
                    (values.jhDetailsCheckbox && values.jointHolderName) || "",
                  jointHolderMobileNumber:
                    (values.jhDetailsCheckbox &&
                      values.jointHolderMobileNumber) ||
                    "",
                  jointHolderEmail:
                    (values.jhDetailsCheckbox && values.jointHolderEmail) || "",
                  jointHolderPAN:
                    (values.jhDetailsCheckbox && values.jointHolderPAN) || "",
                  // id_name: values.id_name,
                  dp_id: values.dp_id,
                  customer_id: values.customer_id,
                  client_id: values.client_id,
                  account_details_type: values.account_details_type,
                  demat_name: values?.demat_name,
                  fileCMR: values.fileCMR
                },
              });
            } else {
              dispatch({
                type: UPDATE_BANK_DETAILS_FCorp,
                payload: {
                  bankName: values.bankName,
                  accountHolderName: values.accountHolderName,
                  accountNumber: values.accountNumber,
                  ifscCode: values.ifscCode,
                  bankBranch: values.bankBranch,
                  accountType: values.accountType,
                  jhDetailsCheckbox: values.jhDetailsCheckbox,
                  bank_name: values.bank_name,
                  swift_code: values.swift_code,
                  tin_number: values.tin_number,
                  jointHolderName:
                    (values.jhDetailsCheckbox && values.jointHolderName) || "",
                  jointHolderMobileNumber:
                    (values.jhDetailsCheckbox &&
                      values.jointHolderMobileNumber) ||
                    "",
                  jointHolderEmail:
                    (values.jhDetailsCheckbox && values.jointHolderEmail) || "",
                  jointHolderPAN:
                    (values.jhDetailsCheckbox && values.jointHolderPAN) || "",
                  // id_name: values.id_name,
                  dp_id: values.dp_id,
                  customer_id: values.customer_id,
                  client_id: values.client_id,
                  account_details_type: values.account_details_type,
                  demat_name: values?.demat_name,
                  fileCMR: values.fileCMR
                },
              });
            }

            // dispatch({ type: UPDATE_BANK_DETAILS, payload });
            // dispatch({ type: SET_PAGE, payload: 4 });
            // if (checkDirectBank) {
            //   navigate("/indian-citizenship/upload-documents");
            // } else {
            //   navigate("/registration-status");
            // }
              navigate("/upload-documents");

          },
        }
      );
    },
  });

  // useEffect(() => {
  //   if (Cookies.get("redirectPath") != null) {
  //     const redirectPath = Cookies.get("redirectPath");
  //     if (redirectPath == "demat") {
  //       setcheckDirectBank(false);
  //       setIndex(0);
  //       Cookies.remove("redirectPath");
  //     }
  //   }
  // }, []);
  
  useEffect(() => {
    setAccountType(formik?.values?.accountType)
  }, [formik?.values?.accountType]);

  useEffect(() => {
    if (accountType != null) {
      if (accountType == '63aac72be36fdf0309e81b61' || accountType == '63aac748e36fdf0309e81b65' || accountType == '63aac765e36fdf0309e81b6c' || accountType == '63aac76ce36fdf0309e81b6f' || accountType == '63aac75ae36fdf0309e81b69') {
        setValidationData(true);
      } else {
        setValidationData(false);
      }
      if (accountType == '63aac748e36fdf0309e81b65' || accountType == '64b0edd07bfb212e65f2d406') {
        setjhDetailsCheckbox(true);
      } else {
        setjhDetailsCheckbox(false);
      }

      let tinShow = accountType == '646614e0c29583a3a4446df0' || accountType == '64b0edd07bfb212e65f2d406' || accountType == '646614c8c29583a3a4446dee' || accountType == '63aac765e36fdf0309e81b6c' || accountType == '6454e32e24efe8b5be5215fd' || accountType == '63aac76ce36fdf0309e81b6f'
      setShowTIN(tinShow)
      let dematAccount = accountType == '63aac72be36fdf0309e81b61' || accountType == '63aac748e36fdf0309e81b65' || accountType == '63aac75ae36fdf0309e81b69' || accountType == '63aac765e36fdf0309e81b6c' || accountType == '63aac76ce36fdf0309e81b6f'
      setShowDemat(dematAccount)
    } else {
      if (checkUSerType.userType == 'indian_citizen' || checkUSerType.userType == 'indian_corporate' || checkUSerType.userType == 'huf') {
        setjhDetailsCheckbox(true);
      } else {
        setjhDetailsCheckbox(false);
      }
    }
  }, [accountType])

  useEffect(() => {
    if (formik?.values) {
      formik.values.jhDetailsCheckbox = isjhDetailsCheckbox;
    }
  }, [isjhDetailsCheckbox])
  

  useEffect(() => {
    if (formik?.values?.fileCMR?.length > 0) {
      console.log("file", formik?.values?.fileCMR[0])
    }
  }, [formik?.values?.fileCMR])

  // useEffect(() => {
  //   setAccountType(formik?.values?.accountType)
  //   var nreAccount = [
  //     "63aac765e36fdf0309e81b6c",
  //     "6454e32e24efe8b5be5215fd",
  //     "63aac76ce36fdf0309e81b6f",
  //   ];
  //   if (nreAccount.includes(formik.values.accountType)) {
  //     setValidationData(false);
  //   } else {
  //     setValidationData(true);
  //   }

  //   if (formik.values.accountType === "63aac748e36fdf0309e81b65" || formik.values.accountType === "64b0edd07bfb212e65f2d406") {
  //     //formik.values.jhDetailsCheckbox = true;
  //     setjhDetailsCheckbox(true);
  //   } else {
  //     formik.values.jhDetailsCheckbox = false;
  //     setjhDetailsCheckbox(false);
  //   }
  // }, [formik.values.accountType]);

  // useEffect(() => {
  //   if (formik?.values?.accountType) {
  //     console.log("accountType", formik?.values?.accountType)
  //     let accountType = formik?.values?.accountType
  //     let tinShow = accountType == '646614e0c29583a3a4446df0' || accountType == '64b0edd07bfb212e65f2d406' || accountType == '646614c8c29583a3a4446dee' || accountType == '63aac765e36fdf0309e81b6c' || accountType == '6454e32e24efe8b5be5215fd' || accountType == '63aac76ce36fdf0309e81b6f'
  //     setShowTIN(tinShow)
  //     let dematAccount = accountType == '63aac72be36fdf0309e81b61' || accountType == '63aac748e36fdf0309e81b65' || accountType == '63aac75ae36fdf0309e81b69' || accountType == '63aac765e36fdf0309e81b6c' || accountType == '63aac76ce36fdf0309e81b6f'
  //     setShowDemat(dematAccount)
  //   }
  // }, [formik.values.accountType])

  return (
    <section className="mid-wrap">
      <div className="forms-header border-b pt-3 pb-3">
        <div className="container">
          {
            checkDirectBank ?
              <>
                <div className="row align-items-center">
                  <div className="col-md-2 col-2">
                    {index === 0 ? (
                      <Link
                        to="/residential-address"
                        className="back-arrow"
                      >
                        <img src={Back} alt="back" />
                      </Link>
                    ) : (
                      <div className="back-arrow cursor-pointer" onClick={() => setIndex(0)}>
                        <img src={Back} alt="back" />
                      </div>
                    )}
                  </div>
                  <div className="col-md-8 col-8">
                    <div className="lg-title text-center">Complete Your KYC</div>
                  </div>
                  <div className="col-md-2 col-2 text-end">
                    <span className="pl-15 sm-title">{(checkUSerType.userType == 'indian_citizen' || checkUSerType.userType == 'huf' || checkUSerType.userType == 'foreign_citizen') ? '3/6' : '3/5'}</span>
                  </div>
                </div>
                <div className="text-center step-process mt-1">
                  <div className="d-inline-flex">
                    <span className="process-step active"></span>
                    <span className="process-step active"></span>
                    <span className="process-step selected"></span>
                    <span className="process-step"></span>
                    <span className="process-step"></span>
                    {(checkUSerType.userType == 'indian_citizen' || checkUSerType.userType == 'huf' || checkUSerType.userType == 'foreign_citizen') && <span className="process-step"></span>}

                  </div>
                </div>
              </> :
              <>
                <div className="row align-items-center">
                  <div className="col-md-2 col-2">
                    <div className="col-md-2 col-2">
                      {index === 1 && (
                        <div className="back-arrow cursor-pointer" onClick={() => setIndex(0)}>
                          <img src={Back} alt="back" />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-8 col-8">
                    <div className="lg-title text-center">Complete Your KYC</div>
                  </div>
                  <div className="col-md-2 col-2 text-end"></div>
                </div>
                <div className="text-center step-process mt-1"></div>
              </>
          }
          {index === 0 && (
            <>
              <h5 className="card-title md-title mt-2 mb-0 heading-formobile">
                Bank Details
              </h5>
            </>
          )}
          {index === 1 && formik.values.jhDetailsCheckbox && (
            <h5 className="card-title md-title mt-2 mb-0 heading-formobile">
              Joint Holder Details
            </h5>
          )}
        </div>
      </div>
      <div className="pt-0 pb-5 kyc-form-block">
        <div className="container">
          <div className="card form-card mt-4">
            <div className="card-body">
              <FormikProvider value={formik} sx={{ flex: 1 }}>
                <Form className="custom-form" onSubmit={formik.handleSubmit}>
                  <div>
                    {index === 0 && (
                      <>
                        <div className="row">
                          <h5 className="card-title md-title mb-4 text-center heading-fordesktop">
                            Bank Details
                          </h5>

                          {/* Account type */}
                          <div className="col-md-6 form-group">
                            <label className="required">Account Type</label>
                            <Field
                              name="accountType"
                              component={CustomSelectComponent}
                              options={acountTypesData}
                              className="form-control"
                              onChange={(e) => {
                                console.log('Value', e.target.value);
                                formik?.setFieldValue('accountType', e.target.value)
                                formik?.setFieldValue('bankName', "")
                                formik?.setFieldValue('bank_name', "")
                              }}
                            />
                          </div>

                          {issetValidationData ? (
                            <div className="col-md-6 form-group">
                              <label className="required">Bank Name</label>
                              <Field
                                name="bankName"
                                component={CustomSelectComponent}
                                options={banks}
                                className="form-control"
                              />
                            </div>
                          ) : (
                            <div className="col-md-6 form-group">
                              <label className="required">Bank Name</label>
                              <Field
                                name="bank_name"
                                component={CustomInputComponent}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          )}

                          {/* Account holder name */}
                          <div className="col-md-6 form-group">
                            <label className="required">
                              Account Holder Name
                            </label>
                            <Field
                              name="accountHolderName"
                              component={CustomInputComponent}
                              type="text"
                              className="form-control"
                            />
                          </div>

                          {/* Account number */}
                          <div className="col-md-6 form-group">
                            <label className="required">Account Number</label>
                            <Field
                              name="accountNumber"
                              component={CustomInputComponent}
                              type="text"
                              className="form-control"
                            />
                          </div>

                          {issetValidationData ? (
                            <div className="col-md-6 form-group">
                              <label className="required">IFSC Code</label>
                              <Field
                                name="ifscCode"
                                component={CustomInputComponent}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          ) : (
                            <div className="col-md-6 form-group">
                              <label className="required">Swift Code</label>
                              <Field
                                name="swift_code"
                                component={CustomInputComponent}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          )}

                          {
                            showTIN && (
                              <div className="col-md-6 form-group">
                                <label className="required">TIN (Tax Identification Number)</label>
                                <Field
                                  name="tin_number"
                                  component={CustomInputComponent}
                                  type="text"
                                  className="form-control"
                                />
                              </div>
                            )
                          }

                          {/* Bank branch */}
                          <div className="col-md-6 form-group">
                            <label className="required">Bank Branch</label>
                            <Field
                              name="bankBranch"
                              component={CustomInputComponent}
                              type="text"
                              className="form-control"
                            />
                          </div>

                          <div className='row'>
                            <div className='addround-header mb-4 ' />
                            <h5 className="card-title md-title mb-4 mt-2 text-center fw-700">
                              Demat Account Details
                            </h5>

                            {/* <div className="col-md-6 form-group">
                              <label className={showDemat && "required"}>Name (As per ID proof)</label>
                              <Field
                                name="id_name"
                                component={CustomInputComponent}
                                type="text"
                                className="form-control"
                              />
                            </div> */}

                            <div className="col-md-6 form-group" style={{ opacity: 0.5 }}>
                          <label className={showDemat && isRequired(requiredFields, "demat_account[demat_name]") && "required"}>
                              {/* <label className={showDemat && "required"}> */}
                                Name
                                {" "}
                                <span className='info-btn text-lgray font-16' data-tooltip-id="my-tooltip-1" data-tooltip-content="Contact us if different name in Demat Account"><i class="bi bi-info-circle"></i></span>
                                <Tooltip id="my-tooltip-1" style={{ maxWidth: '40vh' }} />
                              </label>
                              <Field
                                name="demat_name"
                                component={CustomInputComponent}
                                type="text"
                                className="form-control"
                                disabled={true}
                              />
                            </div>

                            <div className="col-md-12 form-group">
                          <label className={showDemat && isRequired(requiredFields, "demat_account[account_details_type]") && "required"}>
                              {/* <label className={showDemat && "required"}> */}
                                Demat Account Type
                              </label>
                              <Field
                                name="account_details_type"
                                component={CustomRadioComponent}
                                options={[{ label: "NSDL", value: "NSDL" }, { label: "CDSL", value: "CDSL" }]}
                                className="form-group"
                              />
                            </div>
                            <div className="col-md-6 form-group">
                          <label className={showDemat && isRequired(requiredFields, "demat_account[dp_id]") && "required"}>DP ID</label>
                              {/* <label className={showDemat && "required"}>DP ID</label> */}
                              <Field
                                name="dp_id"
                                component={CustomInputComponent}
                                type="text"
                                className="form-control"
                              />
                            </div>
                            {
                              formik?.values?.account_details_type == "NSDL" && <div className="col-md-6 form-group">
                            <label className={showDemat && isRequired(requiredFields, "demat_account[customer_id]") && "required"}>Customer ID</label>
                                {/* <label className={showDemat && "required"}>Customer ID</label> */}
                                <Field
                                  name="customer_id"
                                  component={CustomInputComponent}
                                  type="text"
                                  className="form-control"
                                />
                              </div>
                            }
                            {
                              formik?.values?.account_details_type == "CDSL" && <div className="col-md-6 form-group">
                            <label className={showDemat && isRequired(requiredFields, "demat_account[client_id]") && "required"}>Customer ID</label>
                                {/* <label className={showDemat && "required"}>Customer ID</label> */}
                                <Field
                                  name="client_id"
                                  component={CustomInputComponent}
                                  type="text"
                                  className="form-control"
                                />
                              </div>
                            }
                            <div className="col-md-12 form-group">
                          <label className={showDemat && isRequired(requiredFields, getCMRRequiredKey(bankData?.accountType)) && "required"}>
                              
                                Client Master Report
                                {" "}
                                <span className='info-btn text-lgray font-16' data-tooltip-id="my-tooltip" data-tooltip-content="A digitally signed PDF certificate, known as a Client Master Report (CMR), is provided by a broker to a client including information on their demat account, including demat ID, date of birth, bank details, nomination details, and more."><i class="bi bi-info-circle"></i></span>
                                <Tooltip id="my-tooltip" style={{ maxWidth: '40vh' }} />
                              </label>
                              <div className="custom-upload">
                                <button className="custom-upload-btn">
                                  Upload Client Master Report
                                </button>
                                <Field
                                  name="fileCMR"
                                  component={SingleFileInputComponent}
                                  label="Client Master Report"
                                />
                              </div>
                            </div>
                            <span className="xs-title mb-4">({getConstants()?.CMR_NOTE})</span>
                          </div>
                        </div>

                        {/* Add Joint Holder Details */}

                        {isjhDetailsCheckbox && (
                          <div className="col-md-6 form-group">
                            <div className="react-checkbox">
                              <Field
                                name="jhDetailsCheckbox"
                                component={CustomCheckbox}
                                label="Add joint holder details"
                                className="react-checkbox"
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {index === 1 && formik.values.jhDetailsCheckbox && (
                      <div className="row">
                        <h5 className="card-title md-title mb-4 text-center heading-fordesktop">
                          Joint Holder Details
                        </h5>

                        {/* Joint Holder Name */}
                        <div className="col-md-6 form-group">
                          <label className="required">Joint Holder Name</label>
                          <Field
                            name="jointHolderName"
                            component={CustomInputComponent}
                            type="text"
                            className="form-control"
                          />
                        </div>

                        {/* Joint Holder Mobile Number */}
                        <div className="col-md-6 form-group">
                          <label className="required">Mobile Number</label>
                          <Field
                            name="jointHolderMobileNumber"
                            component={CustomInputComponent}
                            type="text"
                            className="form-control"
                          />
                        </div>

                        {/* Joint Holder Email */}
                        <div className="col-md-6 form-group">
                          <label className="required">Email Address</label>
                          <Field
                            name="jointHolderEmail"
                            component={CustomInputComponent}
                            type="text"
                            className="form-control"
                          />
                        </div>

                        {/* Joint Holder PAN */}
                        {formik.values.accountType != '64b0edd07bfb212e65f2d406' && <div className="col-md-6 form-group">
                          <label className="required">PAN Number</label>
                          <Field
                            name="jointHolderPAN"
                            component={CustomInputComponent}
                            type="text"
                            className="form-control"
                          />
                        </div>}
                      </div>
                    )}
                  </div>
                  {isSaveBankInformationLoading ? (
                    <BtnSpinner />
                  ) : (
                    <div className="mt-3 text-center">
                    { index === 0 && formik?.values?.jhDetailsCheckbox ? (
                      <button
                        type="button"
                        onClick={() => setIndex(1)}
                        className="theme-btn btn w-25 ml-15"
                      >
                        Next
                      </button>
                    )  : index === 0 && formik?.values?.jhDetailsCheckbox ? (
                      <div className="mt-3 text-center">
                        <button
                          type="button"
                          onClick={() => setIndex(1)}
                          className="theme-btn btn w-25 ml-15"
                        >
                          Next
                        </button>
                      </div>
                    ) : (<div className="mt-3 text-center">
                      <button type="button" onClick={() =>formik.submitForm()} className="theme-btn btn w-25">
                        Next
                      </button>
                    </div>)}
  
                  </div>
                  )}
                </Form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BankDetails;
