import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../../main/deals/DashboardLayout'
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CustomInputComponent,
  CustomSelectComponent,
  CustomRadioComponent,
} from "../../../../components/formik/FieldComponents";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import BtnSpinner from "../../../../utils/BtnSpinner";
import backArrow from "../../../../assets/img/back.svg";
import { SavePersonalInformation, FetchAllCountries, GetUserDetailsUpdated } from "../../../../api";
import {
  UPDATE_PERSONALISED_INFORMATION, UPDATE_BUSINEES_INFORMATION_IC, UPDATE_PERSONALISED_INFORMATION_HUF, UPDATE_PERSONAL_INFORMATION_FC, UPDATE_BUSINESS_INFORMATION_FCorp, UPDATE_BANK_DETAILS, UPDATE_BANK_DETAILS_HUF, UPDATE_BANK_DETAILS_FC, UPDATE_BANK_DETAILS_FCorp, UPDATE_BANK_DETAILS_IC
} from "../../../../store/actions";
import { formateDate } from '../../../../utils/utils';

const PersonalInformation = () => {
  const { data: countries } = FetchAllCountries();
  const { data: userData, isLoading: isDocsLoading } = GetUserDetailsUpdated();
  const [user_information, setPersonalInfromation] = useState(null);
  const [checkUSerType, setCheckUserType] = useState(null)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [is_editable, setIsEditable] = useState(null);
  const [edit_access, setEditAccess] = useState(true);

  const personalInformation = useSelector(
    (state) => state.indianCitizenship.personalInformation
  );
  const businessInformationInitialValues = useSelector(
    (state) => state.indianCorporate.businessInformation
  );
  const hufInformation = useSelector(
    (state) => state.indianhuf.personalInformation
  );
  const forign_Information = useSelector(
    (state) => state.foreignCitizenship.personalInformation
  );

  const businessInformation = useSelector(
    (state) => state.foreignCorporate.businessInformation
  );

  useEffect(() => {
    console.log("checkUSerType", checkUSerType)
    console.log("businessInformation", businessInformation)
    if (checkUSerType == "indian_citizen") {
      setPersonalInfromation(personalInformation)
    } else if (checkUSerType == "indian_corporate") {
      setPersonalInfromation(businessInformationInitialValues)
    } else if (checkUSerType == "huf") {
      setPersonalInfromation(hufInformation)
    } else if (checkUSerType == "foreign_citizen") {
      setPersonalInfromation(forign_Information)
    } else {
      setPersonalInfromation(businessInformation)
    }
  }, [personalInformation, businessInformationInitialValues, hufInformation, forign_Information, businessInformation, checkUSerType])

  useEffect(() => {
    setIsEditable(userData?.kyc_editable)
  }, [isDocsLoading])

  useEffect(() => {
    if (userData != null) {
      if (userData.data != null) {
        let values = userData.data;
        console.log("UserdATA", values)
        setCheckUserType(values.user_individual_type)
        console.log("user_individual_type", values.user_individual_type)
        if (values.user_individual_type == "indian_citizen") {
          dispatch({
            type: UPDATE_PERSONALISED_INFORMATION,
            payload: {
              firstName: values.first_name,
              middleName: values.middle_name,
              lastName: values.last_name,
              dateOfBirth: formateDate(values.dob),
              mobileNumber: values.mobile_number,
              gender: values.gender,
              occupation: values.occupation,
              countryOfCitizenship: values.country_cityzenship_id,
              referralPartner: values.referral_partner,
              individual_type: "individual",
            },
          });

          let firstname = values.first_name;
          let middlename = values.middle_name;
          let lastname = values.last_name;
          let fullname = null;
          if (middlename == undefined || middlename?.trim()?.length == 0) {
            fullname = firstname + ' ' + lastname
          } else {
            fullname = firstname + ' ' + middlename + ' ' + lastname;
          }
          dispatch({
            type: UPDATE_BANK_DETAILS, payload: {
              bankName: "",
              accountHolderName: "",
              accountNumber: "",
              ifscCode: "",
              bankBranch: "",
              accountType: "",
              bank_name: "",
              swift_code: "",
              tin_number: "",
              dp_id: "",
              customer_id: "",
              client_id: "",
              account_details_type: "",
              demat_name: fullname,
              jhDetailsCheckbox: false,
            }
          });
        } else if (values.user_individual_type == "indian_corporate") {
          dispatch({
            type: UPDATE_BUSINEES_INFORMATION_IC,
            payload: { ...values, ...{ dob: formateDate(values.dob) } },
          });

          let firstname = values.first_name;
          let middlename = values.middle_name;
          let lastname = values.last_name;
          let fullname = null;
          if (middlename == undefined || middlename?.trim()?.length == 0) {
            fullname = firstname + ' ' + lastname
          } else {
            fullname = firstname + ' ' + middlename + ' ' + lastname;
          }
          dispatch({
            type: UPDATE_BANK_DETAILS_IC, payload: {
              bankName: "",
              accountHolderName: "",
              accountNumber: "",
              ifscCode: "",
              bankBranch: "",
              accountType: "",
              bank_name: "",
              swift_code: "",
              tin_number: "",
              dp_id: "",
              customer_id: "",
              client_id: "",
              account_details_type: "",
              demat_name: fullname,
              jhDetailsCheckbox: false,
            }
          });
        } else if (values.user_individual_type == "huf") {
          dispatch({
            type: UPDATE_PERSONALISED_INFORMATION_HUF,
            payload: {
              legal_entity_name: values.legal_entity_name,
              firstName: values.first_name,
              middleName: values.middle_name,
              lastName: values.last_name,
              dateOfBirth: formateDate(values.dob),
              mobileNumber: values.mobile_number,
              gender: values.gender,
              occupation: values.occupation,
              countryOfCitizenship: values.country_cityzenship_id,
              referralPartner: values.referral_partner,
              individual_type: "huf",
            },
          });

          let firstname = values.first_name;
          let middlename = values.middle_name;
          let lastname = values.last_name;
          let fullname = null;
          if (middlename == undefined || middlename?.trim()?.length == 0) {
            fullname = firstname + ' ' + lastname
          } else {
            fullname = firstname + ' ' + middlename + ' ' + lastname;
          }
          dispatch({
            type: UPDATE_BANK_DETAILS_HUF, payload: {
              bankName: "",
              accountHolderName: "",
              accountNumber: "",
              ifscCode: "",
              bankBranch: "",
              accountType: "",
              bank_name: "",
              swift_code: "",
              tin_number: "",
              dp_id: "",
              customer_id: "",
              client_id: "",
              account_details_type: "",
              demat_name: fullname,
              jhDetailsCheckbox: false,
            }
          });
        } else if (values.user_individual_type == "foreign_citizen") {
          dispatch({
            type: UPDATE_PERSONAL_INFORMATION_FC, payload: {
              firstName: values.first_name,
              middleName: values.middle_name,
              lastName: values.last_name,
              dateOfBirth: formateDate(values.dob),
              mobileNumber: values.mobile_number,
              gender: values.gender,
              occupation: values.occupation,
              countryOfCitizenship: values.country_cityzenship_id,
              referralPartner: values.referral_partner,
            }
          });

          let firstname = values.first_name;
          let middlename = values.middle_name;
          let lastname = values.last_name;
          let fullname = null;
          if (middlename == undefined || middlename?.trim()?.length == 0) {
            fullname = firstname + ' ' + lastname
          } else {
            fullname = firstname + ' ' + middlename + ' ' + lastname;
          }
          dispatch({
            type: UPDATE_BANK_DETAILS_FC, payload: {
              bankName: "",
              accountHolderName: "",
              accountNumber: "",
              ifscCode: "",
              bankBranch: "",
              accountType: "",
              bank_name: "",
              swift_code: "",
              tin_number: "",
              dp_id: "",
              customer_id: "",
              client_id: "",
              account_details_type: "",
              demat_name: fullname,
              jhDetailsCheckbox: false,
            }
          });
        } else {
          dispatch({
            type: UPDATE_BUSINESS_INFORMATION_FCorp,
            payload: { ...values, ...{ dob: formateDate(values.dob) } }
          });

          let firstname = values.first_name;
          let middlename = values.middle_name;
          let lastname = values.last_name;
          let fullname = null;
          if (middlename == undefined || middlename?.trim()?.length == 0) {
            fullname = firstname + ' ' + lastname
          } else {
            fullname = firstname + ' ' + middlename + ' ' + lastname;
          }
          dispatch({
            type: UPDATE_BANK_DETAILS_FCorp, payload: {
              bankName: "",
              accountHolderName: "",
              accountNumber: "",
              ifscCode: "",
              bankBranch: "",
              accountType: "",
              bank_name: "",
              swift_code: "",
              tin_number: "",
              dp_id: "",
              customer_id: "",
              client_id: "",
              account_details_type: "",
              demat_name: fullname,
              jhDetailsCheckbox: false,
            }
          });
        }
      }
    }
  }, [userData]);

  const toggleEdit = () => {
    setEditAccess(edit_access => !edit_access);
  };

  useEffect(() => {
    if (searchParams.has("editable")) {
      setEditAccess(searchParams.get("editable") == 'true')
    }
  }, [searchParams])

  const {
    mutateAsync: savePersonalInformation,
    isLoading: isSavePersonalInformationLoading,
  } = SavePersonalInformation();

  const IndianCitizenValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    dateOfBirth: Yup.date().required("Date of Birth is required"),
    mobileNumber: Yup.string().required("Mobile number is required"),
    gender: Yup.string().required("Gender is required"),
    occupation: Yup.string().required("Occupation is required"),
    countryOfCitizenship: Yup.string().required("Please select a country"),
  });

  const IndianCorporateValidationSchema = Yup.object().shape({
    legal_entity_name: Yup.string().required("Legal name is required"),
    official_email: Yup.string().required("Official email address is required").email("Invalid email address"),
    authorize_personal_email: Yup.string().required("Authorized person email address is required").email("Invalid email address"),
    official_contact: Yup.string().required("Official contact is required"),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    dob: Yup.date().required("Date of Incorporation is required"),
    mobile_number: Yup.string().required("Mobile number is required"),
    gender: Yup.string().required("Gender is required"),
    designation: Yup.string().required("Designation is required"),
  });

  const HufValidationSchema = Yup.object().shape({
    legal_entity_name: Yup.string().required("Legal name is required"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    dateOfBirth: Yup.date().required("Date of Incorporation is required"),
    mobileNumber: Yup.string().required("Mobile number is required"),
    gender: Yup.string().required("Gender is required"),
    occupation: Yup.string().required("Occupation is required"),
    countryOfCitizenship: Yup.string().required("Please select a country"),
  });

  const ForeignCorporateValidationSchema = Yup.object().shape({
    legal_entity_name: Yup.string().required("Legal name is required"),
    official_email: Yup.string().required("Official email address is required").email("Invalid email address"),
    authorize_personal_email: Yup.string().required("Authorized person email address is required").email("Invalid email address"),
    official_contact: Yup.string().required("Official contact is required"),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    dob: Yup.date().required("Date of Incorporation is required"),
    countryOfCitizenship: Yup.string().required("Please select a country"),
    mobile_number: Yup.string().required("Mobile number is required"),
    gender: Yup.string().required("Gender is required"),
    designation: Yup.string().required("Designation is required"),
  });

  const getValidationSchema = () => {
    switch (checkUSerType) {
      case 'indian_citizen': {
        return IndianCitizenValidationSchema
      }
      case 'indian_corporate': {
        return IndianCorporateValidationSchema
      }
      case 'huf': {
        return HufValidationSchema
      }
      case 'foreign_citizen': {
        return IndianCitizenValidationSchema
      }
      case 'foreign_corporate': {
        return ForeignCorporateValidationSchema
      }
      default: {
        return IndianCitizenValidationSchema
      }
    }
  }

  return (
    <>
      <DashboardLayout>
        <div className='header-mobile d-md-none border-b'>
          <div className='d-flex align-items-center justify-content-between'>
            <div>
              <Link className="back-btn blue-text d-block" to="/my-profile">
                <img src={backArrow} alt="arrow" /> KYC Details
              </Link>
            </div>
            {is_editable && <div>
              <button type="button" onClick={toggleEdit} className='theme-btn btn btn-sm' >{edit_access ? 'Edit' : 'Cancel'}</button>
            </div>}
          </div>
        </div>

        <div className='d-flex align-items-center justify-content-between cr-heading header-desktop'>
          <div className="md-title text-start fw-700">
            KYC Details
          </div>
          {is_editable && <div>
            <button type="button" onClick={toggleEdit} className='theme-btn btn btn-sm' >{edit_access ? 'Edit' : 'Cancel'}</button>
          </div>}
        </div>

        <div className="pt-4 pb-4 ps-5 pe-5">
          <div className="pt-0 pb-0 kyc-form-block pyp-block">
            <Formik
              initialValues={user_information}
              enableReinitialize={true}
              validationSchema={getValidationSchema()}
              onSubmit={(values) => {
                let request = {
                  page: '1',
                  re_submit: false
                }
                if (checkUSerType == 'indian_citizen') {
                  request = {
                    ...request, ...{
                      first_name: values.firstName,
                      middle_name: values.middleName,
                      last_name: values.lastName,
                      mobile_number: values.mobileNumber,
                      gender: values.gender,
                      dob: values.dateOfBirth,
                      referral_partner: values.referralPartner,
                      occupation: values.occupation,
                      country_cityzenship_id: values.countryOfCitizenship,
                    }
                  }
                }
                if (checkUSerType == 'indian_corporate') {
                  request = {
                    ...request, ...{
                      first_name: values.first_name,
                      middle_name: values.middle_name,
                      last_name: values.last_name,
                      mobile_number: values.mobile_number,
                      gender: values.gender,
                      dob: values.dob,
                      referral_partner: values.referral_partner,
                      legal_entity_name: values.legal_entity_name,
                      official_contact: values.official_contact,
                      official_email: values.official_email,
                      designation: values.designation,
                      authorize_personal_email: values.authorize_personal_email,
                    }
                  }
                }
                if (checkUSerType == 'huf') {
                  request = {
                    ...request, ...{
                      first_name: values.firstName,
                      middle_name: values.middleName,
                      last_name: values.lastName,
                      mobile_number: values.mobileNumber,
                      gender: values.gender,
                      dob: values.dateOfBirth,
                      referral_partner: values.referralPartner,
                      legal_entity_name: values.legal_entity_name,
                      occupation: values.occupation,
                      country_cityzenship_id: values.countryOfCitizenship,
                    }
                  }
                }
                if (checkUSerType == 'foreign_citizen') {
                  request = {
                    ...request, ...{
                      first_name: values.firstName,
                      middle_name: values.middleName,
                      last_name: values.lastName,
                      mobile_number: values.mobileNumber,
                      gender: values.gender,
                      dob: values.dateOfBirth,
                      referral_partner: values.referralPartner,
                      occupation: values.occupation,
                      country_cityzenship_id: values.countryOfCitizenship,
                    }
                  }
                }
                if (checkUSerType == 'foreign_corporate') {
                  request = {
                    ...request, ...{
                      first_name: values.first_name,
                      middle_name: values.middle_name,
                      last_name: values.last_name,
                      mobile_number: values.mobile_number,
                      gender: values.gender,
                      dob: values.dob,
                      referral_partner: values.referral_partner,
                      legal_entity_name: values.legal_entity_name,
                      official_contact: values.official_contact,
                      official_email: values.official_email,
                      designation: values.designation,
                      country_cityzenship_id: values.country_cityzenship_id,
                      authorize_personal_email: values.authorize_personal_email,
                    }
                  }
                }
                savePersonalInformation(
                  {
                    payload: request,
                  },
                  {
                    onSuccess: () => {
                      if (checkUSerType == "indian_citizen") {
                        dispatch({
                          type: UPDATE_PERSONALISED_INFORMATION,
                          payload: {
                            firstName: values.firstName,
                            middleName: values.middleName,
                            lastName: values.lastName,
                            dateOfBirth: values.dateOfBirth,
                            mobileNumber: values.mobileNumber,
                            gender: values.gender,
                            occupation: values.occupation,
                            countryOfCitizenship: values.countryOfCitizenship,
                            referralPartner: values.referralPartner,
                            individual_type: "individual",
                          },
                        });
                      } else if (checkUSerType == "indian_corporate") {
                        dispatch({
                          type: UPDATE_BUSINEES_INFORMATION_IC,
                          payload: values,
                        });
                      } else if (checkUSerType == "huf") {
                        dispatch({
                          type: UPDATE_PERSONALISED_INFORMATION_HUF,
                          payload: {
                            legal_entity_name: values.legal_entity_name,
                            firstName: values.firstName,
                            middleName: values.middleName,
                            lastName: values.lastName,
                            dateOfBirth: values.dateOfBirth,
                            mobileNumber: values.mobileNumber,
                            gender: values.gender,
                            occupation: values.occupation,
                            countryOfCitizenship: values.countryOfCitizenship,
                            referralPartner: values.referralPartner,
                            individual_type: "huf",
                          },
                        });
                      } else if (checkUSerType == "foreign_citizen") {
                        dispatch({
                          type: UPDATE_PERSONAL_INFORMATION_FC, payload: {
                            firstName: values.firstName,
                            middleName: values.middleName,
                            lastName: values.lastName,
                            dateOfBirth: values.dateOfBirth,
                            mobileNumber: values.mobileNumber,
                            gender: values.gender,
                            occupation: values.occupation,
                            countryOfCitizenship: values.countryOfCitizenship,
                            referralPartner: values.referralPartner,
                          }
                        });
                      } else {
                        dispatch({
                          type: UPDATE_BUSINESS_INFORMATION_FCorp,
                          payload: values,
                        });
                      }
                      navigate("/my-profile/permanent-address?editable=" + edit_access);
                    },
                  }
                );
              }}
            >
              {({ errors, touched, values }) => (
                <Form className="custom-form">
                  {checkUSerType == "indian_citizen" && <div className="row">
                    <h5 className="card-title md-title mb-4 text-center fw-700">
                      Personal Information
                    </h5>
                    {/* First Name */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">First Name</label>
                      <Field
                        name="firstName"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Middle Name */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label>Middle Name</label>
                      <Field
                        name="middleName"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Last Name */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Last Name</label>
                      <Field
                        name="lastName"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Date of Birth */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Date of Birth</label>
                      <Field
                        name="dateOfBirth"
                        component={CustomInputComponent}
                        type="date"
                        disabled={edit_access}
                        className="form-control"

                      />
                    </div>

                    {/* Mobile Number */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Mobile Number</label>
                      <Field
                        name="mobileNumber"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"

                      />
                    </div>

                    {/* Email Address */}
                    {/* <div className="col-md-6 form-group">
                        <label>Email Address</label>
                        <Field
                          name="emailAddress"
                          component={CustomInputComponent}
                          type="email"
                        />
                      </div> */}

                    {/* Gender */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required mb-3">Gender</label>
                      <Field
                        name="gender"
                        component={CustomRadioComponent}
                        options={[
                          { label: "Male", value: "Male" },
                          { label: "Female", value: "Female" },
                        ]}
                        disabled={edit_access}
                        className="form-group"

                      />
                    </div>

                    {/* Country of citizenship */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">
                        Country of Residence
                      </label>
                      <Field
                        name="countryOfCitizenship"
                        component={CustomSelectComponent}
                        options={countries}
                        disabled={edit_access}
                        className="form-control"

                      />
                    </div>

                    {/* Occupation */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Occupation</label>
                      <Field
                        name="occupation"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"

                      />
                    </div>

                    {/* Referral partner */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="">Referral Partner</label>
                      <Field
                        name="referralPartner"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"

                      />
                    </div>

                    {/* Country of citizenship */}
                    {/* <div className="col-md-6 form-group">
                        <label className="required">
                          Select Individual Type
                        </label>
                        <Field
                          name="individual_type"
                          component={CustomSelectComponent}
                          options={IndianCitizenUserType.individual_type}
                        />
                      </div> */}

                    {/* NRI Checkbox */}
                    {/* <div className="col-md-12 form-group">
                        <div className="react-checkbox">
                          <Field
                            name="nriCheckbox"
                            component={CustomCheckbox}
                            label="Are you a Non-Resident Indian (NRI)?"
                          />
                        </div>
                      </div> */}
                  </div>}
                  {checkUSerType == "huf" && <div className="row">
                    {/* Legal name of the entity */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">
                        Name of Incorporation
                      </label>
                      <Field
                        name="legal_entity_name"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"

                      />
                    </div>
                    {/* Date of Birth */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Date of Incorporation</label>
                      <Field
                        name="dateOfBirth"
                        component={CustomInputComponent}
                        type="date"
                        disabled={edit_access}
                        className="form-control"

                      />
                    </div>

                    <h5 className="card-title md-title mb-4 text-center">
                      Personal Information
                    </h5>
                    {/* First Name */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">First Name</label>
                      <Field
                        name="firstName"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"

                      />
                    </div>

                    {/* Middle Name */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label>Middle Name</label>
                      <Field
                        name="middleName"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"

                      />
                    </div>

                    {/* Last Name */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Last Name</label>
                      <Field
                        name="lastName"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"

                      />
                    </div>

                    {/* Mobile Number */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Mobile Number</label>
                      <Field
                        name="mobileNumber"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"

                      />
                    </div>

                    {/* Email Address */}
                    {/* <div className="col-md-6 form-group">
                        <label>Email Address</label>
                        <Field
                          name="emailAddress"
                          component={CustomInputComponent}
                          type="email"
                        />
                      </div> */}

                    {/* Gender */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required mb-3">Gender</label>
                      <Field
                        name="gender"
                        component={CustomRadioComponent}
                        options={[
                          { label: "Male", value: "Male" },
                          { label: "Female", value: "Female" },
                        ]}
                        disabled={edit_access}
                        className="form-group"

                      />
                    </div>

                    {/* Country of citizenship */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">
                        Country of Residence
                      </label>
                      <Field
                        name="countryOfCitizenship"
                        component={CustomSelectComponent}
                        options={countries}
                        disabled={edit_access}
                        className="form-control"

                      />
                    </div>

                    {/* Occupation */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Occupation</label>
                      <Field
                        name="occupation"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"

                      />
                    </div>

                    {/* Referral partner */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="">Referral Partner</label>
                      <Field
                        name="referralPartner"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"

                      />
                    </div>

                    {/* Country of citizenship */}
                    {/* <div className="col-md-6 form-group">
                        <label className="required">
                          Select Individual Type
                        </label>
                        <Field
                          name="individual_type"
                          component={CustomSelectComponent}
                          options={IndianCitizenUserType.individual_type}
                        />
                      </div> */}

                    {/* NRI Checkbox */}
                    {/* <div className="col-md-12 form-group">
                        <div className="react-checkbox">
                          <Field
                            name="nriCheckbox"
                            component={CustomCheckbox}
                            label="Are you a Non-Resident Indian (NRI)?"
                          />
                        </div>
                      </div> */}
                  </div>}
                  {checkUSerType == "indian_corporate" && <div className="row">
                    {/* Legal name of the entity */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">
                        Legal Name of the Entity
                      </label>
                      <Field
                        name="legal_entity_name"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"

                      />
                    </div>

                    {/* Official email address */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">
                        Official Email Address
                      </label>
                      <Field
                        name="official_email"
                        component={CustomInputComponent}
                        type="email"
                        disabled={edit_access}
                        className="form-control"

                      />
                    </div>

                    {/* Offical contact */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Official Contact</label>
                      <Field
                        name="official_contact"
                        component={CustomInputComponent}
                        type="number"
                        disabled={edit_access}
                        className="form-control"

                      />
                    </div>

                    {/* Date of Birth */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Date of Incorporation</label>
                      <Field
                        name="dob"
                        component={CustomInputComponent}
                        type="date"
                        disabled={edit_access}
                        className="form-control"

                      />
                    </div>

                    <h5 className="card-title md-title mb-4 text-center">
                      Authorized Person's Information
                    </h5>
                    {/* First Name */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">First Name</label>
                      <Field
                        name="first_name"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Middle Name */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label>Middle Name</label>
                      <Field
                        name="middle_name"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Last Name */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Last Name</label>
                      <Field
                        name="last_name"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>



                    {/* Mobile Number */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Mobile Number</label>
                      <Field
                        name="mobile_number"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* authorize_personal_email */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">
                        Authorized Person Email Address
                      </label>
                      <Field
                        name="authorize_personal_email"
                        component={CustomInputComponent}
                        type="email"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>


                    {/* master_corporate_type_id */}

                    {/* <div className="col-md-6 form-group">
                        <label className="required">
                        Organization Type
                        </label>
                        <Field
                          name="master_corporate_type_id"
                          component={CustomSelectComponent}
                          options={corporateTypes}
                        />
                      </div> */}

                    {/* gender */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required mb-3">Gender</label>
                      <Field
                        name="gender"
                        component={CustomRadioComponent}
                        options={[
                          { label: "Male", value: "Male" },
                          { label: "Female", value: "Female" },
                        ]}
                        disabled={edit_access}
                        className="form-group"
                      />
                    </div>
                    {/* Designation */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Designation</label>
                      <Field
                        name="designation"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>


                    {/* Referral partner */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="">Referral Partner</label>
                      <Field
                        name="referral_partner"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>


                  </div>}
                  {checkUSerType == "foreign_corporate" && <div className="row">
                    {/* Legal name of the entity */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">
                        Legal Name of the Entity
                      </label>
                      <Field
                        name="legal_entity_name"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Official email address */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">
                        Official Email Address
                      </label>
                      <Field
                        name="official_email"
                        component={CustomInputComponent}
                        type="email"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Offical contact */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Official Contact</label>
                      <Field
                        name="official_contact"
                        component={CustomInputComponent}
                        type="number"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>
                    {/* Date of incorporation */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Date of Incorporation</label>
                      <Field
                        name="dob"
                        component={CustomInputComponent}
                        type="date"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>
                    <h5 className="card-title md-title mb-4 text-center">
                      Authorized Person's Information
                    </h5>
                    {/* First Name */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">First Name</label>
                      <Field
                        name="first_name"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Middle Name */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label>Middle Name</label>
                      <Field
                        name="middle_name"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Last Name */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Last Name</label>
                      <Field
                        name="last_name"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>



                    {/* Mobile Number */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Mobile Number</label>
                      <Field
                        name="mobile_number"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Authorized Person Email Address */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">
                        Authorized Person Email Address
                      </label>
                      <Field
                        name="authorize_personal_email"
                        component={CustomInputComponent}
                        type="email"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Country of citizenship */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">
                        Country of Residence
                      </label>
                      <Field
                        name="country_cityzenship_id"
                        component={CustomSelectComponent}
                        options={countries}
                        excludeIndia={"Yes"}
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Gender */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Gender</label>
                      <Field
                        name="gender"
                        component={CustomRadioComponent}
                        options={[
                          { label: "Male", value: "Male" },
                          { label: "Female", value: "Female" },
                        ]}
                        disabled={edit_access}
                        className="form-group"
                      />
                    </div>

                    {/* Designation */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Designation</label>
                      <Field
                        name="designation"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Referral partner */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="">Referral Partner</label>
                      <Field
                        name="referral_partner"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>


                  </div>}
                  {checkUSerType == "foreign_citizen" && <div className="row">
                    {/* First Name */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">First Name</label>
                      <Field
                        name="firstName"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Middle Name */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label>Middle Name</label>
                      <Field
                        name="middleName"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Last Name */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Last Name</label>
                      <Field
                        name="lastName"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Date of Birth */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Date of Birth</label>
                      <Field
                        name="dateOfBirth"
                        component={CustomInputComponent}
                        type="date"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Mobile Number */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Mobile Number</label>
                      <Field
                        name="mobileNumber"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Gender */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required mb-3">Gender</label>
                      <Field
                        name="gender"
                        component={CustomRadioComponent}
                        options={[
                          { label: "Male", value: "Male" },
                          { label: "Female", value: "Female" },
                        ]}
                        disabled={edit_access}
                        className="form-group"
                      />
                    </div>

                    {/* Country of citizenship */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Country of Residence</label>
                      <Field
                        name="countryOfCitizenship"
                        component={CustomSelectComponent}
                        options={countries}
                        excludeIndia={"Yes"}
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Occupation */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Occupation</label>
                      <Field
                        name="occupation"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Referral partner */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="">Referral Partner</label>
                      <Field
                        name="referralPartner"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>
                  </div>}
                  {edit_access == false ? isSavePersonalInformationLoading ? (
                    <BtnSpinner />
                  ) : (
                    <div className="mt-3 text-center">
                      <button type="submit" className="theme-btn btn w-25">
                        Next
                      </button>
                    </div>
                  ) : <div className="mt-3 text-center">
                    <button type="button" onClick={() => navigate("/my-profile/permanent-address?editable=" + edit_access)} className="theme-btn btn w-25">
                      Next
                    </button>
                  </div>}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </DashboardLayout>
    </>
  )
}

export default PersonalInformation