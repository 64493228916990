// project imports
// action - state management
import moment from "moment";
import * as actionTypes from "./actions";

export const initialState = {
  page: 0,
  personalInformation: {
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: "",
    mobileNumber: "",
    gender: "",
    occupation: "",
    countryOfCitizenship: "",
    referralPartner: "",
    profileImage: null,
  },

  residentialAddress: {
    addressLineOne: "",
    addressLineTwo: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
    currentAddressDifferentCheckbox: false,
    addressLineOneCurrent: "",
    addressLineTwoCurrent: "",
    countryCurrent: "",
    stateCurrent: "",
    cityCurrent: "",
    postalCodeCurrent: "",
  },

  bankDetails: {
    bankName: "",
    accountHolderName: "",
    accountNumber: "",
    ifscCode: "",
    bankBranch: "",
    accountType: "",
    jhDetailsCheckbox: false,
    jointHolderName: "",
    jointHolderMobileNumber: "",
    jointHolderEmail: "",
    jointHolderPAN: "",
    bank_name: "",
    swift_code: "",
    tin_number: "",
    id_name: "",
    dp_id: "",
    customer_id: "",
    client_id: "",
    account_details_type: "",
    demat_name: "",
    fileCMR: null
  },

  documents: {
    fileIdentityProof: null,
    fileAddressProof: null,
  },
  moreAboutYou: {
    haveInvestedBefore: "",
    howHearAboutUs: "",
    referralName: "",
    moreAboutYou: "",
  },

  nomineeDetails: {
    firstName: "",
    middleName: "",
    lastName: "",
    addressLineOne: "",
    addressLineTwo: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
    dateOfBirth: "",
    nomineePanNumber: "",
    relationshipWithInvestor: "",
    minorNomineeCheckbox: false,
    firstNameGuardian: "",
    lastNameGuardian: "",
    addressLineOneGuardian: "",
    addressLineTwoGuardian: "",
    countryGuardian: "",
    stateGuardian: "",
    cityGuardian: "",
    postalCodeGuardian: "",
    isNomineeAdded: "No",
  },
  guardianDetails: {
    firstNameGuardian: "",
    lastNameGuardian: "",
    addressLineOneGuardian: "",
    addressLineTwoGuardian: "",
    countryGuardian: "",
    stateGuardian: "",
    cityGuardian: "",
    postalCodeGuardian: "",
  },

  personalizeYourProfile: {
    expertiseInSectors: [],
    investInSectors: [],
    investInStartUps: [],
    howPreferInvest: "",
    linkedInProfileUrl: "",
    confirmation: [],
    commitment_amount: ""
  },
};

// ==============================|| Indian Citizenship REDUCER ||============================== //

const foreignCitizenship = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PAGE_FC:
      return {
        ...state,
        page: action.payload,
      };
    case actionTypes.UPDATE_PERSONAL_INFORMATION_FC:
      return {
        ...state,
        personalInformation: action.payload,
      };
    case actionTypes.UPDATE_RESIDENTIAL_ADDRESS_FC:
      return {
        ...state,
        residentialAddress: action.payload,
      };
    case actionTypes.UPDATE_CURRENT_ADDRESS_FC:
      return {
        ...state,
        currentAddress: action.payload,
      };
    case actionTypes.UPDATE_BANK_DETAILS_FC:
      return {
        ...state,
        bankDetails: action.payload,
      };
    case actionTypes.UPDATE_JOINT_HOLDER_DETAILS_FC:
      return {
        ...state,
        jointHolderDetails: action.payload,
      };
    case actionTypes.UPDATE_UPLOAD_DOCUMENTS_FC:
      return {
        ...state,
        uploadDocuments: action.payload,
      };
    case actionTypes.UPDATE_MORE_ABOUT_YOU_FC:
      return {
        ...state,
        moreAboutYou: action.payload,
      };
    case actionTypes.UPDATE_NOMINEE_DETAILS_FC:
      return {
        ...state,
        nomineeDetails: action.payload,
      };
    case actionTypes.UPDATE_GUARDIAN_DETAILS_FC:
      return {
        ...state,
        guardianDetails: action.payload,
      };
    case actionTypes.UPDATE_PESONALIZE_YOUR_PROFILE_FC:
      return {
        ...state,
        personalizeYourProfile: action.payload,
      };
    case actionTypes.INTITIALIZE_FOREIGN_CITIZEN:
      const data = action.payload.data;
      const address = action.payload.addresses ? action.payload.addresses : [];

      const docs = data?.documents?.filter(
        (doc) => doc.slug === "onboarding_documents"
      );
      let cmr = docs[0]?.documents?.filter(item => { return item.upload_type == "client_master_report" })

      const investerPermaAdd = address?.filter(
        (add) =>
          add.user_type === "investor" && add.address_type === "residential"
      );

      const investerCurrentAdd = address?.filter(
        (add) => add.user_type === "investor" && add.address_type === "current"
      );

      const nomineeAdd = address?.filter(
        (add) => add.user_type === "nominee" && add.address_type === "residential"
      );
      const nomineeGaurdianAdd = address?.filter(
        (add) => add.user_type === "gaurdian" && add.address_type === "residential"
      );

      let firstname = data.first_name;
      let middlename = data.middle_name;
      let lastname = data.last_name;
      let fullname = null;
      if (middlename == undefined || middlename?.trim()?.length == 0) {
        fullname = firstname + ' ' + lastname
      } else {
        fullname = firstname + ' ' + middlename + ' ' + lastname;
      }

      // console.log("residential",investerPermaAdd);
      const newInitialState = {
        page: 0,
        personalInformation: {
          firstName: data.first_name || "",
          middleName: data.middle_name || "",
          lastName: data.last_name || "",
          dateOfBirth: data.dob
            ? moment(data.dob).utc().format("YYYY-MM-DD")
            : "",
          mobileNumber: data.mobile_number || "",
          gender: data.gender || "",
          occupation: data.occupation || "",
          countryOfCitizenship: data.country_cityzenship_id || "",
          referralPartner: data.referral_partner || "",
          profileImage: data.profile_image || null,
        },
        residentialAddress: {
          addressLineOne: investerPermaAdd[0]?.address_line_1 || "",
          addressLineTwo: investerPermaAdd[0]?.address_line_2 || "",
          country: investerPermaAdd[0]?.country_id || "",
          state: investerPermaAdd[0]?.state_id || "",
          city: investerPermaAdd[0]?.city_id || "",
          postalCode: investerPermaAdd[0]?.zip_code || "",

          currentAddressDifferentCheckbox: investerCurrentAdd[0] ? true : false,
          addressLineOneCurrent: investerCurrentAdd[0]?.address_line_1 || "",
          addressLineTwoCurrent: investerCurrentAdd[0]?.address_line_2 || "",
          countryCurrent: investerCurrentAdd[0]?.country_id || "",
          stateCurrent: investerCurrentAdd[0]?.state_id || "",
          cityCurrent: investerCurrentAdd[0]?.city_id || "",
          postalCodeCurrent: investerCurrentAdd[0]?.zip_code || "",
        },
        bankDetails: {
          bankName: data.bankAccounts[0]?.bank_id?._id || "",
          accountHolderName: data.bankAccounts[0]?.account_holder_name || "",
          accountNumber: data.bankAccounts[0]?.bank_account_number || "",
          ifscCode: data.bankAccounts[0]?.ifsc_code || "",
          bankBranch: data.bankAccounts[0]?.branch_name || "",
          accountType: data.bankAccounts[0]?.account_type_id || "",
          jhDetailsCheckbox: data.bankAccounts[0]?.bank_id ? true : false,
          jointHolderName:
            data.bankAccounts[0]?.accounts[0]?.joint_holder_name || "",
          jointHolderMobileNumber:
            data.bankAccounts[0]?.accounts[0]?.joint_holder_mobile_number || "",
          jointHolderEmail:
            data.bankAccounts[0]?.accounts[0]?.joint_holder_email || "",
          jointHolderPAN:
            data.bankAccounts[0]?.accounts[0]?.joint_holder_pan_number || "",
          bank_name: data.bankAccounts[0]?.bank_name || "",
          swift_code: data.bankAccounts[0]?.swift_code || "",
          tin_number: data.tin_number || "",
          id_name: data.bankAccounts[0]?.demat?.id_name || "",
          dp_id: data.bankAccounts[0]?.demat?.dp_id || "",
          customer_id: data.bankAccounts[0]?.demat?.customer_id || "",
          client_id: data.bankAccounts[0]?.demat?.client_id || "",
          account_details_type: data.bankAccounts[0]?.demat?.account_details_type || "",
          demat_name: fullname || "",
          fileCMR: cmr
        },
        documents: {
          fileIdentityProof: null,
          fileAddressProof: null,
        },
        nomineeDetails: {
          firstName: data.nominee_details[0]?.first_name || "",
          middleName: data.nominee_details[0]?.middle_name || "",
          lastName: data.nominee_details[0]?.last_name || "",
          addressLineOne: nomineeAdd[0]?.address_line_1 || "",
          addressLineTwo: nomineeAdd[0]?.address_line_2 || "",
          country: nomineeAdd[0]?.country_id || "",
          state: nomineeAdd[0]?.state_id || "",
          city: nomineeAdd[0]?.city_id || "",
          postalCode: nomineeAdd[0]?.zip_code || "",
          dateOfBirth: data.nominee_details[0]?.dob || "",
          nomineePanNumber: data.nominee_details[0]?.nominee_pan_number || "",
          relationshipWithInvestor:
            data.nominee_details[0]?.relation_of_nominee || "",
          minorNomineeCheckbox: nomineeGaurdianAdd[0] ? true : false,
          firstNameGuardian: data.nominee_details[0]?.gurdian_first_name || "",
          lastNameGuardian: data.nominee_details[0]?.gurdian_last_name || "",
          addressLineOneGuardian: nomineeGaurdianAdd[0]?.address_line_1 || "",
          addressLineTwoGuardian: nomineeGaurdianAdd[0]?.address_line_2 || "",
          countryGuardian: nomineeGaurdianAdd[0]?.country_id || "",
          stateGuardian: nomineeGaurdianAdd[0]?.state_id || "",
          cityGuardian: nomineeGaurdianAdd[0]?.city_id || "",
          postalCodeGuardian: nomineeGaurdianAdd[0]?.zip_code || "",
          isNomineeAdded: data?.is_nominee_added ? data?.is_nominee_added : "No",
        },
        moreAboutYou: {
          haveInvestedBefore:
            data.more_about_you_static[0]?.haveInvestedBefore || "",
          howHearAboutUs: data.more_about_you_static[0]?.howHearAboutUs || "",
          referralName: data.more_about_you_static[0]?.referralName || "",
          moreAboutYou: data.more_about_you_static[0]?.moreAboutYou || "",
        },
        personalizeYourProfile: {
          expertiseInSectors: data.personalize_profile_static[0]?.expertiseInSectors || [],
          investInSectors: data.personalize_profile_static[0]?.investInSectors || [],
          investInStartUps: data.personalize_profile_static[0]?.investInStartUps || [],
          howPreferInvest: data.personalize_profile_static[0]?.howPreferInvest || '',
          linkedInProfileUrl: data.personalize_profile_static[0]?.linkedInProfileUrl || '',
          confirmation: data.personalize_profile_static[0]?.confirmation || [],
          commitment_amount: data.personalize_profile_static[0]?.commitment_amount || ""
        },
      };
      return newInitialState;

    case actionTypes.RESET_FOREIGN_CITIZEN:
      return initialState;
    default:
      return state;
  }
};

export default foreignCitizenship;
