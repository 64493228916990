import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../views/main/deals/DashboardLayout'
import backArrow from '../../assets/img/back.svg'
import noListingImg from '../../assets/img/no-listing-found.png'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearGetFavourites, getFavourites } from '../../store/actions/getFavouritesAction'
import { clearSaveFavourite, saveFavourite } from '../../store/actions/saveFavouriteAction'

const Favourite = () => {
  const [page, setPage] = useState(1)
  const [favourite, setFavourite] = useState([])

  const dispatch = useDispatch()
  const getFavouritesResponse = useSelector(state => state.getFavouritesReducer.data)
  const saveFavouriteResponse = useSelector(state => state.saveFavouriteReducer.data)

  useEffect(() => {
    setFavourite([])
    fetchFavourites(1)
  }, [])

  const fetchFavourites = (page_no) => {
    dispatch(getFavourites({ page: page_no }))
  }

  useEffect(() => {
    if (getFavouritesResponse != null) {
      if (getFavouritesResponse.data != null) {
        console.log("getFavouritesResponse", getFavouritesResponse)
        if (page == 1) {
          setFavourite(getFavouritesResponse.data)
        } else {
          setFavourite([...favourite, ...getFavouritesResponse.data])
        }
        setPage(page => page + 1)
        dispatch(clearGetFavourites())
      }
    }
  }, [getFavouritesResponse])

  const removeFavourite = (id) => {
    let request = {
      startup_deal_id: id
    }
    dispatch(saveFavourite(request))
  }

  useEffect(() => {
    if (saveFavouriteResponse != null) {
      if (saveFavouriteResponse.data != null) {
        console.log("saveFavouriteResponse", saveFavouriteResponse)
        setPage(1)
        fetchFavourites(1)
        dispatch(clearSaveFavourite())
      }
    }
  }, [saveFavouriteResponse])

  return (
    <>
      <DashboardLayout>
        <div className='header-mobile d-md-none border-b'>
          <div className='d-flex align-items-center justify-content-between'>
            <div>
              <Link className="back-btn blue-text d-block" to="/dashboard">
                <img src={backArrow} alt="arrow" /> Favorites
              </Link>
            </div>
          </div>
        </div>
        <div className="md-title text-start fw-700 cr-heading header-desktop">
          Favorites
        </div>
        <div className="pt-4 pb-4 ps-5 pe-5">
          <div className='favourite-list'>
            {
              favourite.length > 0 ?
                favourite.map((item) => (
                  <li className="p-0" key={item.startup_deal_id}>
                    <div className="d-flex align-items-center justify-content-between favourite-box pt-3 pb-3">
                      <div className="circle-box circle-text">
                        {
                          item.image ?
                            <img
                              src={item.image}
                              alt=""
                            />
                            : <span className="ct-name">{item?.company_name?.split(" ")[0]?.substring(0, 1)}{item?.company_name?.split(" ")[1]?.substring(0, 1)}</span>
                        }
                      </div>
                      <div className="ml-15 d-flex align-items-end justify-content-between rfl-btn-flex" style={{ width: "calc(100% - 60px)" }}>
                        <div>
                          <div className="md-title mb-6">
                            {item.company_name}
                          </div>
                          <div className="sm-title font-0-8em text-start">
                            <span className="text-lgray">{item.description}</span>
                          </div>
                        </div>
                        <div className='rfl-btn-box'>
                          <button type='button' className='transparent-btn text-decoration-underline' onClick={() => { removeFavourite(item.startup_deal_id) }}>Remove from list</button>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
                :
                <div className="no-listing-card text-center pt-4">
                  <img src={noListingImg} alt="no list" />
                  <div className="xs-title text-center mt-4 text-lgray">No favorites found</div>
                </div>
            }
          </div>
        </div>
      </DashboardLayout>
    </>
  )
}

export default Favourite