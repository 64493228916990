import rootReducer from './reducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {createStore, applyMiddleware, compose} from 'redux'
import createSagaMiddleware from "redux-saga";
import rootSaga from './sagas';

// ==============================|| REDUX - MAIN STORE ||============================== //
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
    key: 'root',
    storage,
  }

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer,  composeEnhancers(applyMiddleware(sagaMiddleware)));
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga)

export { store, persistor };
