// project imports
// action - state management
import * as actionTypes from './actions';

export const initialState = {
  userType: '',
};

// ==============================|| Indian Citizenship REDUCER ||============================== //

const userDetails = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload
      };
    default:
      return state;
  }
};

export default userDetails;
