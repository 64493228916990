import {types} from './types';

export function getIndustriesTypes(data) {
  return {
    type: types.SEND_REQUEST_GET_INDUSTRIES_TYPES,
    payload: data,
  };
}

export function getIndustriesTypesSuccess(data) {
  return {
    type: types.SEND_REQUEST_GET_INDUSTRIES_TYPES_SUCCESS,
    payload: data,
  };
}

export function getIndustriesTypesFailure(error) {
  return {
    type: types.SEND_REQUEST_GET_INDUSTRIES_TYPES_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearGetIndustriesTypes() {
  return {
    type: types.CLEAR_REQUEST_GET_INDUSTRIES_TYPES,
    payload: {},
  };
}
