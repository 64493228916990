import {types} from './types';

export function agreeTerms(data) {
  return {
    type: types.SEND_REQUEST_AGREE_TERMS,
    payload: data,
  };
}
export function agreeTermsSuccess(data) {
  return {
    type: types.SEND_REQUEST_AGREE_TERMS_SUCCESS,
    payload: data,
  };
}

export function agreeTermsFailure(error) {
  return {
    type: types.SEND_REQUEST_AGREE_TERMS_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearAgreeTerms() {
  return {
    type: types.CLEAR_REQUEST_AGREE_TERMS,
    payload: {},
  };
}
