import {types} from './types';

export function getFavourites(data) {
  return {
    type: types.SEND_REQUEST_GET_FAVOURITES,
    payload: data,
  };
}

export function getFavouritesSuccess(data) {
  return {
    type: types.SEND_REQUEST_GET_FAVOURITES_SUCCESS,
    payload: data,
  };
}

export function getFavouritesFailure(error) {
  return {
    type: types.SEND_REQUEST_GET_FAVOURITES_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearGetFavourites() {
  return {
    type: types.CLEAR_REQUEST_GET_FAVOURITES,
    payload: {},
  };
}
