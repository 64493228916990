import {types} from './types';

export function getDynamicMessages(data) {
  return {
    type: types.SEND_REQUEST_GET_DYNAMIC_MESSAGES,
    payload: data,
  };
}

export function getDynamicMessagesSuccess(data) {
  return {
    type: types.SEND_REQUEST_DYNAMIC_MESSAGES_SUCCESS,
    payload: data,
  };
}

export function getDynamicMessagesFailure(error) {
  return {
    type: types.SEND_REQUEST_DYNAMIC_MESSAGES_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearGetDynamicMessages() {
  return {
    type: types.CLEAR_REQUEST_DYNAMIC_MESSAGES,
    payload: {},
  };
}
