import React from 'react';

import Login from '../views/auth/Login';
import OtpVerification from '../views/auth/OtpVerification';
import RegisterEmail from '../views/auth/RegisterEmail';
import MainLayout from '../layouts/MainLayout';
import About from '../views/main/pages/About';
import ForgotPin from '../views/auth/ForgotPin';
import {Logout}  from '../views/auth/Logout';
import Complaint from '../views/auth/Complaint';


const AuthRoutes = {
    path: '',
    element: <MainLayout />,
    children: [

            {  
              path: '',
              element: <Login />
            },
            {
            path:'login',
            element:<Login />
            },  
            {
              path:'register-email',
              element:<RegisterEmail /> 
            },
            {
              path:'verify-otp',
              element:<OtpVerification /> 
            },
            {
               path:'about-us',
               element:<About /> 
            },
            {
              path:'forgot-pin',
              element:<ForgotPin /> 
           }, 
            {
              path:'logout',
              element:<Logout /> 
            },
            {
              path: "*",
              element: <Login />,
            },
            {
              path: "Complaint",
              element: <Complaint/>,
            },
    ],
    

  };

export default AuthRoutes;
