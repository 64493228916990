import React from "react";
import { Form, Formik } from "formik";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useState } from "react";
import { ReSendOtp, VerifyOtp } from "../../api";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import ToastMessage from "../../components/ToastMessageComponents";

const otpValidationSchema = Yup.object().shape({
  otp: Yup.string().required("Pin is required"),
});

function OtpVerification() {
  const [OTP, setOTP] = useState("");
  const [errorMsg, seterrorMsg] = useState(null);
  const navigate = useNavigate();

  const {
    mutateAsync: resendOtp,
    status: otpStatus,
    isLoading,
  } = ReSendOtp();

  const {
    mutateAsync: VerifyOtpData,
    status: verifyStatus,
    isLoading: verifyLoading,
  } = VerifyOtp();

  const renderButton = (buttonProps) => {
    if (buttonProps.remainingTime <= 0) {
      return <button {...buttonProps}>Resend OTP</button>;
    } else {
      return "";
    }
  };
  const renderTime = (remainingtime = 0) => {
    if (remainingtime > 0) {
      return (
        <span>
          {" "}
          Resend OTP in <span className="orange-text">
            0:{remainingtime}
          </span>{" "}
        </span>
      );
    } else {
      return "";
    }
  };

  return (
    <section className="mid-wrap pt-5 pb-5">
      <div className="mid-card-block">
        <div className="mxw-520">
          {/* <div className="text-center mb-4 vendor-info">
            <img src={logoVr} alt="my mandi" />
          </div> */}

          {otpStatus == "success" && (
            <>
              <ToastMessage
                message="OTP resent successfully"
                classNameData="text-success"
              />
            </>
          )}

          {verifyStatus == "error" && (
            <ToastMessage message={errorMsg} classNameData="text-danger" />
          )}

          <h5 className="card-title lg-title mb-4 fw-700">Verify Your Email</h5>
          <div className="card form-card">
            <div className="card-body">
              {/* <h5 className="card-title lg-title">OTP Verification</h5> */}
              <p className="sm-title mb-2 mt-0">
                Please enter the OTP below to verify your <br />
                email addresss.
              </p>

              <p className="sm-title mb-4 mt-0">
                {Cookies.get("verifyEmail")}{" "}
                <button
                  type="button"
                  className="change-btn"
                  onClick={() => {
                    navigate("/register-email");
                  }}
                >
                  Change
                </button>
              </p>

              <Formik
                initialValues={{
                  otp: "",
                }}
                // validationSchema={otpValidationSchema}
                onSubmit={() =>
                  VerifyOtpData(
                    { otp: OTP },
                    {
                      onError: (error) => {
                        seterrorMsg(null);
                        // console.log(error);
                        if (error.response) {
                          let res = error.response.data;
                          if (error.response.status === 403) {
                            //toast.error(res.message);
                            seterrorMsg(res.message);
                            // errors.email = res.message;
                          } else {
                            toast.error(error.response.message);
                          }
                          if (error.response.status === 500) {
                            toast.error("Internal Server Error");
                          }
                        }
                      },
                    }
                  )
                }
              >
                {({ errors, touched }) => (
                  <Form className="custom-form">
                    <div className="form-group">
                      <div className="myPinCode d-flex justify-content-between">
                        <>
                          <OTPInput
                            value={OTP}
                            onChange={setOTP}
                            autoFocus={true}
                            OTPLength={4}
                            otpType="number"
                            disabled={false}
                            inputMode="numeric"
                            pattern="[0-9]*"
                          />
                        </>
                      </div>
                    </div>
                    <div className="text-center mt-4">
                      {isLoading || verifyLoading ? (
                        <button
                          className="theme-btn btn w-100 btn-340"
                          type="button"
                          disabled
                        >
                          Loading...
                        </button>
                      ) : (
                        <button
                          className="theme-btn btn w-100 btn-340"
                          type="submit"
                          disabled={OTP.length < 4}
                        >
                          Verify
                        </button>
                      )}
                    </div>
                    {/* <div className="sm-title mt-5">Resend OTP in <span className="orange-text">0:20</span></div> */}
                    <div className="sm-title mt-4 text-center ">
                      <ResendOTP
                        className="resend-btn"
                        renderButton={renderButton}
                        renderTime={renderTime}
                        maxTime={60}
                        onResendClick={() => resendOtp()}
                      />
                    </div>
                    {/* <div sm-title mt-5 text-success">OTP resent successfully.</div> */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OtpVerification;
