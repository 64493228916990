import {types} from './types';

export function getStages(data) {
  return {
    type: types.SEND_REQUEST_GET_STAGES,
    payload: data,
  };
}

export function getStagesSuccess(data) {
  return {
    type: types.SEND_REQUEST_GET_STAGES_SUCCESS,
    payload: data,
  };
}

export function getStagesFailure(error) {
  return {
    type: types.SEND_REQUEST_GET_STAGES_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearGetStages() {
  return {
    type: types.CLEAR_REQUEST_GET_STAGES,
    payload: {},
  };
}
