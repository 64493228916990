import React, { useState, useEffect } from 'react';

const ToastMessage = ({ message,classNameData }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  return (

<div> 
    {isVisible && (
      <div className={`error-box mb-4 ${classNameData}`}>{message}</div>
    )}
</div>
    
  );
};

export default ToastMessage;
