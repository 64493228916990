import { types } from './types';

export function getFeedbacksList(data) {
  return {
    type: types.SEND_REQUEST_GET_FEEDBACKS_LIST,
    payload: data,
  };
}

export function getFeedbacksListSuccess(data) {
  return {
    type: types.SEND_REQUEST_GET_FEEDBACKS_LIST_SUCCESS,
    payload: data,
  };
}

export function getFeedbacksListFailure(error) {
  return {
    type: types.SEND_REQUEST_GET_FEEDBACKS_LIST_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearGetFeedbacksList() {
  return {
    type: types.CLEAR_REQUEST_GET_FEEDBACKS_LIST,
    payload: {},
  };
}
