import { useRoutes } from 'react-router-dom';
// routes
import MainRoutes from './MainRoutes';
import AuthRoutes from './AuthRoutes';
import PagesRoutes from './PagesRoutes';
import DealsRoutes from './DealsRoutes';
import Cookies from 'js-cookie';
import KYCRoutes from './KYCRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {

   const userDetail = Cookies.get("userDetail") ? JSON.parse(Cookies.get("userDetail")) : {};
   // userDetail.isAdminLoggedin = false
   let routesArr;
   if (Cookies.get('jwtToken') !== "" && Cookies.get('jwtToken') !== undefined) {
      if (!userDetail?.isAdminLoggedin) {

         if (Cookies.get("userDetail") && JSON.parse(Cookies.get("userDetail"))?.name?.trim()?.length > 0) {
            routesArr = [MainRoutes, PagesRoutes, DealsRoutes, KYCRoutes];
         } else {
            routesArr = [KYCRoutes, MainRoutes, PagesRoutes, DealsRoutes];
         }
      } else {
         routesArr = [AuthRoutes];
      }
   } else {
      routesArr = [AuthRoutes];
   }

   console.log("routesArr", routesArr)

   return useRoutes([...routesArr]);

   //  var routes = (Cookies.get('jwtToken') !== "" && Cookies.get('jwtToken') !== undefined)?[MainRoutes,PagesRoutes]: [AuthRoutes]; 
   //  return useRoutes([..routes])

   // return useRoutes([...routesArr]);



}
