import React, { useEffect, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
import Modal from '../../../utils/Modal';
import SignatureCanvas from 'react-signature-canvas'
import { decode as atob, encode as btoa } from "base-64"
import { PDFDocument } from "pdf-lib";
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

export default function SignedPdf() {

    const [schemeTerm, setSchemeTerm] = useState(null)
    const [openSignatureOptions, setOpenSignatureOptions] = useState(false)
    const [signatureOptionSelected, setSignatureOptionSeclected] = useState(null)
    const [signBase64, setSignBase64] = useState(null)
    const [pdfEditMode, setPdfEditMode] = useState(false);
    const [signatureArrayBuffer, setSignatureArrayBuffer] = useState(null);
    const [pdfArrayBuffer, setPdfArrayBuffer] = useState(null);

    const [transactionId, setTransactionId] = useState(null)
    const [interestedAmount, setInterestAmount] = useState(null)
    const [dragStart, setDragStart] = useState(null);
    const [signaturePosition, setSignaturePosition] = useState({ x: 0, y: 0 });
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [successMessage, setSuccessMessage] = useState(null)
    const [pageWidth, setPageWidth] = useState(0);
    const [pageHeight, setPageHeight] = useState(0);

    const { state } = useLocation()

    const signatureRef = useRef()

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    useEffect(() => {
        handleSchemeTerm(state?.url)
    }, [])

    const handleSchemeTerm = async (url) => {
        console.log('url', url)
        await fetch(url, {
            headers: {
                // 'Content-Type': 'application/json',
                // 'Accept': 'application/pdf'
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                return new Promise((res) => {
                    reader.onloadend = async () => {
                        res(reader.result);
                        console.log("reader", reader)
                        setSchemeTerm(url)
                        setPdfArrayBuffer(base64ToArrayBuffer(reader.result.replace("data:application/pdf;base64,", "")));
                        const loadedPdfDoc = await PDFDocument.load(base64ToArrayBuffer(reader.result.replace("data:application/pdf;base64,", "")));
                        const firstPage = loadedPdfDoc.getPage(0);
                        const { width, height } = firstPage.getSize();
                        setPageWidth(width);
                        setPageHeight(height);
                    }
                })
            })
            .catch(error => {
                console.log("error", error)
            })
    }

    const handleRadioChange = (event) => {
        setSignatureOptionSeclected(event.target.value);
    };

    const handleSignature = () => {
        if (signatureRef?.current?.isEmpty()) {
            toast.error("Please draw sigature")
        } else {
            const canvas = signatureRef.current.getTrimmedCanvas();
            let sign = canvas?.toDataURL()
            setSignBase64(sign);
            setOpenSignatureOptions(false);
            setPdfEditMode(true);

            // Set the initial position of the signature to the center of the PDF
            // const initialX = (signaturePosition.x);
            // const initialY = (signaturePosition.y);
            const initialX = window.innerWidth / 2;
            const initialY = window.innerHeight / 2;
            setSignaturePosition({ x: initialX, y: initialY });

            // Set the current page as the page where the signature is drawn
            setCurrentPage(pageNumber);
        }
    }

    useEffect(() => {
        if (signBase64) {
            setSignatureArrayBuffer(base64ToArrayBuffer(signBase64.replace("data:image/png;base64,", "")));
        }
    }, [signBase64])

    const base64ToArrayBuffer = (base64) => {
        const binary_string = atob(base64);
        const len = binary_string.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }
    const uint8ToBase64 = (u8Arr) => {
        const CHUNK_SIZE = 0x8000; //arbitrary number
        let index = 0;
        const length = u8Arr.length;
        let result = "";
        let slice;
        while (index < length) {
            slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length));
            result += String.fromCharCode.apply(null, slice);
            index += CHUNK_SIZE;
        }
        return btoa(result);
    }

    const handleMouseMove = (e) => {
        if (dragStart) {
            const offsetX = e.clientX - dragStart.x;
            const offsetY = e.clientY - dragStart.y;
            setSignaturePosition({ x: signaturePosition.x + offsetX, y: signaturePosition.y + offsetY });
            setDragStart({ x: e.clientX, y: e.clientY });
            console.log("signature move", { x: signaturePosition.x + offsetX, y: signaturePosition.y + offsetY })
        }
    };

    const handleMouseUp = () => {
        setDragStart(null);
        setPdfEditMode(false)
    };

    const handleMouseDown = (e) => {
        setDragStart({ x: e.clientX, y: e.clientY });
    };

    const saveSignedPDF = async () => {
        const loadedPdfDoc = await PDFDocument.load(pdfArrayBuffer);
        if (loadedPdfDoc && signBase64) {
            const signatureImageBytes = await fetch(signBase64).then(res => res.arrayBuffer());
            const signatureImageEmbed = await loadedPdfDoc.embedPng(signatureImageBytes);
            const pageToSign = loadedPdfDoc.getPage(currentPage - 1); // here it take current page number
            const { width, height } = pageToSign.getSize();
            const { x, y } = signaturePosition;

            console.log("Page to sign", pageToSign.getSize())
            console.log("signaturePosition", signaturePosition)
            console.log("pageWidth", pageWidth, "pageHeight", pageHeight)

            // Use the same position and size as the drawn signature
            // const signatureWidth = 100;
            // const signatureHeight = 100;

            pageToSign.drawImage(signatureImageEmbed, {
                // x: 70 ,
                // y: height - y - 110,
                x: (pageWidth - x),
                y: (pageHeight + y),
                width: signatureImageEmbed.size().width / 2,
                height: signatureImageEmbed.size().height / 7,
            });

            console.log("position", (pageWidth - x), "   ", (y + pageHeight))

            const modifiedPdfBytes = await loadedPdfDoc.save(); // Save the modified PDF
            const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url)

            const pdfBase64 = uint8ToBase64(modifiedPdfBytes);

            let byteCharacters = atob(pdfBase64);
            let byteArrays = [];
            let sliceSize = 512

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                let slice = byteCharacters.slice(offset, offset + sliceSize);

                let byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                let byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            let signedFile = new File(byteArrays, "Investment Scheme Term Sheet.pdf", { type: "application/pdf" });
            let request = new FormData()
            // if (signedFile != null) {
            //   // request.append("interested_amount", interestedAmount)
            //   request.append("interested_amount", 666666)
            //   request.append("document", signedFile)
            // }
            // dispatch(sendSignedDocument({
            //   id: transactionId,
            //   request: request
            // }))
        }
    }

    return (
        <>
            <div className='d-flex align-items-center justify-content-between mb-4'>
                <h5 className='md-title mb-0' id='documentModalLabel'>Investment Scheme Term Sheet</h5>
            </div>
            <div
                className="pdf-viewer-container"
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}>
                <div>
                    <div>
                        <Document
                            file={schemeTerm}
                            // options={{ workerSrc: "/pdf.worker.js" }}
                            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                        >
                            <Page
                                pageNumber={pageNumber}
                                className="pdf-page"
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                height="600"
                            />
                        </Document>
                    </div>
                    {signBase64 && pageNumber === currentPage && <div>
                        <img src={signBase64} onMouseDown={handleMouseDown} style={{ left: signaturePosition.x, top: signaturePosition.y }} alt="signature" className="signature-overlay" />
                    </div>}
                </div>
                <div className="page-navigation">
                    <button onClick={() => setPageNumber(prevPageNumber => Math.max(1, prevPageNumber - 1))} disabled={pageNumber <= 1}>
                        Previous
                    </button>
                    <button onClick={() => setPageNumber(prevPageNumber => Math.min(numPages, prevPageNumber + 1))} disabled={pageNumber >= numPages}>
                        Next
                    </button>
                </div>
                {
                    pdfEditMode ? <div>
                        <div className="edit-mode">
                            Drag signature where you want to place the signature
                        </div>
                    </div> :
                        <div>
                            {signBase64 != null ?
                                <div className="text-center row mt-3">
                                    <div className="col">
                                        <button
                                            className={`green-btn w-100 is-interested`}
                                            onClick={saveSignedPDF}
                                        >
                                            Save
                                        </button>
                                    </div>
                                    <div className="col">
                                        <button
                                            className={`red-btn w-100 is-not-interested`}
                                            onClick={() => {
                                                signatureRef?.current?.clear()
                                                setSignBase64(null)
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className="text-center mt-2">
                                    <button type="button" className="theme-btn px-4 terms-accept-btn" onClick={() => { setOpenSignatureOptions(true) }}>Sign Document</button>
                                </div>
                            }
                        </div>
                }
            </div>

            <Modal commonModalShow={openSignatureOptions}
                commonModalClose={() => { setOpenSignatureOptions(false) }}
                commonModalData={
                    <>
                        <div className='d-flex align-items-center justify-content-between mb-4'>
                            <h5 className='md-title mb-0' id='documentModalLabel'>Select option for signature</h5>
                        </div>
                        <div>
                            <div className="form-group">
                                {[{ label: "Draw Signature", value: "draw" }, { label: "Select From Initials", value: "initials" }].map((option) => (
                                    <div key={option.value} className="react-radio radio-inline mr-15">
                                        <label className="form-check-label">
                                            <input
                                                type="radio"
                                                className="form-check-input"
                                                checked={signatureOptionSelected === option.value}
                                                value={option.value}
                                                onChange={handleRadioChange} // pass event to handler
                                            />
                                            <span>{option.label}</span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                            {
                                signatureOptionSelected === "draw" && (
                                    <div>
                                        <div className="my-3">
                                            <SignatureCanvas ref={signatureRef} penColor='black'
                                                canvasProps={{ className: 'sigCanvas' }} />
                                        </div>
                                        <div className="text-center row">
                                            <div className="col">
                                                <button
                                                    className={`red-btn w-100 is-not-interested`}
                                                    onClick={() => {
                                                        signatureRef?.current?.clear()
                                                    }}
                                                >
                                                    Clear
                                                </button>
                                            </div>

                                            <div className="col">
                                                <button
                                                    className={`green-btn w-100 is-interested`}
                                                    onClick={handleSignature}
                                                >
                                                    Proceed
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </>
                }
            >
            </Modal>
        </>
    )
}
