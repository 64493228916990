import React from 'react';


import ContactUs from '../views/main/pages/ContactUs';
import FAQS from '../views/main/pages/FAQS';
import Home from '../views/main/pages/Home';
import MainLayout from '../layouts/MainLayout';
import {Logout}  from '../views/auth/Logout';


const PagesRoutes = { 
    path: '',
    element: <MainLayout />,
    children: [
      {
        path:'/',
        element:<Home /> 
      },
    {
      path:'contact-us',
      element:<ContactUs /> 
    },
    {
      path:'faqs',
      element:<FAQS /> 
    },
    {
      path:'logout',
      element:<Logout /> 
    },
    
  ]
};

export default PagesRoutes;
