import { types } from './types';

export function getFeedbackCategories(data) {
  return {
    type: types.SEND_REQUEST_GET_FEEDBACK_CATEGORIES,
    payload: data,
  };
}

export function getFeedbackCategoriesSuccess(data) {
  return {
    type: types.SEND_REQUEST_GET_FEEDBACK_CATEGORIES_SUCCESS,
    payload: data,
  };
}

export function getFeedbackCategoriesFailure(error) {
  return {
    type: types.SEND_REQUEST_GET_FEEDBACK_CATEGORIES_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearGetFeedbackCategories() {
  return {
    type: types.CLEAR_REQUEST_GET_FEEDBACK_CATEGORIES,
    payload: {},
  };
}
