import {types} from './types';

export function updateProfile(data) {
  return {
    type: types.SEND_REQUEST_UPDATE_PROFILE,
    payload: data,
  };
}
export function updateProfileSuccess(data) {
  return {
    type: types.SEND_REQUEST_UPDATE_PROFILE_SUCCESS,
    payload: data,
  };
}
export function updateProfileFailure(error) {
  return {
    type: types.SEND_REQUEST_UPDATE_PROFILE_FAILURE,
    payload: {},
    error: error,
  };
}
export function clearUpdateProfile() {
  return {
    type: types.CLEAR_SEND_REQUEST_UPDATE_PROFILE,
    payload: {},
  };
}
