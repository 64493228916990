import {types} from './types';

export function saveFavourite(data) {
  return {
    type: types.SEND_REQUEST_SAVE_FAVOURITE,
    payload: data,
  };
}

export function saveFavouriteSuccess(data) {
  return {
    type: types.SEND_REQUEST_SAVE_FAVOURITE_SUCCESS,
    payload: data,
  };
}

export function saveFavouriteFailure(error) {
  return {
    type: types.SEND_REQUEST_SAVE_FAVOURITE_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearSaveFavourite() {
  return {
    type: types.CLEAR_REQUEST_SAVE_FAVOURITE,
    payload: {},
  };
}
