import {types} from './types';

export function getExternalInvestment(data) {
  return {
    type: types.SEND_REQUEST_GET_EXTERNAL_INVESTMENT,
    payload: data,
  };
}

export function getExternalInvestmentSuccess(data) {
  return {
    type: types.SEND_REQUEST_GET_EXTERNAL_INVESTMENT_SUCCESS,
    payload: data,
  };
}

export function getExternalInvestmentFailure(error) {
  return {
    type: types.SEND_REQUEST_GET_EXTERNAL_INVESTMENT_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearGetExternalInvestment() {
  return {
    type: types.CLEAR_REQUEST_GET_EXTERNAL_INVESTMENT,
    payload: {},
  };
}
