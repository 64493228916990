import React from 'react'
import DashboardLayout from '../../views/main/deals/DashboardLayout'

const AboutUs = () => {
  return (
    <>
        <DashboardLayout>
            <div className="md-title text-start fw-700 cr-heading">
                About Us
            </div>
        </DashboardLayout>
    </>
  )
}

export default AboutUs