import { Outlet, useSearchParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Footer from './elements/Footer';
import Header from './elements/Header';
import Cookies from "js-cookie";

import domainDetails from "../store/detailsAsPerDomain";


const MainLayout = () => {
    const [searchParams] = useSearchParams();
    const domainName = window.location.hostname;
    var st = domainName.toString();
    var subdomain = process.env.REACT_APP_SUB_DOMAIN;
    const domainSlug = st.slice(0, st.indexOf(subdomain));
    Cookies.set("domainSlug", domainSlug);
    var getDomainDetails = domainDetails(domainSlug);
    // if (searchParams.get("transaction_id")) {
    //     Cookies.set("consentTransactionId", searchParams.get("transaction_id"));
    // }

    return (
        <>
            <ToastContainer limit={1} />

            <Header getDomainDetails={getDomainDetails} />

            <Outlet />

            <Footer />
        </>
    );
};

export default MainLayout;
