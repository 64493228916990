import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../views/main/deals/DashboardLayout'
import arrowRight from '../../assets/img/right-arrow.svg'
import backArrow from '../../assets/img/back.svg'
import portfolioIcon from '../../assets/img/portfolio-icon.svg'
import capitalSummary from '../../assets/img/capital_summary.png'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearGetMyBackrrs, getMyBackrrs } from '../../store/actions/getMyBackrrsAction'
import noListingImg from '../../assets/img/no-listing-found.png'
import Modal from '../../utils/Modal'
import RangeSlider from 'react-range-slider-input/dist/components/RangeSlider'
import 'react-range-slider-input/dist/style.css';
import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { formateDate } from '../../utils/utils'

const MyBackrrs = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [partners, setPartners] = useState([])
    const [data, setData] = useState([])
    const [cityArray, setCityArray] = useState([])
    const [sourceArray, setSourceArray] = useState([])
    const [selectedCity, setSelectedCity] = useState(null)
    const [selectedGender, setSelectedGender] = useState(null)
    const [showFilter, setShowFilter] = useState(false)
    const [selectedSort, setSelectedSort] = useState(null)
    const [selectedReferBy, setSelectedReferBy] = useState(null)
    const [minAge, setMinAge] = useState(18)
    const [maxAge, setMaxAge] = useState(90)
    const [filterApplied, setFilterApplied] = useState(false)

    const [totalCommitments, setTotalCommitments] = useState(null)
    const [totalInvested, setTotalInvested] = useState(null)

    const [showCapitalSummary, setShowCapitalSummary] = useState(false)
    const [chartData, setChartData] = useState(null)

    let gender = [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
    ]

    const getMyBackrrsResponse = useSelector(state => state.getMyBackrrsReducer.data);
    const getMyBackrrsLoader = useSelector(state => state.getMyBackrrsReducer.loading);

    useEffect(() => {
        setShowFilter(false)
        setSelectedCity(null)
        setSelectedGender(null)
        setMinAge(18)
        setMaxAge(90)
        setSelectedReferBy(null)
        setSelectedSort(null)
        setFilterApplied(false)
        dispatch(getMyBackrrs());
    }, [])

    useEffect(() => {
        if (getMyBackrrsResponse != null) {
            if (getMyBackrrsResponse.data != null) {
                console.log("getMyBackrrsResponse", getMyBackrrsResponse)
                setData(getMyBackrrsResponse?.data)
                setPartners(getMyBackrrsResponse?.data)
                let city = []
                let source = []
                for (let partner of getMyBackrrsResponse.data) {
                    if (partner?.city_name?.trim()?.length > 0) {
                        if ((city.filter(e => e.label === partner.city_name).length <= 0)) {
                            city.push({ label: partner.city_name, value: partner.city_name })
                        }
                    }
                    if (partner?.vp_name?.trim()?.length > 0) {
                        if ((source.filter(e => e.label === partner.vp_name).length <= 0)) {
                            source.push({ label: partner.vp_name, value: partner.vp_name })
                        }
                    }
                }
                city = city.sort((a, b) => a?.label?.localeCompare(b.label))
                setCityArray(city)
                setSourceArray(source)
                setChartData(getMyBackrrsResponse?.chartData)
                dispatch(clearGetMyBackrrs());
            }
        }
    }, [getMyBackrrsResponse]);

    useEffect(() => {
        if (data) {
            let totalCommit = data.reduce((a, v) => a + v.original_commitment_amount, 0)
            let totalInvest = data.reduce((a, v) => a + v.original_interestedAmount, 0)
            console.log("totalInvest", totalInvest)
            console.log("totalCommit", totalCommit)
            convertToShort(totalInvest, "invest")
            convertToShort(totalCommit, "commit")
        }
    }, [data])

    const convertToShort = (val, type) => {
        if (val) {
            if (val >= 10000000) {
                let val_cr = (val / 10000000);
                let split_amount = val_cr?.toString()?.split(".");

                if (split_amount?.length > 1) {
                    if (split_amount[1][0] == "0" && split_amount[1][1] == "0") {
                        val = split_amount[0] + 'Cr'
                    } else {
                        val = val_cr.toFixed(2) + 'Cr';
                    }
                } else {
                    val = val_cr + 'Cr';
                }
            } else if (val >= 100000) {
                let val_l = (val / 100000);
                let split_amount = val_l.toString().split(".");
                if (split_amount.length > 1) {
                    if (split_amount[1][0] == "0" && split_amount[1][1] == "0") {
                        val = split_amount[0] + 'L'
                    } else {
                        val = val_l.toFixed(2) + 'L';
                    }
                } else {
                    val = val_l + 'L';
                }
            } else if (val >= 1000) {
                let val_k = (val / 1000);
                let split_amount = val_k.toString().split(".");
                if (split_amount.length > 1) {
                    if (split_amount[1][0] == "0" && split_amount[1][1] == "0") {
                        val = split_amount[0] + 'K'
                    } else {
                        val = val_k.toFixed(2) + 'K';
                    }
                } else {
                    val = val_k + 'K';
                }
            }
        } else {
            val = 0;
        }
        console.log("val", val);
        if (type == "invest") {
            setTotalInvested(val)
        } else {
            setTotalCommitments(val)
        }
    }

    const filterPartners = (city, gender, referBy, minAge, maxAge) => {
        console.log("city", city)
        let partnersArray = partners
        let filteredPartners = partnersArray
        console.log("filteredPartners Before", filteredPartners)
        if (city != null) {
            if (city == 'All') {
                filteredPartners = partnersArray
            } else {
                filteredPartners = filteredPartners.filter(item => item.city_name == city)
            }
            setFilterApplied(true)
        }
        if (gender != null) {
            if (gender == 'All') {
                filteredPartners = partnersArray
            } else {
                filteredPartners = filteredPartners.filter(item => item.gender == gender)
            }
            setFilterApplied(true)
        }
        if (referBy != null) {
            if (referBy == 'Direct') {
                filteredPartners = filteredPartners.filter(item => item.vp_name?.trim()?.length == 0)
            } else {
                filteredPartners = filteredPartners.filter(item => item.vp_name == referBy)
            }
            setFilterApplied(true)
        }
        if (minAge > 18 || maxAge < 90) {
            filteredPartners = filteredPartners.filter(item => Number(item.age) >= minAge && Number(item.age) <= maxAge)
            setFilterApplied(true)
        }

        console.log("filteredPartners After", filteredPartners)
        setData(filteredPartners)
        setSelectedSort(null)
        setShowFilter(false);
    }

    const sortPartners = (event) => {
        console.log("sortBy", event.target.value)
        let reA = /[^a-zA-Z-]/g;
        let reN = /\D/g;
        let partnersArray = data
        let sortedPartners = partnersArray
        console.log("partnersArray", partnersArray)
        if (event.target.value != "Null") {
            switch (event.target.value) {
                case 'Name - A to Z': {
                    sortedPartners = partnersArray.sort((a, b) => a?.name?.localeCompare(b.name))
                    break;
                }
                case 'Name - Z to A': {
                    sortedPartners = partnersArray.sort((a, b) => b?.name?.localeCompare(a.name))
                    break;
                }
                case 'Amount Commitment - Low to High': {
                    sortedPartners = partnersArray.sort((a, b) => a.original_commitment_amount - b.original_commitment_amount)
                    break;
                }
                case 'Amount Commitment - High to Low': {
                    sortedPartners = partnersArray.sort((a, b) => b.original_commitment_amount - a.original_commitment_amount)
                    break;
                }
                case 'Amount Invested - Low to High': {
                    sortedPartners = partnersArray.sort((a, b) => a.original_interestedAmount - b.original_interestedAmount)
                    break;
                }
                case 'Amount Invested - High to Low': {
                    sortedPartners = partnersArray.sort((a, b) => b.original_interestedAmount - a.original_interestedAmount)
                    break;
                }
                case 'LP Number - Low to High': {
                    sortedPartners = partnersArray.sort((a, b) => {
                        let aA = a.lp_code?.replace(reA, "");
                        let bA = b.lp_code?.replace(reA, "");
                        if (aA === bA) {
                            let aN = parseInt(a.lp_code?.replace(reN, ""), 10);
                            let bN = parseInt(b.lp_code?.replace(reN, ""), 10);
                            if (aN === bN) {
                                return 0
                            } else if (aN > bN) {
                                return 1
                            } else {
                                return -1
                            }
                        } else {
                            return aA > bA ? 1 : -1;
                        }
                    })
                    break;
                }
                case 'LP Number - High to Low': {
                    sortedPartners = partnersArray.sort((a, b) => {
                        let aA = a.lp_code?.replace(reA, "");
                        let bA = b.lp_code?.replace(reA, "");
                        if (aA === bA) {
                            let aN = parseInt(a.lp_code?.replace(reN, ""), 10);
                            let bN = parseInt(b.lp_code?.replace(reN, ""), 10);
                            if (aN === bN) {
                                return 0
                            } else if (bN > aN) {
                                return 1
                            } else {
                                return -1
                            }
                        } else {
                            return bA > aA ? 1 : -1;
                        }
                    })
                    break;
                }
            }
            setData([])
            setTimeout(() => {
                setData(sortedPartners)
            }, 10)
        } else {
            setData([])
            setTimeout(() => {
                setData(partners)
            }, 10)
        }
        console.log("sortedPartners", sortedPartners)
    }

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value)
        setSelectedSort("Null")
    }

    const handleGenderChange = (event) => {
        setSelectedGender(event.target.value)
        setSelectedSort("Null")
    }

    const handleSourceChange = (event) => {
        setSelectedReferBy(event.target.value)
        setSelectedSort("Null")
    }

    const submitFilter = () => {
        filterPartners(selectedCity, selectedGender, selectedReferBy, minAge, maxAge)
        setShowFilter(false)
    }

    return (
        <DashboardLayout>
            <div className='header-mobile d-md-none border-b'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div>
                        <Link className="back-btn blue-text d-block" to="/dashboard">
                            <img src={backArrow} alt="arrow" /> My Backrrs
                        </Link>
                    </div>
                    <div className='d-flex align-items-center'>
                        <div>
                            <button className="back-btn" type="button" onClick={() => { navigate("/all-backrr-portfolio") }}><img src={portfolioIcon} alt="arrow" className='d-inline-block' /></button>
                        </div>
                        <div>
                            <button className="back-btn" type="button" onClick={() => { setShowCapitalSummary(true) }}><img src={capitalSummary} alt="arrow" className='d-inline-block' /></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='addround-header header-desktop'>
                <div className='container'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className="md-title text-start fw-700 cursor-pointer d-flex align-items-center">
                            My Backrrs
                        </div>
                        <div className='d-flex align-items-center'>
                            <div>
                                <button className="back-btn" type="button" onClick={() => { navigate("/all-backrr-portfolio") }}><img src={portfolioIcon} alt="arrow" className='d-inline-block' /></button>
                            </div>
                            <div>
                                <button className="back-btn" type="button" onClick={() => { setShowCapitalSummary(true) }}><img src={capitalSummary} alt="arrow" className='d-inline-block' /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-4 pb-4 ps-5 pe-5">
                <div className='favourite-list'>
                    <div className="d-flex align-items-center justify-content-between mb-3 mt-4 filter-selector">
                        <div className='text-lgray'>Total Backrrs ({data?.length})</div>
                        <div className='ml-auto custom-form'>
                            <select className='form-control cursor-pointer' value={selectedSort} onChange={sortPartners}>
                                <option value="Null">Sort By</option>
                                <option value="Name - A to Z">Name - A to Z</option>
                                <option value="Name - Z to A">Name - Z to A</option>
                                <option value="Amount Commitment - Low to High">Amount Commitment - Low to High</option>
                                <option value="Amount Commitment - High to Low">Amount Commitment - High to Low</option>
                                <option value="Amount Invested - Low to High">Amount Invested - Low to High</option>
                                <option value="Amount Invested - High to Low">Amount Invested - High to Low</option>
                                <option value="LP Number - Low to High">LP Number - Low to High</option>
                                <option value="LP Number - High to Low">LP Number - High to Low</option>
                            </select>
                        </div>
                        <div>
                            <button type='button' className='filter-btn' style={{ color: filterApplied && '#FF6624' }} onClick={() => { setShowFilter(true) }}>
                                <i className={filterApplied ? "bi bi-funnel-fill" : "bi bi-funnel"}></i>
                            </button>
                        </div>
                    </div>
                    {
                        data?.length > 0 ? data?.map((item) => {
                            return (
                                <div key={item._id} className="d-flex align-items-center justify-content-between border-b pt-3 pb-3 cursor-pointer" onClick={() => { navigate("/my-backrrs-deals", { state: item }) }}>
                                    <div className="d-flex align-items-center">
                                        <div className="circle-box">
                                            <img src={item?.profile_image_url} />
                                        </div>
                                        <div className="ml-15">
                                            <div className="md-title mb-0 text-start">{item.name}</div>
                                            {item.lp_code && <div className="sm-title font-0-8em text-start black-text">{item.lp_code}</div>}
                                            {item.signature_date && <div className="sm-title font-0-8em text-start black-text">Onboarded: {formateDate(item.signature_date)}</div>}
                                            {item.interestedAmountNew && <div className="sm-title font-0-8em text-start black-text">Interested amount: {item.interestedAmountNew}</div>}
                                            {item.unallocated_amount && <div className="sm-title font-0-8em text-start black-text">Unallocated amount : {item.unallocated_amount}</div>}
                                            {item.kyc_status && <div className="sm-title font-0-8em text-start black-text">KYC Status: <span style={{ color: item.kyc_color }}>{item.kyc_status}</span></div>}
                                        </div>
                                    </div>
                                    <div className='ml-auto'>
                                        <div className="sm-title font-0-8em text-end black-text fw-700">Invested</div>
                                        <div className="sm-title font-0-8em text-end black-text mt-1"><span className='orange-text'>{item.interestedAmount}</span>{item.original_commitment_amount > 0 && "/" + item.commitment_amount}</div>
                                    </div>
                                    <div className="hm-arrow ms-3"><img src={arrowRight} alt="arrow" /></div>
                                </div>
                            )
                        }) : <div className="no-listing-card text-center pt-4">
                            <img src={noListingImg} alt="no list" />
                            <div className="xs-title text-center mt-4 text-lgray">No backrrs found</div>
                        </div>
                    }
                    {
                        getMyBackrrsLoader && <div className='spinner text-center'><span className='spinner-border spinner-border-xl text-warning'></span></div>
                    }
                </div>
            </div>

            <Modal commonModalShow={showFilter} crossHide={true} hideHeading={true} bodyPadding={true}
                commonModalData={
                    <>
                        <div className='d-flex align-items-center p-3'>
                            <h5 className='md-title mb-0'>
                                Filters
                            </h5>
                            <div className='ml-auto'>
                                <button className='transparent-btn text-uppercase' type='button' onClick={() => {
                                    setSelectedCity(null)
                                    setSelectedGender(null)
                                    setSelectedReferBy(null)
                                    setMinAge(18)
                                    setMaxAge(90)
                                    setFilterApplied(false)
                                    filterPartners(null, null, null, 18, 90)
                                }}>Clear All</button>
                            </div>
                        </div>
                        <div className='custom-tabs d-flex vertical-tabs'>
                            <ul className="nav nav-tabs d-block" id="filterTabs" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="city-tab" data-bs-toggle="tab" data-bs-target="#city" type="button" role="tab" aria-controls="city" aria-selected="true">City</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="gender-tab" data-bs-toggle="tab" data-bs-target="#gender" type="button" role="tab" aria-controls="gender" aria-selected="false">Gender</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="age-tab" data-bs-toggle="tab" data-bs-target="#age" type="button" role="tab" aria-controls="age" aria-selected="false">Age Group</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="source-tab" data-bs-toggle="tab" data-bs-target="#source" type="button" role="tab" aria-controls="source" aria-selected="false">Source</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="filterTabsContent">
                                <div className="tab-pane fade show active" id="city" role="tabpanel" aria-labelledby="city-tab">
                                    <div className="custom-radio mt-3">
                                        <input className="styled-radio" id="cityAll" type="radio" value="All" onChange={handleCityChange} name="city" />
                                        <label htmlFor="cityAll">Select All ({cityArray.length})</label>
                                    </div>
                                    {cityArray?.map((val, indx) => (
                                        <div className="custom-radio mt-3" key={val.label}>
                                            <input className="styled-radio" id={"City" + indx} type="radio" onChange={handleCityChange} value={val.value} name="city"
                                                checked={selectedCity === val.value}
                                            />

                                            <label htmlFor={"City" + indx}>{val.label}</label>
                                        </div>))}
                                </div>
                                <div className="tab-pane fade" id="gender" role="tabpanel" aria-labelledby="gender-tab">
                                    <div className="custom-radio mt-3">
                                        <input className="styled-radio" id="genderAll0" type="radio" onChange={handleGenderChange} value="All" name="gender" />
                                        <label htmlFor="genderAll0">Select All ({gender.length})</label>
                                    </div>
                                    {gender?.map((secVal, Secindx) => (

                                        <div className="custom-radio mt-3" key={secVal.label}>
                                            <input className="styled-radio" id={"Gender" + Secindx} type="radio" onChange={handleGenderChange} value={secVal.value} name="gender"
                                                checked={selectedGender === secVal.value}
                                            />
                                            <label htmlFor={"Gender" + Secindx}>{secVal.label} </label>

                                        </div>))}
                                </div>
                                <div className="tab-pane fade" id="age" role="tabpanel" aria-labelledby="age-tab">
                                    <div>Age {minAge} - {maxAge}</div>
                                    <div className="custom-radio mt-3">
                                        <RangeSlider
                                            min={18}
                                            max={90}
                                            step={1}
                                            value={[minAge, maxAge]}
                                            onInput={([min, max]) => { setMinAge(min); setMaxAge(max) }}
                                        />
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="source" role="tabpanel" aria-labelledby="source-tab">
                                    <div className="custom-radio mt-3">
                                        <input className="styled-radio" id="sourceAll0" type="radio" onChange={handleSourceChange} value="Direct" name="source" />
                                        <label htmlFor="sourceAll0">Direct</label>
                                    </div>
                                    {sourceArray?.map((secVal, Secindx) => (

                                        <div className="custom-radio mt-3" key={secVal.label}>
                                            <input className="styled-radio" id={"Source" + Secindx} type="radio" onChange={handleSourceChange} value={secVal.value} name="source"
                                                checked={selectedReferBy === secVal.value}
                                            />
                                            <label htmlFor={"Source" + Secindx}>{secVal.label} </label>

                                        </div>))}
                                </div>
                            </div>
                        </div>
                        <div className='text-center mt-3 mb-3 ps-3 pe-3'>
                            <button className='gray-btn sm-btn w-180 close-btn me-3' type="button" onClick={() => { setShowFilter(false) }}>CLOSE</button>
                            <button className='theme-btn w-180' onClick={() => submitFilter()} type="button">APPLY</button>
                        </div>
                    </>
                }>

            </Modal>
            <Modal
                commonModalTitle={"Capital Summary"}
                commonModalShow={showCapitalSummary}
                commonModalClose={() => { setShowCapitalSummary(false) }}
                commonModalData={
                    <>
                        <div className='d-flex align-items-center justify-content-between mb-4'>
                            <h5 className='md-title mb-0' id='documentModalLabel'>Capital Summary</h5>
                            <button type='button' className='btn-close' onClick={() => { setShowCapitalSummary(false) }}></button>
                        </div>
                        <div>
                            <div className='my-3 d-flex align-items-center justify-content-center'>
                                <div style={{ height: 180, width: 180 }}>
                                    <CircularProgressbarWithChildren
                                        value={100}
                                        maxValue={100}
                                        strokeWidth={10}
                                        styles={{
                                            path: {
                                                stroke: '#A1C878'
                                            }
                                        }}
                                    >
                                        <div style={{ height: 145, width: 145 }}>
                                            <CircularProgressbar
                                                value={(chartData?.calledUpCapitalPercentage/chartData?.totalCommitmentAmountPercentage)*100}
                                                maxValue={100}
                                                strokeWidth={10}
                                                styles={{
                                                    path: {
                                                        stroke: '#F8D295'
                                                    },
                                                    trail: {
                                                        stroke: 'transparent'
                                                    },
                                                }}
                                            />
                                        </div>
                                    </CircularProgressbarWithChildren>
                                </div>


                            </div>
                            <div className='mb-mxw-400'>
                                <div className='d-flex align-items-center justify-content-between mb-4 main-home-card '>
                                    <div className='w-50 px-3 text-center'>
                                            <div className='distribution-item pb-0 d-inline-flex'>
                                                <span
                                                    className='distribution-circle'
                                                    style={{
                                                        background: '#A1C878',
                                                    }}
                                                ></span>
                                                <div>
                                                    <div className="blank-link sm-title font-0-8em text-center mb-1">Committed Amount</div>
                                                    <div className="blank-link sm-title font-0-8em text-center text-black fw-700">{chartData?.totalCommitmentAmount}</div>
                                                </div>
                                            </div>
                                    </div>
                                    <div className='w-50 px-3 text-center'>
                                            <div className='distribution-item pb-0 d-inline-flex'>
                                                <span
                                                    className='distribution-circle'
                                                    style={{
                                                        background: '#F8D295',
                                                    }}
                                                ></span>
                                                <div>
                                                    <div className="blank-link sm-title font-0-8em text-center mb-1">Called up Capital</div>
                                                    <div className="blank-link sm-title font-0-8em text-center text-black fw-700">{chartData?.calledUpCapital}</div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-between mb-4'>
                                    <div className='w-50 px-3 text-center'>
                                        <div className='card form-card px-4 py-3 w-100'>
                                            <div className="blank-link sm-title font-0-8em text-center mb-1">Allocated Amount</div>
                                            <div className="blank-link sm-title font-0-8em text-center text-black fw-700">{chartData?.totalAllocatedAmount}</div>
                                        </div>
                                    </div>
                                    <div className='w-50 px-3 text-center'>
                                        <div className='card form-card px-4 py-3 w-100'>
                                            <div className="blank-link sm-title font-0-8em text-center mb-1">Unallocated Capital</div>
                                            <div className="blank-link sm-title font-0-8em text-center text-black fw-700">{chartData?.totalUnAllocatedAmount}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-between mb-4'>
                                    <div className='w-50 px-3'>
                                        <div className='card form-card px-4 py-3 w-100'>
                                            <div className="blank-link sm-title font-0-8em text-center">Amount Received</div>
                                            <div className="blank-link sm-title font-0-8em text-center mb-1">(Live deals)</div>
                                            <div className="blank-link sm-title font-0-8em text-center text-black fw-700">{chartData?.receivedAmount}</div>
                                        </div>
                                    </div>
                                    <div className='w-50 px-3 text-center'>
                                        <div className='card form-card px-4 py-3 w-100'>
                                            <div className="blank-link sm-title font-0-8em text-center">Amount Due</div>
                                            <div className="blank-link sm-title font-0-8em text-center mb-1">(Live deals)</div>
                                            <div className="blank-link sm-title font-0-8em text-center text-black fw-700">{chartData?.totalAmountDue}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            />
        </DashboardLayout>
    )
}

export default MyBackrrs