import {types} from './types';

export function saveRating(data) {
  return {
    type: types.SEND_REQUEST_SAVE_RATING,
    payload: data,
  };
}

export function saveRatingSuccess(data) {
  return {
    type: types.SEND_REQUEST_SAVE_RATING_SUCCESS,
    payload: data,
  };
}

export function saveRatingFailure(error) {
  return {
    type: types.SEND_REQUEST_SAVE_RATING_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearSaveRating() {
  return {
    type: types.CLEAR_REQUEST_SAVE_RATING,
    payload: {},
  };
}
