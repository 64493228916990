import React from 'react'
import { useSearchParams } from 'react-router-dom';
import { GetDealDetails } from '../../../api';
import DashboardLayout from '../deals/DashboardLayout'
import backArrow from '../../../assets/img/back.svg'
import { Link } from 'react-router-dom'

function FAQS() {

  const [searchParams] = useSearchParams();

  const slug = searchParams.get("slug");
  const {
    data: dealDatares,
    isLoading,
    refetch: refetchData,
  } = GetDealDetails({ slug: slug });
  const dealData = dealDatares ? dealDatares.data[0] : [];
  const faqs = dealData?.faq

  return (
    <DashboardLayout>
      <div className='header-mobile d-md-none border-b'>
          <div className='d-flex align-items-center justify-content-between'>
              <div>
                  <Link className="back-btn blue-text d-block" to="/dashboard">
                      <img src={backArrow} alt="arrow" /> {dealData?.startups?.company_name} - FAQ's
                  </Link>
              </div>
          </div>
      </div>
      <div className="md-title text-start fw-700 cr-heading header-desktop">
        {dealData?.startups?.company_name} - FAQ's
      </div>
      <section className="faqs-wrap p-4">
        {
          faqs?.map((item, index) => {
            return (
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id={index}>
                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                      data-bs-target={"#collapse" + index} aria-expanded="true" aria-controls={"collapse" + index}>
                      {item.question}
                    </button>
                  </h2>
                  <div id={"collapse" + index} className="accordion-collapse collapse show" aria-labelledby={index}
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      {item.answer}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        }
      </section>
    </DashboardLayout>
  )
}

export default FAQS