import React, { useRef } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import BtnSpinner from "../../../../utils/BtnSpinner";
import {
  CustomCheckboxGroup,
} from "../../../../components/formik/FieldComponents";
import { SaveQuestions, FetchAllIndustries, GetUserDetailsUpdated } from "../../../../api";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  UPDATE_PERSONALIZE_YOUR_PROFILE,
} from "../../../../store/actions";
import { useEffect, useState } from "react";
import { personalizeProfile } from "../../../../store/lcocalStaticVariable";

import DashboardLayout from "../../../main/deals/DashboardLayout";
import backArrow from '../../../../assets/img/back.svg'

function DeclarationProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useRef()
  const [searchParams] = useSearchParams();

  const checkUSerType = useSelector((state) => state.userDetails);

  const [is_editable, setIsEditable] = useState(null);
  const [edit_access, setEditAccess] = useState(true);

  const [selectedCommitment, setSelectedCommitment] = useState(null)

  const { data: userData, isLoading: isDocsLoading } = GetUserDetailsUpdated();

  const commitmentOptions = [
    { label: '+ ₹25L', value: "2500000" },
    { label: '+ ₹50L', value: "5000000" },
    { label: '+ ₹75L', value: "7500000" },
    { label: '+ ₹1Cr', value: "10000000" },
  ]

  const { mutateAsync: saveQuestions, isLoading: isSaveQuestionsLoading } =
    SaveQuestions();

  useEffect(() => {
    setIsEditable(userData?.kyc_editable)
  }, [isDocsLoading])

  const toggleEdit = () => {
    // 👇️ passed function to setState
    setEditAccess(edit_access => !edit_access);
  };

  useEffect(() => {
    if (searchParams.has("editable")) {
      setEditAccess(searchParams.get("editable") == 'true')
      console.log("editable", searchParams.get("editable"))
    }
  }, [searchParams])

  const personalizeYourProfile = useSelector(
    (state) => state.indianCitizenship.personalizeYourProfile)

  const personalizeYourProfileValidationSchema = Yup.object().shape({
    confirmation: Yup.array().min(5, "Please confirm all details"),
    commitment_amount: Yup.number().integer().required("Commitment Amount is required").moreThan(0, "Commitment Amount is required"),

  });

  return (
    <DashboardLayout>
      <div className='header-mobile d-md-none border-b'>
        <div className='d-flex align-items-center justify-content-between'>
          <div>
            <Link className="back-btn blue-text d-block" to={"/my-profile/upload-documents?editable=" + edit_access}>
              <img src={backArrow} alt="arrow" /> KYC Details
            </Link>
          </div>

          {is_editable && <div>
            <button type="button" onClick={toggleEdit} className='theme-btn btn btn-sm' >{edit_access ? 'Edit' : 'Cancel'}</button>
          </div>}
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-between cr-heading header-desktop'>
        <div className="md-title text-start fw-700">
          <button className='back-btn' onClick={() => navigate("/my-profile/upload-documents?editable=" + edit_access)} type='button'><img src={backArrow} alt='arrow' /></button>  KYC Details
        </div>
        {is_editable && <div>
          <button type="button" onClick={toggleEdit} className='theme-btn btn btn-sm' >{edit_access ? 'Edit' : 'Cancel'}</button>
        </div>}
      </div>
      <div className="pt-4 pb-4 ps-5 pe-5">
        <div className="pt-0 pb-5 kyc-form-block pyp-block">
          <Formik
            innerRef={formik}
            initialValues={personalizeYourProfile}
            enableReinitialize={true}
            validationSchema={personalizeYourProfileValidationSchema}
            onSubmit={(values) => {
              var newexpertiseInSectors = [];
              values.expertiseInSectors.map((ob) =>
                newexpertiseInSectors.push(ob.value ? ob.value : ob)
              );
              values.expertiseInSectors = newexpertiseInSectors;

              var neweinvestInSectors = [];
              values.investInSectors.map((ob) =>
                neweinvestInSectors.push(ob.value ? ob.value : ob)
              );
              values.investInSectors = neweinvestInSectors;

              saveQuestions(
                {
                  payload: {
                    commitment_amount: values.commitment_amount,
                    personalize_profile_static: values,
                    page: checkUSerType?.userType == 'indian_corporate' || checkUSerType?.userType == 'foreign_corporate' || checkUSerType?.userType == 'huf' ? "5" : "6",
                    re_submit: false,
                  },
                },
                {
                  onSuccess: () => {
                    dispatch({
                      type: UPDATE_PERSONALIZE_YOUR_PROFILE,
                      payload: values,
                    });
                    // dispatch({ type: SET_PAGE, payload: 7 });
                    navigate("/my-profile");
                  },
                }
              );
            }}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form className="custom-form">
                <h5 className="card-title md-title mb-4 text-center fw-700">
                  Commitment
                </h5>
                <div className="form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                  <label className="required">Enter Commitment Amount</label>

                  <Field
                    type="number"
                    className="form-control"
                    name="commitment_amount"
                    min="0"
                    disabled={edit_access}
                    onChange={(e) => {
                      setFieldValue("commitment_amount", e.target.value);
                      setSelectedCommitment(null)
                    }
                    }
                  />
                  {errors.commitment_amount && touched.commitment_amount && (
                    <small className="text-danger">{errors.commitment_amount}</small>
                  )}
                </div>

                <div className="mb-4 grid">
                  {
                    commitmentOptions.map(item => {
                      return (
                        <button type="button" disabled={edit_access} style={{ opacity: edit_access ? 0.5 : 1 }} className={`item ${item.label === selectedCommitment && "selected-item"}`} onClick={() => { setSelectedCommitment(item.label); formik?.current?.setFieldValue("commitment_amount", item.value); }}>{item.label}</button>
                      )
                    })
                  }
                </div>
                <h5 className="card-title md-title mb-4 text-center fw-700">
                  Declaration
                </h5>
                <div className="row" style={{ opacity: edit_access ? 0.5 : 1 }}>
                  <div
                    className="col-12 form-group"
                    role="group"
                    aria-labelledby="checkbox-group"
                  >
                    <label className="mb-2 required">Confirmation</label>
                    <div className="rradio-mb">
                      <Field
                        name="confirmation"
                        component={CustomCheckboxGroup}
                        options={checkUSerType?.userType == 'indian_corporate' || checkUSerType?.userType == 'foreign_corporate' ? personalizeProfile.confirmationCorporate : personalizeProfile.confirmation}
                        multiline={"yes"}
                        disabled={edit_access}
                        className="react-checkbox"
                      />
                    </div>
                  </div>
                </div>

                {!edit_access ? isSaveQuestionsLoading ? (
                  <BtnSpinner />
                ) : (
                  <div className="mt-3 text-center">
                    <button
                      type="submit"
                      className="theme-btn btn w-25 ml-15"
                    >
                      Submit
                    </button>
                  </div>
                ) : <div className="mt-3 text-center">
                  <button type="button" onClick={() => navigate("/my-profile")} className="theme-btn btn w-25">
                    Next
                  </button>
                </div>}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default DeclarationProfile;
