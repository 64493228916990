import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import backArrow from "../../../../assets/img/back.svg";
import RoundDetails from '../../../../components/ExternalInvestment/RoundDetails';
import InvestmentDetails from '../../../../components/ExternalInvestment/InvestmentDetails';
import DocumentDetails from '../../../../components/ExternalInvestment/DocumentDetails';
import { clearUpdateExternalInvestment, updateExternalInvestment } from '../../../../store/actions/updateExternalInvestmentAction'
import { clearSaveExternalInvestment, saveExternalInvestment } from '../../../../store/actions/saveExternalInvestmentAction'
import { toast } from 'react-toastify';
import { formateDate } from '../../../../utils/utils';

export default function AddRounds() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { state } = useLocation();

    const [activeTab, setActiveTab] = useState(0);
    const [roundData, setRoundData] = useState(null)

    const saveExternalInvestmentResponse = useSelector(state => state.saveExternalInvestmentReducer.data)
    const updateExternalInvestmentResponse = useSelector(state => state.updateExternalInvestmentReducer.data)

    const tabs = [
        { label: 'Round Details', content: <RoundDetails roundData={roundData} handleSubmit={(values) => { setActiveTab(1); setRoundData({ ...roundData, ...values }) }} /> },
        { label: 'Investment Details', content: <InvestmentDetails roundData={roundData} handleSubmit={(values) => { setActiveTab(2); setRoundData({ ...roundData, ...values }) }} /> },
        { label: 'Documents', content: <DocumentDetails roundData={roundData} handleSubmit={(values) => { saveInvestmentData(values) }} /> },
    ];

    useEffect(() => {
        setRoundData({
            round_name: null,
            investment_date: null,
            syndicate_name: null,
            security_type: null,
            no_of_units: null,
            per_share_unit: null,
            investment_amount: null,
            current_value_per_unit: null,
            current_investment_value: null,
            documents: [],
        })
        if (state?.roundData) {
            setRoundData({ ...state?.roundData, ...{ investment_date: formateDate(state?.roundData?.investment_date) } })
        }
    }, [])

    console.log("state", state)

    const docFile = (doc) => {
        let file
        if (doc.name) {
            file = new File([doc], doc.name)
        } else {
            file = new File([doc], doc.title + "." + doc.filename.split(/[#?]/)[0].split(".").pop().trim())
        }
        return file;
    }

    const saveInvestmentData = (roundDetails) => {
        let request = new FormData()

        request.append("startup_name", state?.startupData?.startup_name)
        request.append("founder_name", state?.startupData?.founder_name)
        request.append("founder_email", state?.startupData?.founder_email)
        request.append("sectors", state?.startupData?.sectors)
        request.append("stages", state?.startupData?.stages)

        if (state?.startupData?.startup_id != null) {
            console.log("Edit Startup")
            // Edit Starup
            let rounds = state?.startupData?.rounds
            if (state?.roundData != null) {
                console.log("Edit Round")
                // Edit Round
                if (rounds?.length > 0) {
                    for (let [i, round] of rounds.entries()) {
                        console.log("documents", state?.roundData._id, "round Id", round._id)
                        if (round._id == state?.roundData._id) {
                            request.append("rounds[" + i + "][round_name]", roundDetails.round_name)
                            request.append("rounds[" + i + "][investment_date]", roundDetails.investment_date)
                            request.append("rounds[" + i + "][syndicate_name]", roundDetails.syndicate_name)
                            request.append("rounds[" + i + "][security_type]", roundDetails.security_type)
                            request.append("rounds[" + i + "][no_of_units]", roundDetails.no_of_units)
                            request.append("rounds[" + i + "][per_share_unit]", roundDetails.per_share_unit)
                            request.append("rounds[" + i + "][investment_amount]", roundDetails.investment_amount?.split(".")[0]?.replace(new RegExp(",", "g"), "").replace(new RegExp("₹", "g"), "").replace(/[^0-9]/g, ''))
                            request.append("rounds[" + i + "][current_value_per_unit]", roundDetails.current_value_per_unit)
                            request.append("rounds[" + i + "][current_investment_value]", roundDetails.current_investment_value?.split(".")[0]?.replace(new RegExp(",", "g"), "").replace(new RegExp("₹", "g"), "").replace(/[^0-9]/g, ''))
                            request.append("rounds[" + i + "][additional_notes]", null)

                            if (roundDetails.documents?.length > 0) {
                                for (let doc of roundDetails.documents) {
                                    let count = i
                                    let countKey = 'round' + count
                                    console.log("countKey ===", countKey)
                                    request.append(countKey, docFile(doc))
                                }
                            }
                        } else {
                            request.append("rounds[" + i + "][round_name]", round.round_name)
                            request.append("rounds[" + i + "][investment_date]", round.investment_date)
                            request.append("rounds[" + i + "][syndicate_name]", round.syndicate_name)
                            request.append("rounds[" + i + "][security_type]", round.security_type)
                            request.append("rounds[" + i + "][no_of_units]", round.no_of_units)
                            request.append("rounds[" + i + "][per_share_unit]", round.per_share_unit)
                            request.append("rounds[" + i + "][investment_amount]", round.investment_amount)
                            request.append("rounds[" + i + "][current_value_per_unit]", round.current_value_per_unit)
                            request.append("rounds[" + i + "][current_investment_value]", round.current_investment_value)
                            request.append("rounds[" + i + "][additional_notes]", null)

                            if (round.documents?.length > 0) {
                                for (let doc of round.documents) {
                                    let count = i
                                    let countKey = 'round' + count
                                    console.log("countKey ===", countKey)
                                    request.append(countKey, docFile(doc))
                                }
                            }
                        }
                    }
                } else {
                    request.append("rounds[0][round_name]", roundDetails.round_name)
                    request.append("rounds[0][investment_date]", roundDetails.investment_date)
                    request.append("rounds[0][syndicate_name]", roundDetails.syndicate_name)
                    request.append("rounds[0][security_type]", roundDetails.security_type)
                    request.append("rounds[0][no_of_units]", roundDetails.no_of_units)
                    request.append("rounds[0][per_share_unit]", roundDetails.per_share_unit)
                    request.append("rounds[0][investment_amount]", roundDetails.investment_amount?.split(".")[0]?.replace(new RegExp(",", "g"), "").replace(new RegExp("₹", "g"), "").replace(/[^0-9]/g, ''))
                    request.append("rounds[0][current_value_per_unit]", roundDetails.current_value_per_unit)
                    request.append("rounds[0][current_investment_value]", roundDetails.current_investment_value?.split(".")[0]?.replace(new RegExp(",", "g"), "").replace(new RegExp("₹", "g"), "").replace(/[^0-9]/g, ''))
                    request.append("rounds[0][additional_notes]", null)

                    if (roundDetails.documents?.length > 0) {
                        for (let doc of roundDetails.documents) {
                            let countKey = 'round0'
                            request.append(countKey, docFile(doc))
                        }
                    }
                }
            } else {
                console.log("Add Round")
                // Add Round
                if (rounds?.length > 0) {
                    // Previous + New
                    for (let [i, round] of rounds.entries()) {
                        request.append("rounds[" + i + "][round_name]", round.round_name)
                        request.append("rounds[" + i + "][investment_date]", round.investment_date)
                        request.append("rounds[" + i + "][syndicate_name]", round.syndicate_name)
                        request.append("rounds[" + i + "][security_type]", round.security_type)
                        request.append("rounds[" + i + "][no_of_units]", round.no_of_units)
                        request.append("rounds[" + i + "][per_share_unit]", round.per_share_unit)
                        request.append("rounds[" + i + "][investment_amount]", round.investment_amount)
                        request.append("rounds[" + i + "][current_value_per_unit]", round.current_value_per_unit)
                        request.append("rounds[" + i + "][current_investment_value]", round.current_investment_value)
                        request.append("rounds[" + i + "][additional_notes]", null)

                        if (round.documents?.length > 0) {
                            for (let doc of round.documents) {
                                let count = i
                                let countKey = 'round' + count
                                console.log("countKey ===", countKey)
                                request.append(countKey, docFile(doc))
                            }
                        }
                    }
                    let newCount = rounds?.length
                    request.append("rounds[" + newCount + "][round_name]", roundDetails.round_name)
                    request.append("rounds[" + newCount + "][investment_date]", roundDetails.investment_date)
                    request.append("rounds[" + newCount + "][syndicate_name]", roundDetails.syndicate_name)
                    request.append("rounds[" + newCount + "][security_type]", roundDetails.security_type)
                    request.append("rounds[" + newCount + "][no_of_units]", roundDetails.no_of_units)
                    request.append("rounds[" + newCount + "][per_share_unit]", roundDetails.per_share_unit)
                    request.append("rounds[" + newCount + "][investment_amount]", roundDetails.investment_amount?.split(".")[0]?.replace(new RegExp(",", "g"), "").replace(new RegExp("₹", "g"), "").replace(/[^0-9]/g, ''))
                    request.append("rounds[" + newCount + "][current_value_per_unit]", roundDetails.current_value_per_unit)
                    request.append("rounds[" + newCount + "][current_investment_value]", roundDetails.current_investment_value?.split(".")[0]?.replace(new RegExp(",", "g"), "").replace(new RegExp("₹", "g"), "").replace(/[^0-9]/g, ''))
                    request.append("rounds[" + newCount + "][additional_notes]", null)

                    if (roundDetails.documents?.length > 0) {
                        for (let doc of roundDetails.documents) {
                            let count = newCount
                            let countKey = 'round' + count
                            console.log("countKey ===", countKey)
                            request.append(countKey, docFile(doc))
                        }
                    }
                } else {
                    // First round
                    request.append("rounds[0][round_name]", roundDetails.round_name)
                    request.append("rounds[0][investment_date]", roundDetails.investment_date)
                    request.append("rounds[0][syndicate_name]", roundDetails.syndicate_name)
                    request.append("rounds[0][security_type]", roundDetails.security_type)
                    request.append("rounds[0][no_of_units]", roundDetails.no_of_units)
                    request.append("rounds[0][per_share_unit]", roundDetails.per_share_unit)
                    request.append("rounds[0][investment_amount]", roundDetails.investment_amount?.split(".")[0]?.replace(new RegExp(",", "g"), "").replace(new RegExp("₹", "g"), "").replace(/[^0-9]/g, ''))
                    request.append("rounds[0][current_value_per_unit]", roundDetails.current_value_per_unit)
                    request.append("rounds[0][current_investment_value]", roundDetails.current_investment_value?.split(".")[0]?.replace(new RegExp(",", "g"), "").replace(new RegExp("₹", "g"), "").replace(/[^0-9]/g, ''))
                    request.append("rounds[0][additional_notes]", null)

                    if (roundDetails.documents?.length > 0) {
                        for (let doc of roundDetails.documents) {
                            let countKey = 'round0'
                            request.append(countKey, docFile(doc))
                        }
                    }
                }
            }
        } else {
            console.log("Add New Startup")
            // Add New Startup
            request.append("rounds[0][round_name]", roundDetails.round_name)
            request.append("rounds[0][investment_date]", roundDetails.investment_date)
            request.append("rounds[0][syndicate_name]", roundDetails.syndicate_name)
            request.append("rounds[0][security_type]", roundDetails.security_type)
            request.append("rounds[0][no_of_units]", roundDetails.no_of_units)
            request.append("rounds[0][per_share_unit]", roundDetails.per_share_unit)
            request.append("rounds[0][investment_amount]", roundDetails.investment_amount?.split(".")[0]?.replace(new RegExp(",", "g"), "").replace(new RegExp("₹", "g"), "").replace(/[^0-9]/g, ''))
            request.append("rounds[0][current_value_per_unit]", roundDetails.current_value_per_unit)
            request.append("rounds[0][current_investment_value]", roundDetails.current_investment_value?.split(".")[0]?.replace(new RegExp(",", "g"), "").replace(new RegExp("₹", "g"), "").replace(/[^0-9]/g, ''))
            request.append("rounds[0][additional_notes]", null)

            if (roundDetails.documents?.length > 0) {
                for (let doc of roundDetails.documents) {
                    console.log("doc", doc)
                    let countKey = 'round0'
                    request.append(countKey, docFile(doc))
                }
            }
        }

        for (let pair of request.entries()) {
            console.log('request', pair);
        }
        if (state?.startupData?.startup_id != null) {
            console.log("startup_id", state?.startupData?.startup_id)
            dispatch(updateExternalInvestment({ request: request, id: state?.startupData?.startup_id }))
        } else {
            dispatch(saveExternalInvestment(request));
        }
    }

    useEffect(() => {
        if (saveExternalInvestmentResponse != null) {
            console.log("saveExternalInvestmentResponse", saveExternalInvestmentResponse)
            if (saveExternalInvestmentResponse?.data != null) {
                navigate("/add-investment", { state: { syndicate_id: saveExternalInvestmentResponse?.data?.external_portfolio_id } })
                toast.success(saveExternalInvestmentResponse.message)
                dispatch(clearSaveExternalInvestment())
            }
        }
    }, [saveExternalInvestmentResponse])

    useEffect(() => {
        if (updateExternalInvestmentResponse != null) {
            console.log("updateExternalInvestmentResponse", updateExternalInvestmentResponse)
            if (updateExternalInvestmentResponse?.data != null) {
                navigate("/add-investment", { state: { syndicate_id: updateExternalInvestmentResponse?.data?.external_portfolio_id } })
                toast.success(updateExternalInvestmentResponse.message)
                dispatch(clearUpdateExternalInvestment())
            }
        }
    }, [updateExternalInvestmentResponse])

    return (
        <div>
            <div className='header-mobile d-md-none border-b'>
                <div className='d-flex align-items-center addround-flex'>
                    <div>
                        <button className="back-btn blue-text d-block" onClick={() => { activeTab == 0 ? navigate(-1) : setActiveTab(prev => prev - 1) }}>
                            <img src={backArrow} alt="arrow" /> Add Round Details
                        </button>
                    </div>
                    <div className='md-title text-center fw-700 addround-md-title'>{state?.startupData?.startup_name}</div>
                </div>
            </div>
            <div className='pyp-block'>
                <div className='addround-header header-desktop'>
                    <div className='container'>
                        <div className='d-flex align-items-center addround-flex'>
                            <div>
                                <div class="md-title text-start fw-700 cursor-pointer d-flex align-items-center" onClick={() => { activeTab == 0 ? navigate(-1) : setActiveTab(prev => prev - 1) }}>
                                    <button class="back-btn" type="button"><img src={backArrow} alt="arrow" className='d-inline-block' /></button>
                                    <div className='pt-1 blue-text'>Add Round Details</div>
                                </div>
                            </div>
                            <div className='md-title text-center fw-700 addround-md-title'>{state?.startupData?.startup_name}</div>
                        </div>
                    </div>
                </div>
                <div className='text-center'>
                    <div className="tab-buttons addround-tabs-btns d-inline-flex pt-5">
                        {tabs.map((tab, index) => (
                            <div
                                key={index}
                                className={`addround-btn ${activeTab == index ? 'active' : ''}`}
                                // onClick={() => { setActiveTab(index) }}
                            >
                                <span className='addround-step-count'>{index + 1}</span> {tab.label}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="tab-content addround-content">
                {tabs[activeTab].content}
            </div>
        </div>
    )
}
