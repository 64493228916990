import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { changePIN, clearChangePIN } from '../../../../store/actions/changePINAction';
import BtnSpinner from '../../../../utils/BtnSpinner';
import DashboardLayout from '../../deals/DashboardLayout';
import backArrow from '../../../../assets/img/back.svg'

const changePinValidationSchema = Yup.object().shape({
    currentpin: Yup.string().required('Please enter current PIN'),
    pin: Yup.string().required('Please enter PIN').matches(/^\d+$/, "Must be only digits").max(4, "Please enter 4 digit PIN").min(4, "Please enter 4 digit PIN"),
    confirmpin: Yup.string().required('Please confirm PIN').oneOf([Yup.ref('pin'), null], 'PIN and Confirm PIN must me same'),
})
const ChangePin = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const changePINResponse = useSelector(state => state.changePINReducer.data)
    const changePINLoading = useSelector(state => state.changePINReducer.loading)

    useEffect(() => {
        if (changePINResponse != null) {
            if (changePINResponse.data != null) {
                console.log("changePINResponse", changePINResponse)
                toast.success(changePINResponse.message)
                dispatch(clearChangePIN())
                navigate(-1)
            }
        }
    }, [changePINResponse])

    return (
        <>
            <DashboardLayout>
                <div className='header-mobile d-md-none border-b'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div>
                            <Link className="back-btn blue-text d-block" to="/my-profile">
                                <img src={backArrow} alt="arrow" /> Change PIN
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="md-title text-start fw-700 cr-heading header-desktop">
                    Change PIN
                </div>
                <div className="pt-4 pb-4 ps-5 pe-5">
                    <Formik
                        validationSchema={changePinValidationSchema}
                        initialValues={{
                            currentpin: '',
                            pin: '',
                            confirmpin: '',
                        }}
                        onSubmit={(values) => {
                            console.log(values)
                            let request = {
                                old_pin: values.currentpin,
                                pin: values.pin,
                                confirm_pin: values.confirmpin
                            }
                            dispatch(changePIN(request))
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form className="custom-form">
                                <div className='form-group mb-3'>
                                    <label>Current Pin</label>
                                    <Field
                                        name="currentpin"
                                        type="text"
                                        className="form-control"
                                    />
                                    {errors.currentpin && touched.currentpin && (
                                        <small className='text-danger'>{errors.currentpin}</small>
                                    )}
                                </div>
                                <div className='form-group mb-3'>
                                    <label>Pin</label>
                                    <Field
                                        name="pin"
                                        type="text"
                                        className="form-control"
                                    />
                                    {errors.pin && touched.pin && (
                                        <small className='text-danger'>{errors.pin}</small>
                                    )}
                                </div>
                                <div className='form-group mb-3'>
                                    <label>Confirm Pin</label>
                                    <Field
                                        name="confirmpin"
                                        type="text"
                                        className="form-control"
                                    />
                                    {errors.confirmpin && touched.confirmpin && (
                                        <small className='text-danger'>{errors.confirmpin}</small>
                                    )}
                                </div>
                                {changePINLoading ? (
                                    <BtnSpinner />
                                ) : (
                                    <div className="mt-3 text-center">
                                        <button type="submit" className="theme-btn btn w-25">
                                            Next
                                        </button>
                                    </div>
                                )}
                            </Form>
                        )}
                    </Formik>
                </div>
            </DashboardLayout>
        </>
    )
}

export default ChangePin