import { types } from './types';

export function getMyBackrrDeals(data) {
  return {
    type: types.SEND_REQUEST_GET_MY_BACKRR_DEALS,
    payload: data,
  };
}

export function getMyBackrrDealsSuccess(data) {
  return {
    type: types.SEND_REQUEST_GET_MY_BACKRR_DEALS_SUCCESS,
    payload: data,
  };
}

export function getMyBackrrDealsFailure(error) {
  return {
    type: types.SEND_REQUEST_GET_MY_BACKRR_DEALS_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearGetMyBackrrDeals() {
  return {
    type: types.CLEAR_REQUEST_GET_MY_BACKRR_DEALS,
    payload: {},
  };
}
