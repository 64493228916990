import moment from "moment/moment";
import ReactPlayer from "react-player";
import Modal from "../../../utils/Modal";
import { useEffect, useRef, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { getFounderNames, getLeadInvestorNames } from "../../../utils/helpers";
import { CallForMoney, InvestmentInterest, ShowCase } from "./PitchCall";
import pitchRecording from "../../../assets/img/pitch-recording.svg";
import faq from "../../../assets/img/faq_blue.png"

// Images
import { GetDealDetails } from "../../../api";

import {
  Document,
  FbIcon,
  LinIcon,
  Location,
  Pdf,
  TwIcon,
  ViewIcon,
  Web,
} from "../../../assets/img";
import StarRating from "../../../utils/StarRating";
import { clearSaveFavourite, saveFavourite } from "../../../store/actions/saveFavouriteAction";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import backArrow from '../../../assets/img/back.svg'
import facebookIcon from '../../../assets/img/facebook1.svg'
import InstagramIcon from '../../../assets/img/instagram1.png'
import twitterIcon from '../../../assets/img/twitter1.svg'
import linkedlnIcon from '../../../assets/img/linkedIn1.svg'

const DealDetails = () => {

  const dispatch = useDispatch()
  const faqRef = useRef()

  const [searchParams] = useSearchParams();

  const slug = searchParams.get("slug");
  const page = searchParams.get("page");
  const [commonModalShow, setCommonModalShow] = useState(false);

  const saveFavouriteResponse = useSelector(state => state.saveFavouriteReducer.data)
  const getCordinatesLoader = useSelector(state => state.getCordinatesReducer.loading)

  const commonModalClose = () => setCommonModalShow(false);
  const commonHandleShow = () => {
    setCommonModalShow(true);
  };

  const {
    data: dealDatares,
    isLoading,
    refetch: refetchData,
  } = GetDealDetails({ slug: slug });
  // console.log(GetDealDetails,"GetDealDetailsGetDealDetailsGetDealDetailsGetDealDetailsGetDealDetailsGetDealDetails")
  const dealData = dealDatares ? dealDatares.data[0] : [];

  const unallocatedAmount = dealDatares ? dealDatares.unallocated_amount : 0;

  useEffect(() => {
    window.scrollTo(0, 0)
    if(page) {
      scrollToDiv(-200, faqRef)
    }
  }, [])

  const addBookmark = (dealDeatils) => {
    console.log("is Fav", dealDeatils?.is_fav)
    let request = {
      startup_deal_id: dealDeatils._id
    }

    dispatch(saveFavourite(request))
  }

  useEffect(() => {
    if (saveFavouriteResponse != null) {
      if (saveFavouriteResponse.data != null) {
        console.log("saveFavouriteResponse", saveFavouriteResponse)
        toast.success(saveFavouriteResponse.message)
        refetchData()
        dispatch(clearSaveFavourite())
      }
    }
  }, [saveFavouriteResponse])

  const scrollToDiv = (yOffset, myRef) => {
    const y = myRef?.current?.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  if (isLoading)
    return (
      <div className="loading-box">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  return (
    <>
      <section className="inner-wrap">
        <div className="detail-card">
          <div className="header-mobile d-md-none border-b">
            <Link className='back-btn blue-text d-block' to="/deal-list?deal-id=all-deal" type='button'><img src={backArrow} alt='arrow' /> {dealData?.company_name} </Link>
          </div>
          <div className="startup-profile-header border-b">
            <div className="container">
              <div className="d-block align-items-center justify-content-between pt-3 pb-3 text-center dtl-top-info">
                <div className="d-block align-items-center">
                  <div className="circle-box circle-text">
                    {dealData?.startups && dealData?.startups?.image ? (
                      <img
                        src={dealData?.startups?.image}
                        alt={dealData?.company_name}
                      />
                    ) : (<span className="ct-name md-title">{dealData?.company_name?.split(" ")[0]?.substring(0, 1)}{dealData?.company_name?.split(" ")[1]?.substring(0, 1)}</span>)}
                  </div>

                  {/* Deal */}
                  <div className="mb-3">
                    <div className="md-title mb-6">
                      {dealData?.company_name}
                    </div>

                    {dealData?.startups?.city_name != "" && (
                      <div className="sm-title font-0-8em text-center">
                        <span className="text-lgray">
                          {dealData?.startups.city_name}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="md-title mb-6 font-1em">{dealData?.startups?.description}</div>
                </div>
                <div>
                  {dealData?.faq?.length > 0 && <button type="button" onClick={() => {scrollToDiv(-200, faqRef)}} className="ela-action faq-tag"><img src={faq} alt="faq" /></button>}
                  <button type="button" className={dealData?.is_fav == "Yes" ? "ela-action selected mt-1" : "ela-action mt-1"} onClick={() => { addBookmark(dealData) }}><i class={dealData?.is_fav == "Yes" ? "bi bi-star-fill" : "bi bi-star"}></i></button>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="border-b deal-info-block dtl-deal-info-block">
              <div className="row pt-5 pb-5">
                <div className="col-xl-7 col-lg-6 pr-3">
                  {/* Description */}
                  {/* {dealData?.startups && dealData?.startups?.description && (
                    <div className="mb-3">
                      <div className="sm-title text-start font-0-9em fw-700 black-text mb-2">
                        Description:
                      </div>

                      <div className="sm-title text-start font-0-8em">
                        {dealData?.startups?.description}
                      </div>
                    </div>
                  )} */}

                  {/* Founders */}
                  {dealData?.startups &&
                    dealData?.startups?.founders.length > 0 && (
                      <div className="mb-3">
                        <div className="sm-title text-start font-0-9em fw-700 black-text mb-2">
                          Founder(s):
                        </div>

                        <div className="sm-title text-start font-0-8em">
                          {getFounderNames(dealData?.startups?.founders, true)}
                        </div>
                      </div>
                    )}

                  {/* Lead Investors */}
                  {dealData?.startups &&
                    dealData?.startups?.lead_investors.length > 0 && (
                      <div className="mb-3">
                        <div className="sm-title text-start font-0-9em fw-700 black-text mb-2">
                          Lead Investor(s):
                        </div>

                        <div className="sm-title text-start font-0-8em">
                          {getLeadInvestorNames(
                            dealData?.startups?.lead_investors,
                            true
                          )}
                        </div>
                      </div>
                    )}

                  {dealData?.fixed_details?.security_type && (
                    <div className="mb-3">
                      <div className="sm-title text-start font-0-9em fw-700 black-text mb-2">Security type:</div>
                      <div className="sm-title text-start font-0-8em">
                        {dealData?.fixed_details?.security_type}
                      </div>
                    </div>
                  )}

                  <div className="dtl-info d-flex">
                    {/* Website */}
                    {dealData?.startups && dealData?.startups?.website_url && (
                      <div className="col-info-box mb-2 me-2">
                        <Link
                          to={dealData?.startups?.website_url}
                          target="_blank"
                        >
                          <div className="gray-box text-center">
                            <div className="icon">
                              <img src={Web} alt="web" />
                            </div>

                            <div className="sm-title font-0-8em black-text">
                              Website
                            </div>
                          </div>
                        </Link>
                      </div>
                    )}

                    {/* Documents */}
                    {dealData?.startups &&
                      dealData?.startups?.document.length > 0 && (
                        <div className="col-info-box mb-2 me-2">
                          <button
                            type="button"
                            onClick={commonHandleShow}
                            className="gray-box d-block text-center w-100"
                          >
                            <div className="icon">
                              <img src={Document} alt="document" />
                            </div>

                            <div className="sm-title font-0-8em black-text">
                              Documents
                            </div>
                          </button>
                        </div>
                      )}

                    {/* pitch recording */}

                    {(dealData?.deal_flow[0]?.value === "investment_interest" ||
                      dealData?.deal_flow[0]?.value === "call_for_money") &&
                      dealData.meetings[0]?.pitch_recording && (
                        <div className="col-info-box mb-2 me-2">
                          <Link
                            to={dealData?.meetings[0]?.pitch_recording}
                            target="_blank"
                            className="gray-box d-block text-center w-100"
                          >
                            <div className="icon">
                              <img src={pitchRecording} alt="pitch recording" />
                            </div>
                            <div className="sm-title">Pitch Recording</div>
                          </Link>
                        </div>
                      )}
                  </div>
                </div>
                {/* Pitch Call */}
                {dealData?.deal_flow && (
                  <div className="col-xl-5 col-lg-6">
                    {dealData?.deal_flow[0]?.value === "startup_showcase" && (
                      <ShowCase dealData={dealData} />
                    )}

                    {dealData?.deal_flow[0]?.value === "investment_interest" && (
                      <InvestmentInterest
                        dealData={dealData}
                        refetchData={refetchData}
                        unallocatedAmount={unallocatedAmount}
                      />
                    )}

                    {dealData?.deal_flow[0]?.value === "call_for_money" && (
                      <CallForMoney
                        dealData={dealData}
                        refetchData={refetchData}
                        unallocatedAmount={unallocatedAmount}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="border-b deal-dsc-block">
              {/* Overview */}
              <div className="row pt-5 pb-5">
                <div className="md-title col-12">Overview</div>
                <div className="col-lg-7">
                  {dealData?.startups && dealData?.startups?.overview && (
                    <div className="desc mt-3">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: dealData?.startups?.overview.replace(
                            /\n/g,
                            "<br />"
                          ),
                        }}
                      ></p>
                    </div>
                  )}

                  {dealData?.startups &&
                    (dealData?.startups?.facebook_page_url ||
                      dealData?.startups?.instagram_page_url ||
                      dealData?.startups?.linkedln_page_url ||
                      dealData?.startups?.twitter_page_url) && (
                      <div>
                        <div className="sm-title black-text mt-2 text-start">
                          Follow us on:
                        </div>

                        <div className="mt-2 social-icons">
                          {/* Facebook URL */}
                          {dealData?.startups?.facebook_page_url && (
                            <Link
                              to={dealData?.startups?.facebook_page_url}
                              target="_blank"
                            >
                              <img src={facebookIcon} alt="fb" />
                            </Link>
                          )}

                          {/* Instagram URL */}
                          {dealData?.startups?.instagram_page_url && (
                            <Link
                              to={dealData?.startups?.instagram_page_url}
                              target="_blank"
                            >
                              <img src={InstagramIcon} alt="ins" />
                            </Link>
                          )}

                          {/* LinkedIn URL */}
                          {dealData?.startups?.linkedln_page_url && (
                            <Link
                              to={dealData?.startups?.linkedln_page_url}
                              target="_blank"
                            >
                              <img src={linkedlnIcon} alt="lin" />
                            </Link>
                          )}

                          {/* Twitter URL */}
                          {dealData?.startups?.twitter_page_url && (
                            <Link
                              to={dealData?.startups?.twitter_page_url}
                              target="_blank"
                            >
                              <img src={twitterIcon} alt="tw" />
                            </Link>
                          )}
                        </div>
                      </div>
                    )}

                  {dealData?.startups &&
                    dealData?.startups?.videos.length > 0 && (
                      <div className="video-thumb mt-3">
                        <ReactPlayer
                          width="100%"
                          height="350px"
                          url={dealData?.startups?.videos[0].url}
                          controls={true}
                        />
                      </div>
                    )}
                </div>
                <div className="col-lg-5 border-l">
                  {dealData?.real_time_analysis && (
                    <div className="plr-30">
                      <div className="md-title col-12">Fund Manager Analysis</div>

                      <div className="sm-title text-start mt-4">
                        Overall Rating
                      </div>

                      <div className="d-inline-flex align-items-center mt-2">
                        <div className="rating-count lg-title">
                          {dealData?.rating_avg?.toFixed(1)}
                        </div>

                        <div className="ml-10">
                          <div className="rating-stars">
                            <StarRating rating={dealData?.rating_avg} />
                          </div>
                        </div>
                      </div>

                      {/* Star rating */}
                      <div className="mt-5 realtime-analysis-block">
                        <table className="w-100 custom-table">
                          <tbody>
                            {dealData?.real_time_analysis &&
                              dealData?.real_time_analysis.map((analysisData) => (
                                <tr key={analysisData._id}>
                                  <td>{analysisData.name}</td>
                                  <td className="text-end">
                                    <div className="rating-stars">
                                      <StarRating rating={analysisData.rating} />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="deal-dtl-block">
              <div className="row pt-5 pb-5">

                {/* Deal Flow Activities */}
                {dealData?.activities && (
                  <div className="col-lg-5 border-l order-2">
                    <div className="deal-dtl-box plr-30 deal-activity-flow">
                      <div className="md-title col-12">Deal Flow Activities</div>

                      <div className="mt-3">
                        <div className="deal-flow-block">
                          {dealData?.activities.map((activity) => (
                            <div className="deal-flow-item" key={activity._id}>
                              <span
                                className="deal-flow-circle"
                                style={{
                                  background: activity.masters.values[0].color,
                                }}
                              ></span>

                              <div className="sm-title text-start black-text fw-600">
                                {activity.masters.values[0].name}
                              </div>

                              <div className="sm-title text-start mt-2 font-0-8em">
                                {moment(activity.updatedAt).format(
                                  "DD-MMM-YYYY, hh:mm A"
                                )}{" "}
                                IST
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-lg-7">
                  {/* Deal Details */}
                  <div className="deal-dtl-box">
                    <div className="md-title col-12">Deal Details</div>

                    <div className="mt-3">
                      <table className="w-100 custom-table">
                        <tbody>
                          {dealData?.deal_details &&
                            dealData?.deal_details.map((dealDetail) => (
                              <tr key={dealDetail.key}>
                                <th className="w-40">{dealDetail.key}</th>
                                <td>{dealDetail.value}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>

                      {/* Scheme Letter */}
                      {dealData?.scheme_letter &&
                        dealData?.scheme_letter.length > 0 && (
                          <div className="mt-3 ps-1">
                            <Link
                              to={dealData?.scheme_letter[0]}
                              target="_blank"
                              className="text-decoration-underline orange-text fw-600 font-0-8em"
                            >
                              Download Scheme Term Sheet
                            </Link>
                          </div>
                        )}
                    </div>
                  </div>

                  {dealData?.faq?.length > 0 &&
                    <div>
                      <div className="deal-dtl-box pt-5" ref={faqRef}>
                        <div className="md-title col-12">FAQs</div>
                        {dealData?.faq?.map((item, index) => {
                          return (<div className="accordion sep-faq-accordion mt-4" id="accordionExample">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id={index}>
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                  data-bs-target={"#collapse" + index} aria-expanded="false" aria-controls={"collapse" + index}>
                                  {item.question}
                                </button>
                              </h2>
                              <div id={"collapse" + index} className="accordion-collapse collapse" aria-labelledby={index}
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  {item.answer}
                                </div>
                              </div>
                            </div>
                          </div>);
                        })}
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Documents Modal */}
      <Modal
        commonModalTitle="Documents"
        commonModalShow={commonModalShow}
        commonModalClose={commonModalClose}
        commonModalData={
          <>
            <div className='d-flex align-items-center justify-content-between mb-4'>
              <h5 className='md-title mb-0' id='documentModalLabel'>{dealData?.company_name} - Documents</h5>
              <button type='button' className='btn-close' onClick={commonModalClose}></button>
            </div>
            {/* <button className='btn-close close-pos-btn' type="button" onClick={commonModalClose}></button> */}
            <ul className="document-list mt-2">
              {dealData?.startups &&
                dealData?.startups?.document.length > 0 &&
                dealData?.startups?.document.map((doc) => (
                  <Link to={doc.url} target="_blank" key={doc.name}>
                    <li className="d-flex align-items-center">
                      <div className="icon">
                        <img src={Pdf} alt="pdf" />
                      </div>

                      <div className="ml-15">
                        <div className="md-title text-start">{doc.name}</div>
                        <div className="sm-title text-start mt-1">
                          {moment(doc.date).format("DD MMM YYYY")}
                        </div>
                      </div>

                      <div className="ml-auto">
                        <span className="view-icon">
                          <img src={ViewIcon} alt="view" />
                        </span>
                      </div>
                    </li>
                  </Link>
                ))}
            </ul>
          </>
        }
      />
      {
        getCordinatesLoader && <div className={`modal fade common-modal ${getCordinatesLoader ? 'show' : ''}`}><div className='spinner text-center'><span className='spinner-border spinner-border-xl text-warning loader'></span></div></div>
      }
    </>
  );
};

export default DealDetails;
