export const moreAboutYouData = {
  haveInvestedBefore: [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ],

  howHearAboutUs: [
    { label: "News", value: "news" },
    {
      label: "Referral from startup",
      value: "ReferralFromStartup",
    },
    { label: "Search", value: "search" },
    {
      label: "Referral from investor",
      value: "ReferralFromInvestor",
    },
  ],
};

export const personalizeProfile = {
  expertiseInSectors: [
    { label: "Real Estate", value: "Real Estate" },
    { label: "Medical", value: "Medical" },
    { label: "Transportation", value: "Transportation" },
    { label: "Software", value: "Software" },
    { label: "Hardware", value: "Hardware" },
  ],

  wantToInvesInSectors: [
    { label: "Real Estate", value: "Real Estate" },
    { label: "Medical", value: "Medical" },
    { label: "Transportation", value: "Transportation" },
    { label: "Software", value: "Software" },
    { label: "Hardware", value: "Hardware" },
  ],

  likeToInvestInStartupStage: [
    { label: "Pre-Seed", value: "Pre-Seed" },
    { label: "Seed", value: "Seed" },
    { label: "Pre-Series A", value: "Pre-Series A" },
    { label: "Series A", value: "Series A" },
    { label: "Series B, C, D", value: "Series B, C, D" },
    { label: "Growth Stage", value: "Growth Stage" },
  ],

  howDoYouPreferToInvest: [
    { label: "Lead Deals", value: "Lead Deals" },
    { label: "Co-invest with a group", value: "Co-invest with a group" },
  ],

  confirmation: [
    {
      label:
        "I hereby declare that I have prior experience with investing in startups or have 10 years of professional experience.",
      value: "prior_experience_checked",
    },

    {
      label:
        "I hereby declare that my net worth is more than INR 2 crores, excluding my primary residence.",
      value: "net_worth_2_crores_checked",
    },
    {
      label:
        "I confirm that the above KYC details provided by me are correct and true to my knowledge.",
      value: "key_provided_checked",
    },
    {
      label:
        "I hereby give consent to RTAF to acquire and utilize my CKYC information for its internal operational procedures.",
      value: "ckyc_consent",
    },
    {
      label:
        "I affirm that the bank account details that I have provided matches the details of the account from which I will transfer funds.",
      value: "bank_detail_affirmation",
    },
  ],

  confirmationCorporate: [
    {
      label:
        "I hereby declare that I have prior experience with investing in startups or have 10 years of professional experience.",
      value: "prior_experience_checked",
    },

    {
      label:
        "I hereby declare that my net worth is more than INR 10 crores, excluding my primary residence.",
      value: "net_worth_2_crores_checked",
    },
    {
      label:
        "I confirm that the above KYC details provided by me are correct and true to my knowledge.",
      value: "key_provided_checked",
    },
    {
      label:
        "I hereby give consent to RTAF to acquire and utilize my CKYC information for its internal operational procedures.",
      value: "ckyc_consent",
    },
    {
      label:
        "I affirm that the bank account details that I have provided matches the details of the account from which I will transfer funds.",
      value: "bank_detail_affirmation",
    },
  ],
};


export const IndianCitizenUserType = {
  individual_type: [
    { label: "HUF", value: "huf" },
    { label: "Personal", value: "individual" },

  ],
};
