import { Field, Form, FormikProvider, useFormik } from 'formik';
import React from 'react'
import * as Yup from "yup";
import { CustomInputComponent } from '../formik/FieldComponents';

export default function RoundDetails(props) {

    const roundValidation = {
        round_name: Yup.string().required("Round name is required"),
        investment_date: Yup.date().required("Investment Date is required"),
        syndicate_name: Yup.string().required("Syndicate Name is required"),
        security_type: Yup.string().required("Security Type is required"),
    };

    const formik = useFormik({
        initialValues: props.roundData,
        enableReinitialize: true,
        validationSchema: Yup.object().shape(roundValidation),
        onSubmit: (values) => {
            props.handleSubmit(values)
        }
    })



    console.log("formik", formik)

    return (
        <div className="pt-4 pb-4 ps-5 pe-5">
            <div className="pt-0 pb-0 kyc-form-block pyp-block">
                <FormikProvider
                    value={formik}
                    sx={{ flex: 1 }}
                >
                    <Form className="custom-form" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            {/* <h5 className="card-title md-title mb-4 text-center fw-700">
                                Round Details
                            </h5> */}
                            {/* Round Name */}
                            <div className="col-md-6 form-group">
                                <label className="required">Round Name</label>
                                <Field
                                    name="round_name"
                                    component={CustomInputComponent}
                                    type="text"
                                    className="form-control"
                                />
                            </div>

                            {/* Investment Date */}
                            <div className="col-md-6 form-group">
                                <label className="required">
                                    Investment Date
                                </label>
                                <Field
                                    name="investment_date"
                                    component={CustomInputComponent}
                                    type="date"
                                    className="form-control"
                                />
                            </div>

                            {/* Syndicate Name */}
                            <div className="col-md-6 form-group">
                                <label className="required">
                                    Platform/Syndicate Name
                                </label>
                                <Field
                                    name="syndicate_name"
                                    component={CustomInputComponent}
                                    type="text"
                                    className="form-control"

                                />
                            </div>

                            {/* Security Type */}
                            <div className="col-md-6 form-group">
                                <label className="required">Security Type</label>
                                <Field
                                    name="security_type"
                                    component={CustomInputComponent}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="mt-3 text-center">
                            <button type="submit" className="theme-btn btn w-25" disabled={!formik?.isValid || formik?.values?.round_name == undefined}>
                                Next
                            </button>
                        </div>
                    </Form>
                </FormikProvider>
            </div>
        </div>
    );
}
