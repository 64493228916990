import React, { useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import BtnSpinner from "../../../../utils/BtnSpinner";
import { Back } from "../../../../assets/img";
import { CustomCheckboxGroup } from "../../../../components/formik/FieldComponents";
import { SaveQuestions, FetchAllIndustries } from "../../../../api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  UPDATE_PERSONALIZE_YOUR_PROFILE,
  SET_PAGE,
} from "../../../../store/actions";
import { personalizeProfile } from "../../../../store/lcocalStaticVariable";

function PersonalizeYourInformation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useRef()
  const checkUSerType = useSelector((state) => state.userDetails);
  const { state } = useLocation();

  const { mutateAsync: saveQuestions, isLoading: isSaveQuestionsLoading } =
    SaveQuestions();
  const personalizeYourProfile = useSelector(
    (state) => state.indianCitizenship.personalizeYourProfile
  );
  const [issetValidationData, setValidationData] = useState(false);

  const [selectedCommitment, setSelectedCommitment] = useState(null)

  const commitmentOptions = [
    { label: '+ ₹25L', value: "2500000" },
    { label: '+ ₹50L', value: "5000000" },
    { label: '+ ₹75L', value: "7500000" },
    { label: '+ ₹1Cr', value: "10000000" },
  ]

  const personalizeYourProfileValidationSchema = Yup.object().shape({
    confirmation: Yup.array()
      .min(5, "Please confirm all details")
      .test("allSelected", "Please confirm all details", (value) => {
        return value && value.length === 5;
      }),
    commitment_amount: Yup.number().integer().required("Commitment Amount is required").moreThan(0, "Commitment Amount is required"),
  });

  return (
    <section className="mid-wrap">
      <div className="forms-header border-b pt-3 pb-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-2 col-2">
              <Link
                to={
                  state?.isNomineeAdded === "Yes"
                    ? "/nominee-details"
                    : "/upload-documents"
                }
                className="back-arrow"
              >
                <img src={Back} alt="back" />
              </Link>
            </div>
            <div className="col-md-8 col-8">
              <div className="lg-title text-center">Complete Your KYC</div>
            </div>
            <div className="col-md-2 col-2 text-end">
              <span className="pl-15 sm-title">{(checkUSerType.userType == 'indian_citizen' || checkUSerType.userType == 'huf' || checkUSerType.userType == 'foreign_citizen') ? '6/6' : '5/5'}</span>
            </div>
          </div>
          <div className="text-center step-process mt-1">
            <div className="d-inline-flex">
              <span className="process-step active"></span>
              <span className="process-step active"></span>
              <span className="process-step active"></span>
              <span className="process-step active"></span>
              {(checkUSerType.userType == 'indian_citizen' || checkUSerType.userType == 'huf' || checkUSerType.userType == 'foreign_citizen') && <span className="process-step active"></span>}
              <span className="process-step selected"></span>
            </div>
          </div>
          <h5 className="card-title md-title mt-2 mb-0 text-center heading-formobile">
            Personalize Your Profile
          </h5>
        </div>
      </div>
      <div className="pt-0 pb-5 kyc-form-block pyp-block">
        <div className="container">
          {issetValidationData && (
            <div className="text-danger error-box">
              Please Fill All Required Fields.
            </div>
          )}
          <div className="card form-card mt-4">
            <div className="card-body">
              <h5 className="card-title md-title mb-4 text-center heading-fordesktop">
                Personalize Your Profile
              </h5>
              <Formik
                innerRef={formik}
                initialValues={personalizeYourProfile}
                enableReinitialize={true}
                validationSchema={personalizeYourProfileValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (values.confirmation && values.confirmation.length === 5) {
                    let newexpertiseInSectors = [];
                    values.expertiseInSectors.forEach((ob) =>
                      newexpertiseInSectors.push(ob.value ? ob.value : ob)
                    );
                    values.expertiseInSectors = newexpertiseInSectors;

                    let neweinvestInSectors = [];
                    values.investInSectors.forEach((ob) =>
                      neweinvestInSectors.push(ob.value ? ob.value : ob)
                    );
                    values.investInSectors = neweinvestInSectors;

                    saveQuestions(
                      {
                        payload: {
                          commitment_amount: values.commitment_amount,
                          personalize_profile_static: values,
                          page: "7",
                          re_submit: false,
                        },
                      },
                      {
                        onSuccess: () => {
                          dispatch({
                            type: UPDATE_PERSONALIZE_YOUR_PROFILE,
                            payload: values,
                          });
                          dispatch({ type: SET_PAGE, payload: 7 });
                          navigate("/registration-status");
                        },
                      }
                    );
                  } else {
                    setValidationData(true);
                  }
                  setSubmitting(false);
                }}
              >
                {({ errors, touched, values, setFieldValue }) => (
                  <Form className="custom-form">
                    <h5 className="card-title md-title mb-4 text-center fw-700">
                      Commitment
                    </h5>
                    <div className="form-group">
                      <label className="required">Enter Commitment Amount</label>

                      <Field
                        type="number"
                        className="form-control"
                        name="commitment_amount"
                        min="0"
                        onChange={(e) => {
                          setFieldValue("commitment_amount", e.target.value);
                          setSelectedCommitment(null)
                        }
                        }
                      />
                      {errors.commitment_amount && touched.commitment_amount && (
                        <small className="text-danger">{errors.commitment_amount}</small>
                      )}
                    </div>

                    <div className="mb-4 grid">
                      {
                        commitmentOptions.map(item => {
                          return (
                            <button type="button" className={`item ${item.label === selectedCommitment && "selected-item"}`} onClick={() => { setSelectedCommitment(item.label); formik?.current?.setFieldValue("commitment_amount", item.value); }}>{item.label}</button>
                          )
                        })
                      }
                    </div>
                    <div className="row">
                      {/* I have expertise in these sectors */}

                      <div className="col-12 form-group">
                        <label className="mb-2 required">Confirmation</label>
                        <div className="rradio-mb">
                          <Field
                            name="confirmation"
                            component={CustomCheckboxGroup}
                            options={checkUSerType?.userType == 'indian_corporate' || checkUSerType?.userType == 'foreign_corporate' ? personalizeProfile.confirmationCorporate : personalizeProfile.confirmation}
                            multiline={"yes"}
                            className="react-checkbox"
                          />
                        </div>
                      </div>
                    </div>

                    {isSaveQuestionsLoading ? (
                      <BtnSpinner />
                    ) : (
                      <div className="mt-3 text-center">
                        <button
                          type="submit"
                          className="theme-btn btn w-25 ml-15"
                        >
                          Submit
                        </button>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PersonalizeYourInformation;
