import moment from "moment/moment";
import { GetUserDetailsUpdated } from "../../../api";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import rightArrow from "../../../assets/img/right-arrow.svg";
import hmIcon4 from "../../../assets/img/hm-icon4.svg";
import videoThumb from "../../../assets/img/video-thumb.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { NavLink } from "react-router-dom";
import DealLeaftMenu from "./DealLeaftMenu";
import DashboardLayout from "./DashboardLayout";
import Cookies from "js-cookie";
import { PDFDocument } from "pdf-lib";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { decode as atob, encode as btoa } from "base-64"
import { clearSendSignedDocument, sendSignedDocument } from "../../../store/actions/sendSignedDocumentAction";
import Modal from "../../../utils/Modal";
import { Document, Page, pdfjs } from "react-pdf";
import SignatureCanvas from 'react-signature-canvas'
import crossImg from "../../../assets/img/cross-icon.svg";
import { clearGetCoordinates, getCoordinates } from "../../../store/actions/getCordinatesAction";

const MyDeals = () => {

  const [openSchemeTerm, setOpenSchemeTerm] = useState(false)
  const [schemeTerm, setSchemeTerm] = useState(null)
  const [openSignatureOptions, setOpenSignatureOptions] = useState(false)
  const [signatureOptionSelected, setSignatureOptionSeclected] = useState(null)
  const [signBase64, setSignBase64] = useState(null)
  const [pdfEditMode, setPdfEditMode] = useState(false);
  const [pdfArrayBuffer, setPdfArrayBuffer] = useState(null);

  const [pdfUrl, setPdfUrl] = useState(null)
  const [interestedAmount, setInterestAmount] = useState(null)
  const [signaturePage, setSignaturePage] = useState(null)
  const [signatureCordinates, setSignatureCordinates] = useState(null)
  const [dragStart, setDragStart] = useState(null);
  const [signaturePosition, setSignaturePosition] = useState({ x: 0, y: 0 });
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [successMessage, setSuccessMessage] = useState(null)
  const [pageWidth, setPageWidth] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([])
  const [initialDocument, setInitialDocument] = useState(null)

  const signatureRef = useRef()
  const PDFViewRef = useRef()
  const dispatch = useDispatch()

  const sendSignedPdfResponse = useSelector(state => state.sendSignedPdfReducer.data)
  const getCordinatesResponse = useSelector(state => state.getCordinatesReducer.data)
  const getCordinatesLoader = useSelector(state => state.getCordinatesReducer.loading)

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  let randomLen = Math.floor(Math.random() * 10 + 1);
  const options = {
    loop: false,
    margin: 10,
    nav: false,
    stagePadding: 0,
    dots: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };

  // let consentDetails = {
  //   transaction_id: Cookies.get("consentTransactionId"),
  // }

  const { data: userData, isLoading: isDocsLoading } = GetUserDetailsUpdated();

  const finalResult = userData ? userData.homeData : [];

  useEffect(() => {
    if (userData) {
      let finalData = userData
      const fName = finalData.data.first_name
        ? finalData.data.first_name
        : "";

      const lName = finalData.data.last_name ? finalData.data.last_name : "";
      const mName = finalData.data.middle_name ? finalData.data.middle_name : "";

      const saveUserData = {
        name: fName + " " + lName,
        mob: finalData.data.mobile_number || "",
        email: finalData.data.email ? finalData.data.email : "",
        fullName: fName + " " + mName + " " + lName,
        showMyBackrr: finalData.show_mybackkr,
        isAdminLoggedin: finalData.isAdminLoggedin
      };

      console.log("saveUserData", saveUserData)

      Cookies.set("userDetail", JSON.stringify(saveUserData));

      // if (consentDetails?.transaction_id) {
      //   console.log("=========== SHOW PDF POPUP =============", consentDetails?.transaction_id)
      //   dispatch(getCoordinates({ transaction_id: consentDetails?.transaction_id }))
      // }
    }
  }, [userData])

  useEffect(() => {
    if (getCordinatesResponse != null) {
      if (getCordinatesResponse.statusCode == 200) {
        console.log("getCordinatesResponse", getCordinatesResponse)
        setInterestAmount(getCordinatesResponse?.data?.interested_amount)
        let cordinates = getCordinatesResponse.coordinates
        let pages = cordinates?.map((item) => { return item.page })
        setSignaturePage(pages)
        setSignatureCordinates(cordinates)
        setPdfUrl(getCordinatesResponse?.pdfUrl)
        // handleSchemeTerm(getCordinatesResponse?.pdfUrl)
        dispatch(clearGetCoordinates())
      }
    }
  }, [getCordinatesResponse])

  const handleSchemeTerm = async (url) => {
    console.log('url', url)
    await fetch(url, {
      headers: {
        // 'Content-Type': 'application/json',
        // 'Accept': 'application/pdf'
      }
    })
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise((res) => {
          reader.onloadend = async () => {
            res(reader.result);
            console.log("reader", reader)
            setInitialDocument(reader)
            setPdfArrayBuffer(base64ToArrayBuffer(reader.result.replace("data:application/pdf;base64,", "")));
            const loadedPdfDoc = await PDFDocument.load(base64ToArrayBuffer(reader.result.replace("data:application/pdf;base64,", "")));
            const firstPage = loadedPdfDoc.getPage(0);
            const { width, height } = firstPage.getSize();
            setPageWidth(width);
            setPageHeight(height);

            const modifiedPdfBytes = await loadedPdfDoc.save(); // Save the modified PDF
            const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            setSchemeTerm(url)
            setOpenSchemeTerm(true)
          }
        })
      })
      .catch(error => {
        console.log("error", error)
      })
  }

  const handleRadioChange = (event) => {
    setSignatureOptionSeclected(event.target.value);
  };

  const handleSignature = () => {
    if (signatureRef?.current?.isEmpty()) {
      toast.error("Please draw sigature")
    } else {
      const canvas = signatureRef.current.getTrimmedCanvas();
      let sign = canvas?.toDataURL()
      setSignBase64(sign);
      setOpenSignatureOptions(false);
      setPdfEditMode(true);

      // Set the initial position of the signature to the top corner of the popup
      const initialX = (signaturePosition.x);
      const initialY = (signaturePosition.y);
      // setSignaturePosition({ x: initialX, y: initialY });

      // Set the current page as the page where the signature is drawn
      setCurrentPage(pageNumber);
    }
  }

  const handleSignatureUpload = () => {
    if (selectedFiles.length == 0) {
      toast.error("Please upload sigature")
    } else {
      const reader = new FileReader()

      reader.readAsDataURL(selectedFiles[0])

      reader.onload = () => {

        setSignBase64(reader.result);
        setOpenSignatureOptions(false);
        setPdfEditMode(true);

        // Set the initial position of the signature to the top corner of the popup
        const initialX = (signaturePosition.x);
        const initialY = (signaturePosition.y);
        // setSignaturePosition({ x: initialX, y: initialY });

        // Set the current page as the page where the signature is drawn
        setCurrentPage(pageNumber);
      }
    }
  }

  useEffect(() => {
    if (signBase64) {
      handleSingleTap()
    }
  }, [signBase64])

  const handleSingleTap = async () => {

    const loadedPdfDoc = await PDFDocument.load(pdfArrayBuffer);
    if (loadedPdfDoc && signBase64) {
      let extension = signBase64?.split(";")[0]?.split("/")[1]
      const signatureImageBytes = await fetch(signBase64).then(res => res.arrayBuffer());
      const signatureImageEmbed = extension == 'png' ? await loadedPdfDoc.embedPng(signatureImageBytes) : await loadedPdfDoc.embedJpg(signatureImageBytes);
      const { width, height } = signatureImageEmbed.scale(0.18)

      signaturePage.forEach(page => {
        const pageToSign = loadedPdfDoc.getPage(page - 1);
        const pageWiseCordinates = signatureCordinates.filter(item => { return item.page == page })
        pageWiseCordinates.forEach(cordinates => {
          let x = cordinates.x
          let y = cordinates.y

          pageToSign.drawImage(signatureImageEmbed, {
            x: x - 30,
            y: y + 20,
            width: width,
            height: height < 35 ? height : 35
          });
        });
      });

      const modifiedPdfBytes = await loadedPdfDoc.save(); // Save the modified PDF
      const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      setSchemeTerm(url)
      // window.open(url)
    }
  }

  const base64ToArrayBuffer = (base64) => {
    const binary_string = atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  const uint8ToBase64 = (u8Arr) => {
    const CHUNK_SIZE = 0x8000; //arbitrary number
    let index = 0;
    const length = u8Arr.length;
    let result = "";
    let slice;
    while (index < length) {
      slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length));
      result += String.fromCharCode.apply(null, slice);
      index += CHUNK_SIZE;
    }
    return btoa(result);
  }

  const handleMouseMove = (e) => {
    if (dragStart) {
      const offsetX = e.clientX - dragStart.x;
      const offsetY = e.clientY - dragStart.y;
      setSignaturePosition({ x: signaturePosition.x + offsetX, y: signaturePosition.y + offsetY });
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setDragStart(null);
    setPdfEditMode(false)
  };

  const handleMouseDown = (e) => {
    setDragStart({ x: e.clientX, y: e.clientY });
  };

  const saveSignedPDF = async () => {
    const loadedPdfDoc = await PDFDocument.load(pdfArrayBuffer);
    if (loadedPdfDoc && signBase64) {
      let extension = signBase64?.split(";")[0]?.split("/")[1]
      const signatureImageBytes = await fetch(signBase64).then(res => res.arrayBuffer());
      const signatureImageEmbed = extension == 'png' ? await loadedPdfDoc.embedPng(signatureImageBytes) : await loadedPdfDoc.embedJpg(signatureImageBytes);
      const { width, height } = signatureImageEmbed.scale(0.18)
      signaturePage.forEach(page => {
        const pageToSign = loadedPdfDoc.getPage(page - 1);
        const pageWiseCordinates = signatureCordinates.filter(item => { return item.page == page })
        pageWiseCordinates.forEach(cordinates => {
          let x = cordinates.x
          let y = cordinates.y

          pageToSign.drawImage(signatureImageEmbed, {
            x: x - 30,
            y: y + 20,
            width: width,
            height: height < 35 ? height : 35
          });
        });
      });

      const modifiedPdfBytes = await loadedPdfDoc.save(); // Save the modified PDF
      const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      // window.open(url)

      const pdfBase64 = uint8ToBase64(modifiedPdfBytes);

      let byteCharacters = atob(pdfBase64);
      let byteArrays = [];
      let sliceSize = 512

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);

        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        let byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      let signedFile = new File(byteArrays, "Investment Scheme Term Sheet.pdf", { type: "application/pdf" });
      let deviceInfo = {
        device_type: "web",
        ip_address: window.location.host
      }
      let request = new FormData()
      if (signedFile != null) {
        request.append("device_info[device_type]", deviceInfo.device_type)
        request.append("device_info[ip_address]", deviceInfo.ip_address)
        request.append("interested_amount", interestedAmount)
        request.append("document", signedFile)
      }
      dispatch(sendSignedDocument({
        // id: consentDetails.transaction_id,
        request: request
      }))
    }
  }

  useEffect(() => {
    if (sendSignedPdfResponse != null) {
      if (sendSignedPdfResponse.statusCode == 200) {
        console.log("sendSignedPdfResponse", sendSignedPdfResponse)
        toast.success(successMessage)
        setSchemeTerm(null)
        setPageNumber(1)
        setCurrentPage(1)
        setSignaturePosition({ x: 0, y: 0 })
        setPdfArrayBuffer(null);
        setSignBase64(null);
        dispatch(clearSendSignedDocument())
        setSuccessMessage(null)
        setOpenSchemeTerm(false)
        // Cookies.remove("consentTransactionId");
      }
    }
  }, [sendSignedPdfResponse])

  const handleMouseCLick = (e) => {
    const rect = PDFViewRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left + PDFViewRef.current.scrollLeft;
    const y = e.clientY - rect.top + PDFViewRef.current.scrollTop;
    setSignaturePosition({ x: x, y: y });
  }

  const handleFileChange = (event) => {
    let allowedExtensions = ["jpg", "jpeg", "png"]
    let files = event.target.files;
    let extension = files[0]?.name.split(/[#?]/)[0].split(".").pop().trim()
    if (allowedExtensions.indexOf(extension) > -1) {
      for (let i = 0; i < files.length; i++) {
        files[i]["blob"] = URL.createObjectURL(files[i]);
      }
      let signImage = []
      setSelectedFiles([...signImage, ...files])
    } else {
      toast.error("Only image is allowed")
    }
  };

  const removeSignature = async () => {
    signatureRef?.current?.clear()
    setSignBase64(null)
    setPdfArrayBuffer(base64ToArrayBuffer(initialDocument.result.replace("data:application/pdf;base64,", "")));
    const loadedPdfDoc = await PDFDocument.load(base64ToArrayBuffer(initialDocument.result.replace("data:application/pdf;base64,", "")));
    const modifiedPdfBytes = await loadedPdfDoc.save(); // Save the modified PDF
    const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    setSchemeTerm(url)
  }

  const dashData = [];
  return (
    <>
      <DashboardLayout>
        {/* <section className="mid-wrap">
        <div className="pt-5 pb-5 main-home-card">
          <div className="container">
            <div className="d-flex common-flex">
              <DealLeaftMenu />
              <div className="common-right">
                <div className="custom-card"> */}
        <div className="md-title text-start fw-700 cr-heading">
          My Startup Deal Flow
        </div>
        <div className="pt-4 pb-4 ps-5 pe-5">
          <ul className="home-tab-list">
            {finalResult.map((dashDeatils, index) => (
              <li key={index}>
                <Link
                  to={`/deal-list?deal-id=${dashDeatils._id}&deal-type=${dashDeatils.values[0].name}`}
                  className="d-flex align-items-center"
                >
                  <div className="hm-icon">
                    <img src={dashDeatils.values[0].icon} alt="hm" />
                  </div>
                  <div className="hml-title">
                    {dashDeatils.values[0].name}
                  </div>
                  <div className='ml-auto d-flex align-items-center'>
                    <div
                      className={`badge ${dashDeatils.total.un_read_count > 0
                        ? "active"
                        : ""
                        } `}
                    >
                      {dashDeatils.total.un_read_count > 0
                        ? dashDeatils.total.un_read_count
                        : dashDeatils.total.total}
                    </div>
                    <div className="hm-arrow">
                      <img src={rightArrow} alt="arrow" />
                    </div>
                  </div>
                </Link>
              </li>
            ))}

            <li>
              <Link
                to={`/portfolio`}
                className="d-flex align-items-center"
              >
                <div className="hm-icon">
                  <img src={hmIcon4} alt="hm" />
                </div>
                <div className="hml-title">
                  My Portfolio
                </div>
                <div className='ml-auto d-flex align-items-center'>
                  <div className="hm-arrow">
                    <img src={rightArrow} alt="arrow" />
                  </div>
                </div>
              </Link>
            </li>

            {/* <li>
                                <Link to="#" className="d-flex align-items-center">
                                  <div className="hm-icon">
                                    <img src={hmIcon4} alt="hm" />
                                  </div>
                                  <div className="hml-title">My Portfolio</div>
                                  <div className="badge">0</div>
                                  <div className="hm-arrow">
                                    <img src={rightArrow} alt="arrow" />
                                  </div>
                                  </Link>
                              </li> */}
          </ul>
        </div>
        {/* </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      </DashboardLayout>

      <Modal commonModalShow={openSchemeTerm}
        commonModalClose={() => { setOpenSchemeTerm(false) }}
        commonModalData={
          <>
            <div className='d-flex align-items-center justify-content-center mb-4'>
              <h5 className='md-title mb-0' id='documentModalLabel'>Investment Scheme Term Sheet</h5>
            </div>
            <div
              className="pdf-viewer-container"
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}>
              <div>
                <div>
                  <Document
                    file={schemeTerm}
                    // options={{ workerSrc: "/pdf.worker.js" }}
                    onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                  >
                    <Page
                      onClick={handleMouseCLick} inputRef={PDFViewRef}
                      pageNumber={pageNumber}
                      className="pdf-page"
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      height={pageHeight}
                      width={window.innerWidth > 768 ? window.innerWidth/2 : window.innerWidth/2 + 150}
                    />
                  </Document>
                </div>
                {signBase64 && pageNumber === currentPage && signaturePosition.x != 0 && <div>
                  <img src={signBase64} onMouseDown={handleMouseDown} style={{ left: signaturePosition.x + 100, top: signaturePosition.y + 50 }} alt="signature" className="signature-overlay" />
                </div>}
              </div>
              <div className="d-flex page-navigation">
                <button className="transparent-orange-btn" style={{ opacity: pageNumber <= 1 ? 0.5 : 1 }} onClick={() => setPageNumber(prevPageNumber => Math.max(1, prevPageNumber - 1))} disabled={pageNumber <= 1}>
                  Previous
                </button>
                <div className="mx-4 sm-title">{pageNumber}/{numPages}</div>
                <button className="transparent-orange-btn" style={{ opacity: pageNumber >= numPages ? 0.5 : 1 }} onClick={() => setPageNumber(prevPageNumber => Math.min(numPages, prevPageNumber + 1))} disabled={pageNumber >= numPages}>
                  Next
                </button>
              </div>
              {
                <div>
                  {signBase64 != null ?
                    <div className="text-center row mt-3">
                      <div className="col">
                        <button
                          className={`red-btn w-100 px-5`}
                          onClick={removeSignature}
                        >
                          Resign
                        </button>
                      </div>
                      <div className="col">
                        <button
                          className={`green-btn w-100 px-5`}
                          onClick={saveSignedPDF}
                        >
                          Proceed
                        </button>
                      </div>
                    </div>
                    :
                    (
                      signaturePage?.indexOf(pageNumber) > -1 ? <div className="text-center mt-3">
                        <button type="button" className="theme-btn px-4 terms-accept-btn" onClick={() => { setOpenSignatureOptions(true) }}>Sign Document</button>
                      </div>
                        : <div className="text-center mt-3">Click Next to place signature</div>
                    )
                  }
                </div>
              }
            </div>
          </>
        }
      >
      </Modal>
      <Modal commonModalShow={openSignatureOptions}
        commonModalClose={() => { setOpenSignatureOptions(false) }}
        commonModalData={
          <>
            <div className='d-flex align-items-center justify-content-between mb-4'>
              <h5 className='md-title mb-0' id='documentModalLabel'>Select option for signature</h5>
              <button type='button' className='btn-close' onClick={() => { setOpenSignatureOptions(false) }}></button>
            </div>
            <div>
              <div className="form-group">
                {[{ label: "Draw Signature", value: "draw" }, { label: "Upload Signature", value: "initials" }].map((option) => (
                  <div key={option.value} className="react-radio radio-inline mr-15">
                    <label className="form-check-label">
                      <input
                        type="radio"
                        className="form-check-input"
                        checked={signatureOptionSelected === option.value}
                        value={option.value}
                        onChange={handleRadioChange} // pass event to handler
                      />
                      <span>{option.label}</span>
                    </label>
                  </div>
                ))}
              </div>
              {
                signatureOptionSelected === "draw" && (
                  <div>
                    <div className="my-3">
                      <SignatureCanvas ref={signatureRef} penColor='black'
                        canvasProps={{ className: 'sigCanvas' }} />
                    </div>
                    <div className="text-center row">
                      <div className="col">
                        <button
                          className={`red-btn w-100`}
                          onClick={() => {
                            signatureRef?.current?.clear()
                          }}
                        >
                          Clear
                        </button>
                      </div>

                      <div className="col">
                        <button
                          className={`green-btn w-100`}
                          onClick={handleSignature}
                        >
                          Place
                        </button>
                      </div>
                    </div>
                  </div>
                )
              }
              {
                signatureOptionSelected === "initials" && (
                  <div className="mt-3">
                    <div className="custom-upload">
                      <button className="custom-upload-btn">
                        Upload Signature Image
                      </button>
                      <div>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange}
                          multiple={false}
                        />
                        <>
                          {selectedFiles.map((selectedFile, index) => (
                            <div
                              className="uploaded-imgs-block w-100"
                              key={index}
                            >
                              <div className="uploaded-box position-relative">
                                <img
                                  src={
                                    selectedFile?.url ? selectedFile?.url : selectedFile?.blob
                                  }
                                  alt="Uploaded"
                                />
                                <div
                                  className="cross-icon cursor-pointer"
                                  onClick={() => {
                                    let newFiles = selectedFiles.filter((_, i) => i != index);
                                    setSelectedFiles(newFiles);
                                  }}
                                >
                                  <img src={crossImg} alt="cross" />
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      </div>
                    </div>
                    {selectedFiles.length > 0 && <div className="text-center row mt-2">

                      <div className="col">
                        <button
                          className={`green-btn w-100`}
                          onClick={handleSignatureUpload}
                        >
                          Place
                        </button>
                      </div>
                    </div>}
                  </div>
                )
              }
            </div>
          </>
        }
      >
      </Modal>
    </>
  );
};

export default MyDeals;
