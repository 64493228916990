import { types } from '../actions/types';

const initialState = {
  data: {},
};

const saveProfileImageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.PROFILE_IMAGE:
      return {
        ...state,
        data: payload,
      };
    default:
      return state;
  }
};

export default saveProfileImageReducer
