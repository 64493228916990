import axios from "axios";
import moment from "moment";
import * as Yup from "yup";
import BtnSpinner from "../../../utils/BtnSpinner";
import Modal from "../../../utils/Modal";
import TimeDiff from "../../../utils/TimeDiff";
import { Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetDealBankAccountDetails } from "../../../api/Master";
import { Calendar } from "../../../assets/img";
import Cookies from "js-cookie";
import { Logout } from "../../../views/auth/Logout";
import infoIcon from "../../../assets/img/information-fill.svg";
import dealReceivedIcon from "../../../assets/img/deals-icon.svg";

import { SingleFileInputComponent } from "../../../components/formik/FieldComponents";
import { GetUserDetailsUpdated } from "../../../api";
import { fixedModulesAmount, redirectToKYC } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { getConstants } from "../../../utils/constants";
import SignatureCanvas from 'react-signature-canvas'
import { decode as atob, encode as btoa } from "base-64"
import { PDFDocument } from "pdf-lib";
import { Document, Page, pdfjs } from "react-pdf";
import { clearSendSignedDocument, sendSignedDocument } from "../../../store/actions/sendSignedDocumentAction";
import crossImg from "../../../assets/img/cross-icon.svg";
import { clearGetCoordinates, getCoordinates } from "../../../store/actions/getCordinatesAction";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const getAuthToken = () => {
  return Cookies.get("jwtToken");
};

export const ShowCase = ({ dealData }) => {
  const { data: userData, isLoading: isDocsLoading } = GetUserDetailsUpdated();
  const navigate = useNavigate()
  const checkUSerType = useSelector((state) => state.userDetails);
  const [active, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [finalData, setFinalData] = useState(null)

  useEffect(() => {
    setFinalData(userData)
  }, [userData])

  const getAuthToken = () => {
    return Cookies.get("jwtToken");
  };

  const saveMeeting = () => {
    setIsLoading(true);

    client
      .post(
        "/shedule-meeting",
        {
          startup_deal_id: dealData._id,
          is_interested: "Yes",
        },
        {
          headers: { Authorization: `Bearer ${getAuthToken()}` },
        }
      )
      .then((res) => {
        setIsLoading(false);

        res = res.data;
        if (res && res.statusCode === 200) {
          // Success Message
          toast.success(res.message);
        } else {
          // Error Message
          toast.error(res.message);

          Logout();
        }
      })
      .catch((error) => {
        toast.error("Error");
        console.error("API Catch", error);

        setIsLoading(false);
      });
  };

  //   console.log(dealData);

  return (
    <div className="white-right-box join-box pt-0 pb-0">
      <div className="mb-3">
        <div className="sm-title text-start black-text fw-600">
          Join Pitch Call
        </div>

        {dealData?.meetings[0]?.date && (
          <TimeDiff
            displayText={dealData?.meetings[0]?.date_text}
            deadline={dealData?.meetings[0]?.date}
            setActive={setActive}
          />
        )}
      </div>

      <div className="d-flex align-items-center call-dtl-box justify-content-between mb-4">
        <div className="d-flex align-items-center">
          <div>
            <img className="d-block" src={Calendar} alt="calendar" />
          </div>

          <div className="sm-title font-0-8em black-text pl-6">
            {dealData?.meetings[0]?.date &&
              moment(dealData?.meetings[0]?.date).format("DD-MMM-YYYY hh:mm A")}
          </div>
        </div>

        {/* <div>
          {isLoading ? (
            <BtnSpinner loader={true} />
          ) : (
            <button
              className="theme-btn pitch-recording-btn"
              type="button"
              onClick={saveMeeting}
            >
              Save
            </button>
          )}
        </div> */}
      </div>

      {dealData?.meetings[0]?.url && (
        <div className="text-center">
          <button
            type="button"
            disabled={!active}
            className="d-green-btn ps-3 pe-3 w-100"
            onClick={() => {
              if (finalData.data.master_status_id[0]?.values[0]?.name === "Approved") {
                window.open(dealData?.meetings[0]?.url, "_blank", "noreferrer")
              } else {
                redirectToKYC(finalData, navigate, checkUSerType)
              }
            }
            }
          >
            Join
          </button>
        </div>
      )}
    </div>
  );
};

export const InvestmentInterest = ({
  dealData,
  refetchData,
  unallocatedAmount,
}) => {
  const { data: userData, isLoading: isDocsLoading } = GetUserDetailsUpdated();
  const [isLoading, setIsLoading] = useState(false);
  const [commonModalShow, setCommonModalShow] = useState(false);
  const [isInterested, setIsInterested] = useState(
    dealData?.meetings[0]?.is_interested?.is_interested ?? ""
  );
  const [gstType, setGstType] = useState(null)
  const [fees, setFees] = useState(null)
  const [tableRows, setTableRows] = useState([])
  const navigate = useNavigate()
  const checkUSerType = useSelector((state) => state.userDetails);
  const [finalData, setFinalData] = useState(null)
  const [openSchemeTerm, setOpenSchemeTerm] = useState(false)
  const [schemeTerm, setSchemeTerm] = useState(null)
  const [openSignatureOptions, setOpenSignatureOptions] = useState(false)
  const [signatureOptionSelected, setSignatureOptionSeclected] = useState(null)
  const [signBase64, setSignBase64] = useState(null)
  const [pdfEditMode, setPdfEditMode] = useState(false);
  const [pdfArrayBuffer, setPdfArrayBuffer] = useState(null);

  const [transactionId, setTransactionId] = useState(null)
  const [interestedAmount, setInterestAmount] = useState(null)
  const [dragStart, setDragStart] = useState(null);
  const [signaturePosition, setSignaturePosition] = useState({ x: 0, y: 0 });
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [successMessage, setSuccessMessage] = useState(null)
  const [pageWidth, setPageWidth] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([])
  const [signaturePage, setSignaturePage] = useState(null)
  const [signatureCordinates, setSignatureCordinates] = useState(null)
  const [initialDocument, setInitialDocument] = useState(null)
  const [selectedInterest, setSelectedInterest] = useState(null)
  const [isSignatured, setIsSignatured] = useState("Signed")
  const [startupDealId, setStartupDealId] = useState(null)

  const signatureRef = useRef()
  const PDFViewRef = useRef()
  const formik = useRef()
  const dispatch = useDispatch()

  const sendSignedPdfResponse = useSelector(state => state.sendSignedPdfReducer.data)
  const getCordinatesResponse = useSelector(state => state.getCordinatesReducer.data)

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const intrestOptions = [
    { label: '+ ₹1,00,000', value: "100000" },
    { label: '+ ₹2,00,000', value: "200000" },
    { label: '+ ₹3,00,000', value: "300000" },
    { label: '+ ₹5,00,000', value: "500000" },
    { label: '+ ₹10,00,000', value: "1000000" },
  ]

  useEffect(() => {
    setFinalData(userData)
  }, [userData])

  const commonModalClose = () => setCommonModalShow(false);
  const commonHandleShow = () => {
    setCommonModalShow(true);
  };

  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const confirmModalClose = () => setConfirmModalShow(false);
  const confirmHandleShow = () => {
    setConfirmModalShow(true);
  };

  const [checkUnallocatedMessage, setCheckUnallocatedMessage] = useState(false);

  const submitInterest = (resetForm, values = {}, is_interested = "No") => {
    if (
      is_interested === "Yes" &&
      dealData.scheme_letter &&
      dealData.scheme_letter.length > 0 &&
      !values?.agree
    ) {
      toast.error("Agree to the scheme letter term sheet");
      return;
    }
    setIsLoading(true);
    let fields = {
      amount: values.amount ? values.amount?.toString() : "0",
      startup_deal_id: dealData._id,
      is_interested,
    };

    client
      .post("/deal/save-interest", fields, {
        headers: { Authorization: `Bearer ${getAuthToken()}` },
      })
      .then((res) => {
        res = res.data;

        if (res.statusCode === 200) {
          toast.success(res.message);
          setSuccessMessage(res.message)
          // if (is_interested == "No") {
            setIsInterested(is_interested);
          // }
          setStartupDealId(dealData._id)
          setCommonModalShow(false);
          // if (res.data?.length > 0) {
          //   setTransactionId(res.data[0].transaction_id)
          //   setInterestAmount(res.data[0].interested_amount)
          //   handleSchemeTerm(res.data[0].url)
          //   let cordinates = res.coordinates
          //   let pages = cordinates?.map((item) => { return item.page })
          //   setSignaturePage(pages)
          //   setSignatureCordinates(cordinates)
          // }
        } else {
          toast.error(res.message);
        }
        setIsLoading(false);
        refetchData();
      })
      .catch((error) => {
        // toast.error(error.response);
        toast.error(error.response.data.message);
        //console.error("API Catch", error);

        setIsLoading(false);
      });
  };

  const handleSchemeTerm = async (url) => {
    console.log('url', url)
    await fetch(url, {
      headers: {
        // 'Content-Type': 'application/json',
        // 'Accept': 'application/pdf'
      }
    })
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise((res) => {
          reader.onloadend = async () => {
            res(reader.result);
            console.log("reader", reader)
            setInitialDocument(reader)
            setPdfArrayBuffer(base64ToArrayBuffer(reader.result.replace("data:application/pdf;base64,", "")));
            const loadedPdfDoc = await PDFDocument.load(base64ToArrayBuffer(reader.result.replace("data:application/pdf;base64,", "")));
            const firstPage = loadedPdfDoc.getPage(0);
            const { width, height } = firstPage.getSize();
            setPageWidth(width);
            setPageHeight(height);

            const modifiedPdfBytes = await loadedPdfDoc.save(); // Save the modified PDF
            const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            setSchemeTerm(url)
            setOpenSchemeTerm(true)
          }
        })
      })
      .catch(error => {
        console.log("error", error)
      })
  }

  const confirmPayment = (resetForm, values = {}) => {
    if (!!!values?.transcationID && !!!values?.document) {
      toast.error("Please enter transaction ID or upload document");
      return;
    }
    setIsLoading(true);

    const formData = new FormData();

    if (values?.document != "") {
      // formData.append("name", "Payment Screenshot");
      formData.append("document", values?.document[0]);
    }

    if (values?.transcationID != "") {
      formData.append("transcationID", values?.transcationID);
    }

    formData.append("is_unallocated", values?.is_unallocated);
    formData.append("startup_deal_id", dealData._id);

    client
      .post("/payment", formData, {
        headers: { Authorization: `Bearer ${getAuthToken()}` },
      })
      .then((res) => {
        res = res.data;

        if (res.statusCode === 200) {
          toast.success(res.message);
          setConfirmModalShow(false);
        } else {
          toast.error(res.message);
        }
        setIsLoading(false);
        refetchData();
      })
      .catch((error) => {
        // toast.error(error.response);
        toast.error(error.response.data.message);
        //console.error("API Catch", error);

        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (dealData != null) {
      // setIsSignatured(dealData.meetings[0]?.is_interested?.is_signatured)
      setGstType(dealData?.fees_management?.gst_type)
      if (dealData?.fees_management?.gst_type) {
        let feesTitles = Object.keys(dealData.fees_management)
        convertTitle(feesTitles)
      } else {
        setFees(null)
      }
    }
  }, [dealData])

  const convertTitle = (titles) => {
    let convertedTitles = []
    for (const [index, element] of titles.entries()) {
      let title = element
      if (title != "gst_type") {
        title = title.replace(/_/g, " ").replace(/\s(.)/g, function (a) {
          return a.toUpperCase();
        })
          .replace(/^(.)/, function (b) {
            return b.toLowerCase();
          })
        title = title.substring(0, 1).toUpperCase() + title.substring(1)
        let values = Object.values(dealData.fees_management)[index]
        values = { ...values, ...{ title: title } }
        convertedTitles.push(values)
      }
    }
    console.log("converted title", convertedTitles)
    setFees(convertedTitles)
    let tableRow = []
    for (let row of convertedTitles) {
      let rowData = []
      rowData.push(row.title)
      rowData.push(row.final_fee)
      tableRow.push(rowData)
    }
    console.log("tableRow", tableRow)
    setTableRows(tableRow)
  }

  const handleInterestedAmount = (interestedAmount) => {
    if (interestedAmount && gstType) {
      console.log("interestedAmount", interestedAmount)
      let total = fees.reduce((partialSum, a) => partialSum + ((a.final_fee * interestedAmount) / 100), 0)
      total = total.toFixed(2)

      let tableRow = []
      let investable = null
      let payable = null
      if (gstType == "include") {
        investable = fixedModulesAmount(Number(interestedAmount) - Number(total))
        payable = fixedModulesAmount(interestedAmount)
      } else {
        investable = fixedModulesAmount(interestedAmount)
        payable = fixedModulesAmount(Number(interestedAmount) + Number(total))
      }
      tableRow.push(["Investable Amount", `${dealData?.ref_min_investment_amount?.substring(0, 1)}` + investable])
      for (let row of fees) {
        let rowData = []
        let fee = fixedModulesAmount((row.final_fee * interestedAmount) / 100)
        fee = `${dealData?.ref_min_investment_amount?.substring(0, 1)}` + fee + " (" + row.final_fee + "%)"
        console.log("fee", fee)
        rowData.push(row.title)
        rowData.push(fee)
        tableRow.push(rowData)
      }
      tableRow.push(["Total Payable Amount", `${dealData?.ref_min_investment_amount?.substring(0, 1)}` + payable])
      console.log("tableRow", tableRow)
      setTableRows(tableRow)
    }
  }

  const renderFeesManagement = () => {
    let fees = tableRows?.filter(item => { return item[0] != "Total Payable Amount" })
    let total = tableRows?.filter(item => { return item[0] == "Total Payable Amount" })
    console.log("tableRows", tableRows)
    console.log("fees", fees)
    console.log("total", total)
    return (
      <div>
        <div className="py-10">
          <div className="mb-2">Investment Amount and Fee details</div>
          {/* <Table borderStyle={{ borderWidth: 2, borderColor: Colors.BoxBorder }}>
            <Row data={['Particular', `Amount (${company?.ref_min_investment_amount?.substring(0, 1)})`]} style={styles.head} textStyle={styles.head.text} />
            <Rows data={fees} textStyle={styles.cell} />
            <Rows data={total} textStyle={[styles.cell, { color: Colors.Black }]} />
          </Table> */}
        </div>
      </div>
    )
  }

  const handleRadioChange = (event) => {
    setSignatureOptionSeclected(event.target.value);
  };

  const handleSignature = () => {
    if (signatureRef?.current?.isEmpty()) {
      toast.error("Please draw sigature")
    } else {
      const canvas = signatureRef.current.getTrimmedCanvas();
      let sign = canvas?.toDataURL()
      setSignBase64(sign);
      setOpenSignatureOptions(false);
      setPdfEditMode(true);

      // Set the initial position of the signature to the top corner of the popup
      const initialX = (signaturePosition.x);
      const initialY = (signaturePosition.y);
      // setSignaturePosition({ x: initialX, y: initialY });

      // Set the current page as the page where the signature is drawn
      setCurrentPage(pageNumber);
    }
  }

  const handleSignatureUpload = () => {
    if (selectedFiles.length == 0) {
      toast.error("Please upload sigature")
    } else {
      const reader = new FileReader()

      reader.readAsDataURL(selectedFiles[0])

      reader.onload = () => {

        setSignBase64(reader.result);
        setOpenSignatureOptions(false);
        setPdfEditMode(true);

        // Set the initial position of the signature to the top corner of the popup
        const initialX = (signaturePosition.x);
        const initialY = (signaturePosition.y);
        // setSignaturePosition({ x: initialX, y: initialY });

        // Set the current page as the page where the signature is drawn
        setCurrentPage(pageNumber);
      }
    }
  }

  useEffect(() => {
    if (signBase64) {
      handleSingleTap()
    }
  }, [signBase64])

  const handleSingleTap = async () => {

    const loadedPdfDoc = await PDFDocument.load(pdfArrayBuffer);
    if (loadedPdfDoc && signBase64) {
      let extension = signBase64?.split(";")[0]?.split("/")[1]
      const signatureImageBytes = await fetch(signBase64).then(res => res.arrayBuffer());
      const signatureImageEmbed = extension == 'png' ? await loadedPdfDoc.embedPng(signatureImageBytes) : await loadedPdfDoc.embedJpg(signatureImageBytes);
      const { width, height } = signatureImageEmbed.scale(0.18)
      console.log("image dimension", { width, height })

      signaturePage.forEach(page => {
        const pageToSign = loadedPdfDoc.getPage(page - 1);
        const pageWiseCordinates = signatureCordinates.filter(item => { return item.page == page })
        pageWiseCordinates.forEach(cordinates => {
          let x = cordinates.x
          let y = cordinates.y

          pageToSign.drawImage(signatureImageEmbed, {
            x: x - 30,
            y: y + 20,
            width: width,
            height: height < 35 ? height : 35
          });
        });
      });

      const modifiedPdfBytes = await loadedPdfDoc.save(); // Save the modified PDF
      const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      setSchemeTerm(url)
      // window.open(url)
    }
  }

  const base64ToArrayBuffer = (base64) => {
    const binary_string = atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  const uint8ToBase64 = (u8Arr) => {
    const CHUNK_SIZE = 0x8000; //arbitrary number
    let index = 0;
    const length = u8Arr.length;
    let result = "";
    let slice;
    while (index < length) {
      slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length));
      result += String.fromCharCode.apply(null, slice);
      index += CHUNK_SIZE;
    }
    return btoa(result);
  }

  const handleMouseMove = (e) => {
    if (dragStart) {
      const offsetX = e.clientX - dragStart.x;
      const offsetY = e.clientY - dragStart.y;
      setSignaturePosition({ x: signaturePosition.x + offsetX, y: signaturePosition.y + offsetY });
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setDragStart(null);
    setPdfEditMode(false)
  };

  const handleMouseDown = (e) => {
    setDragStart({ x: e.clientX, y: e.clientY });
  };

  const saveSignedPDF = async () => {
    const loadedPdfDoc = await PDFDocument.load(pdfArrayBuffer);
    if (loadedPdfDoc && signBase64) {
      let extension = signBase64?.split(";")[0]?.split("/")[1]
      const signatureImageBytes = await fetch(signBase64).then(res => res.arrayBuffer());
      const signatureImageEmbed = extension == 'png' ? await loadedPdfDoc.embedPng(signatureImageBytes) : await loadedPdfDoc.embedJpg(signatureImageBytes);
      const { width, height } = signatureImageEmbed.scale(0.18)

      signaturePage.forEach(page => {
        const pageToSign = loadedPdfDoc.getPage(page - 1);
        const pageWiseCordinates = signatureCordinates.filter(item => { return item.page == page })
        pageWiseCordinates.forEach(cordinates => {
          let x = cordinates.x
          let y = cordinates.y

          pageToSign.drawImage(signatureImageEmbed, {
            x: x - 30,
            y: y + 20,
            width: width,
            height: height < 35 ? height : 35
          });
        });
      });

      const modifiedPdfBytes = await loadedPdfDoc.save(); // Save the modified PDF
      const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      // window.open(url)

      const pdfBase64 = uint8ToBase64(modifiedPdfBytes);

      let byteCharacters = atob(pdfBase64);
      let byteArrays = [];
      let sliceSize = 512

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);

        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        let byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      let signedFile = new File(byteArrays, "Investment Scheme Term Sheet.pdf", { type: "application/pdf" });
      let deviceInfo = {
        device_type: "web",
        ip_address: window.location.host
      }
      let request = new FormData()
      if (signedFile != null) {
        request.append("device_info[device_type]", deviceInfo.device_type)
        request.append("device_info[ip_address]", deviceInfo.ip_address)
        request.append("interested_amount", interestedAmount)
        request.append("document", signedFile)
      }
      dispatch(sendSignedDocument({
        id: transactionId,
        request: request
      }))
    }
  }

  useEffect(() => {
    if (sendSignedPdfResponse != null) {
      if (sendSignedPdfResponse.statusCode == 200) {
        console.log("sendSignedPdfResponse", sendSignedPdfResponse)
        if (startupDealId == dealData._id) {
          toast.success(successMessage != null ? successMessage : sendSignedPdfResponse.message)
        }
        setSchemeTerm(null)
        setPageNumber(1)
        setCurrentPage(1)
        setSignaturePosition({ x: 0, y: 0 })
        setPdfArrayBuffer(null);
        setSignBase64(null);
        dispatch(clearSendSignedDocument())
        setSuccessMessage(null)
        setOpenSchemeTerm(false)
        setIsInterested("Yes");
        setStartupDealId(null)
        refetchData()
      }
    }
  }, [sendSignedPdfResponse])

  const handleMouseCLick = (e) => {
    const rect = PDFViewRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left + PDFViewRef.current.scrollLeft;
    const y = e.clientY - rect.top + PDFViewRef.current.scrollTop;
    // setSignaturePosition({ x: x, y: y });
  }

  const handleFileChange = (event) => {
    let allowedExtensions = ["jpg", "jpeg", "png"]
    let files = event.target.files;
    let extension = files[0]?.name.split(/[#?]/)[0].split(".").pop().trim()
    if (allowedExtensions.indexOf(extension) > -1) {
      for (let i = 0; i < files.length; i++) {
        files[i]["blob"] = URL.createObjectURL(files[i]);
      }
      let signImage = []
      setSelectedFiles([...signImage, ...files])
    } else {
      toast.error("Only image is allowed")
    }
  };

  const removeSignature = async () => {
    signatureRef?.current?.clear()
    setSignBase64(null)
    setPdfArrayBuffer(base64ToArrayBuffer(initialDocument.result.replace("data:application/pdf;base64,", "")));
    const loadedPdfDoc = await PDFDocument.load(base64ToArrayBuffer(initialDocument.result.replace("data:application/pdf;base64,", "")));
    const modifiedPdfBytes = await loadedPdfDoc.save(); // Save the modified PDF
    const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    setSchemeTerm(url)
  }

  const signDocument = () => {
    setTransactionId(dealData?.meetings[0]?.is_interested?.transaction_id)
    dispatch(getCoordinates({ transaction_id: dealData?.meetings[0]?.is_interested?.transaction_id }))
  }

  useEffect(() => {
    if (getCordinatesResponse != null) {
      if (getCordinatesResponse.statusCode == 200) {
        if (getCordinatesResponse?.data?.startup_deal_id == dealData._id) {
          console.log("getCordinatesResponse Deals", getCordinatesResponse)
          setStartupDealId(getCordinatesResponse?.data?.startup_deal_id)
          setInterestAmount(getCordinatesResponse?.data?.interested_amount)
          let cordinates = getCordinatesResponse.coordinates
          let pages = cordinates?.map((item) => { return item.page })
          setSignaturePage(pages)
          setSignatureCordinates(cordinates)
          // handleSchemeTerm(getCordinatesResponse?.pdfUrl)
        }
        dispatch(clearGetCoordinates())
      }
    }
  }, [getCordinatesResponse])

  return (
    <>
      {dealData?.meetings[0]?.transcation?.status ? (
        <div className="white-right-box join-box pt-0 pb-0">
          {dealData?.meetings[0]?.transcation?.status == "pending" ? (
            <button className="d-green-btn ps-3 pe-3 w-100" disabled={true}>
              Processing
            </button>
          ) : dealData.deal_status != "completed" ? (
            <Link
              onClick={() => {
                if (finalData.data.master_status_id[0]?.values[0]?.name === "Approved") {

                } else {
                  redirectToKYC(finalData, navigate, checkUSerType)
                }
              }}
              to={"/portfolio"}>
              <button
                className={`d-green-btn ps-3 pe-3 w-100 "is-interested"
                    }`}
              >
                Funds Received
              </button>
            </Link>
          ) : (
            <Link
              onClick={() => {
                if (finalData.data.master_status_id[0]?.values[0]?.name === "Approved") {

                } else {
                  redirectToKYC(finalData, navigate, checkUSerType)
                }
              }}
              to={"/portfolio"}>
              <button
                className={`d-green-btn ps-3 pe-3 w-100 "is-interested"
                    }`}
              >
                View Portfolio
              </button>
            </Link>
          )}

          <span className="info-amount mt-2 font-14 text-start d-block">
            <img className="me-2" src={infoIcon} alt="info" /> Interested shown by{" "}
            {dealData.last_updated_by} :{" "}
            {dealData?.meetings[0]?.is_interested?.amount ?? ""}
          </span>
          <span className="received-amount mt-2 font-14 text-start d-block">
            <img className="me-2" src={dealReceivedIcon} alt="info" /> {getConstants()?.DEAL_INTEREST_TEXT}: &nbsp;
            {dealData?.total_interested_amount ?? ""}
          </span>
        </div>
      ) : (
        <div className="white-right-box join-box">
          <div className="mb-2">
            <div className="sm-title text-start black-text fw-600 d-none d-md-block mb-2">
              Show Investment Interest
            </div>

            <TimeDiff
              displayText="Closing in"
              deadline={dealData.closing_date}
            />
          </div>

          {isInterested == "No" || isInterested == "" ? (
            <>
              <div className="text-center row">
                <div className="col">
                  <button
                    className={`red-btn w-100 ${isInterested === "No" && "is-not-interested"
                      }`}
                    onClick={() => {
                      if (finalData.data.master_status_id[0]?.values[0]?.name === "Approved") {
                        submitInterest()
                      } else {
                        redirectToKYC(finalData, navigate, checkUSerType)
                      }
                    }}
                    disabled={isInterested === "No"}
                  >
                    NO
                  </button>
                </div>

                <div className="col">
                  <button
                    className={`green-btn w-100 ${isInterested === "Yes" && "is-interested"
                      }`}
                    onClick={() => {
                      if (finalData.data.master_status_id[0]?.values[0]?.name === "Approved") {
                        commonHandleShow()
                      } else {
                        redirectToKYC(finalData, navigate, checkUSerType)
                      }
                    }}
                    disabled={isInterested === "Yes"}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="text-center row">
                <div className="col">
                  <button
                    className={`red-btn change-amount-btn w-100 ${isInterested === "No" && "is-not-interested"
                      }`}
                    onClick={() => {
                      if (finalData.data.master_status_id[0]?.values[0]?.name === "Approved") {
                        commonHandleShow()
                      } else {
                        redirectToKYC(finalData, navigate, checkUSerType)
                      }
                    }}
                    disabled={isInterested === "No"}
                  >
                    Change Amount
                  </button>
                </div>

                <div className="col">
                  <button
                    className={isSignatured == "Pending" ? "green-btn ps-3 pe-3 confirm-amount-btn w-100 sign-document-btn" : "green-btn ps-3 pe-3 confirm-amount-btn w-100"}
                    onClick={() => {
                      if (finalData.data.master_status_id[0]?.values[0]?.name === "Approved") {
                        if (isSignatured == "Pending") {
                          signDocument()
                        } else {
                          confirmHandleShow()
                        }
                      } else {
                        redirectToKYC(finalData, navigate, checkUSerType)
                      }
                    }}
                  >
                    {isSignatured == "Pending" ? "Sign Document" : "Confirm Payment"}
                  </button>
                </div>
              </div>
              <div className="info-amount mt-2 font-14 text-start d-block">
                <img className="me-2" src={infoIcon} alt="info" /> Interested shown by{" "}
                {dealData.last_updated_by} :{" "}
                {dealData?.meetings[0]?.is_interested?.amount ?? ""}
              </div>
            </>
          )}

          <span className="received-amount mt-2 font-14 text-start d-block">
            <img className="me-2" src={dealReceivedIcon} alt="info" /> {getConstants()?.DEAL_INTEREST_TEXT}: &nbsp;
            {dealData?.total_interested_amount ?? ""}
          </span>
        </div>
      )}

      {/* change amount and enter amount poup */}

      <Modal
        commonModalTitle="Investment"
        commonModalShow={commonModalShow}
        commonModalClose={commonModalClose}
        commonModalData={
          <>
            <div className='d-flex align-items-center justify-content-between mb-4'>
              <h5 className='md-title mb-0' id='documentModalLabel'>Investment</h5>
              <button type='button' className='btn-close' onClick={commonModalClose}></button>
            </div>
            <Formik
              innerRef={formik}
              initialValues={{
                amount: "",
              }}
              validationSchema={Yup.object().shape({
                amount: Yup.number().integer().required("Amount is required"),
              })}
              onSubmit={(values, { resetForm }) => {
                submitInterest(resetForm, values, "Yes");
              }}
            >
              {({ values, errors, touched, setFieldValue }) => (
                <Form className="custom-form">
                  <div className="form-group">
                    <label>Enter Investment Amount</label>

                    <Field
                      type="number"
                      className="form-control"
                      name="amount"
                      min="0"
                      onChange={(e) => {
                        setFieldValue("amount", e.target.value);
                        setSelectedInterest(null)
                        handleInterestedAmount(e.target.value)
                      }
                      }
                    />
                    {errors.amount && touched.amount && (
                      <small className="text-danger">{errors.amount}</small>
                    )}

                    {(dealData?.ref_min_investment_amount !== "" ||
                      dealData?.ref_max_investment_amount !== "") && (
                        <small className="d-block text-muted">
                          {dealData?.ref_min_investment_amount && (
                            <span>
                              {" "}
                              Min: {dealData?.ref_min_investment_amount ?? 0}{" "}
                            </span>
                          )}

                          {dealData?.ref_max_investment_amount && (
                            <span>
                              {" "}
                              and Max: {dealData?.ref_max_investment_amount}
                            </span>
                          )}
                        </small>
                      )}
                  </div>

                  <div className="mb-4 grid">
                    {
                      intrestOptions.map(item => {
                        console.log("formik", formik)
                        return (
                          <button type="button" className={`item ${item.label === selectedInterest && "selected-item"}`} onClick={() => { setSelectedInterest(item.label); formik?.current?.setFieldValue("amount", item.value); }}>{item.label}</button>
                        )
                      })
                    }
                  </div>

                  {
                    // gstType && values.amount && renderFeesManagement()
                  }

                  {dealData.scheme_letter &&
                    dealData.scheme_letter.length > 0 && (
                      <div className="form-check mt-4 mb-3">
                        <input
                          type="checkbox"
                          id="confirmCheckbox"
                          className="form-check-input"
                          onChange={(e) =>
                            setFieldValue("agree", e.target.checked ?? false)
                          }
                        />

                        <label
                          className="form-check-label"
                          htmlFor="confirmCheckbox"
                        >
                          I have read and agree to the{" "}
                          <Link
                            to={dealData.scheme_letter[0]}
                            target="_blank"
                            className="text-decoration-underline"
                          >
                            scheme letter term sheet
                          </Link>
                        </label>
                      </div>
                    )}

                  <div>
                    {isLoading ? (
                      <BtnSpinner />
                    ) : (
                      <button className="theme-btn btn w-100" type="submit">
                        Submit
                      </button>
                    )}
                  </div>

                  {isInterested == "Yes" && (
                    <div
                      className="col-12 text-center not-interest-text mt-4 cursor-pointer"
                      onClick={submitInterest}
                    >
                      <u>Not interested to invest </u>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </>
        }
      />

      {/* confirm amount popup */}

      <Modal
        commonModalTitle="Confirm Payment"
        commonModalShow={confirmModalShow}
        commonModalClose={confirmModalClose}
        commonModalData={
          <>
            <div className='d-flex align-items-center justify-content-between mb-4'>
              <h5 className='md-title mb-0' id='documentModalLabel'>Confirm Payment</h5>
              <button type='button' className='btn-close' onClick={confirmModalClose}></button>
            </div>
            <div className="cp-mxh-calc">
              <Formik
                initialValues={{
                  is_unallocated: "No",
                  transcationID: "",
                  document: "",
                }}
                onSubmit={(values, { resetForm }) => {
                  confirmPayment(resetForm, values);
                }}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form className="custom-form">
                    <div className="form-group">
                      {unallocatedAmount != "₹0" && (
                        <>
                          <label>
                            {" "}
                            We have some of your unallocated funds with us which you
                            can use to confirm for this deal
                          </label>
                          <div className="d-flex align-items-center balance-fund-box mt-2">
                            <div className="wallet-icon"></div>
                            <div>Balance funds with us</div>
                            <div className="orange-text ml-auto">
                              {unallocatedAmount}
                            </div>
                          </div>
                          <div className="mt-3">
                            <input
                              type="checkbox"
                              id="is_unallocated"
                              className="form-check-input me-2"
                              onChange={(e) => {
                                setFieldValue(
                                  "is_unallocated",
                                  e.target.checked ? "Yes" : "No"
                                );
                                setCheckUnallocatedMessage(e.target.checked);
                              }}
                            />{" "}
                            Use Balance funds
                          </div>

                          {checkUnallocatedMessage && (
                            <span className="unallocated-text">
                              {
                                dealData?.meetings[0]?.is_interested
                                  ?.unallocated_message
                              }{" "}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                    {unallocatedAmount == "₹0" && <label>
                      {" "}
                      Please provide the transaction ID or attach transaction screen for the confirmation.
                    </label>}
                    <div className="form-group">
                      <label>Transaction ID</label>
                      <Field
                        type="test"
                        className="form-control"
                        name="transcationID"
                      />
                    </div>
                    <div className="form-group">
                      <div className="custom-upload">
                        Attach Transaction Screenshot
                        <button className="custom-upload-btn">Choose file</button>
                        <Field
                          name="document"
                          component={SingleFileInputComponent}
                          label="Choose file"
                          desc=""
                        />
                      </div>
                    </div>

                    <div>
                      {isLoading ? (
                        <BtnSpinner />
                      ) : (
                        <button className="theme-btn btn w-100" type="submit">
                          Submit
                        </button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
      />

      <Modal commonModalShow={openSchemeTerm}
        commonModalClose={() => { setOpenSchemeTerm(false) }}
        commonModalData={
          <>
            <div className='d-flex align-items-center justify-content-between mb-4'>
              <h5 className='md-title mb-0' id='documentModalLabel'>Investment Scheme Term Sheet</h5>
              <button type='button' className='btn-close'
                onClick={() => {
                  setOpenSchemeTerm(false)
                  setSchemeTerm(null)
                  setPageNumber(1)
                  setCurrentPage(1)
                  setSignaturePosition({ x: 0, y: 0 })
                  setPdfArrayBuffer(null);
                  setSignBase64(null);
                  dispatch(clearSendSignedDocument())
                  setSuccessMessage(null)
                }}></button>
            </div>
            <div
              className="pdf-viewer-container"
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}>
              <div>
                <div>
                  <Document
                    file={schemeTerm}
                    // options={{ workerSrc: "/pdf.worker.js" }}
                    onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                  >
                    <Page
                      onClick={handleMouseCLick} inputRef={PDFViewRef}
                      pageNumber={pageNumber}
                      className="pdf-page"
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      height={pageHeight}
                      width={window.innerWidth > 768 ? window.innerWidth / 2 : window.innerWidth / 2 + 150}
                    />
                  </Document>
                </div>
                {signBase64 && pageNumber === currentPage && signaturePosition.x != 0 && <div>
                  <img src={signBase64} onMouseDown={handleMouseDown} style={{ left: signaturePosition.x + 100, top: signaturePosition.y + 50 }} alt="signature" className="signature-overlay" />
                </div>}
              </div>
              <div className="d-flex page-navigation">
                <button className="transparent-orange-btn" style={{ opacity: pageNumber <= 1 ? 0.5 : 1 }} onClick={() => setPageNumber(prevPageNumber => Math.max(1, prevPageNumber - 1))} disabled={pageNumber <= 1}>
                  Previous
                </button>
                <div className="mx-4 sm-title">{pageNumber}/{numPages}</div>
                <button className="transparent-orange-btn" style={{ opacity: pageNumber >= numPages ? 0.5 : 1 }} onClick={() => setPageNumber(prevPageNumber => Math.min(numPages, prevPageNumber + 1))} disabled={pageNumber >= numPages}>
                  Next
                </button>
              </div>
              {
                <div>
                  {signBase64 != null ?
                    <div className="text-center row mt-3">
                      <div className="col">
                        <button
                          className={`red-btn w-100 px-5`}
                          onClick={removeSignature}
                        >
                          Resign
                        </button>
                      </div>
                      <div className="col">
                        <button
                          className={`green-btn w-100 px-5`}
                          onClick={saveSignedPDF}
                        >
                          Proceed
                        </button>
                      </div>
                    </div>
                    :
                    (
                      signaturePage?.indexOf(pageNumber) > -1 ? <div className="text-center mt-3">
                        <button type="button" className="theme-btn px-4 terms-accept-btn" onClick={() => { setOpenSignatureOptions(true) }}>Sign Document</button>
                      </div>
                        : <div className="text-center mt-3">Click Next to place signature</div>
                    )
                  }
                </div>
              }
            </div>
          </>
        }
      >
      </Modal>
      <Modal commonModalShow={openSignatureOptions}
        commonModalClose={() => { setOpenSignatureOptions(false) }}
        commonModalData={
          <>
            <div className='d-flex align-items-center justify-content-between mb-4'>
              <h5 className='md-title mb-0' id='documentModalLabel'>Select option for signature</h5>
              <button type='button' className='btn-close' onClick={() => { setOpenSignatureOptions(false) }}></button>
            </div>
            <div>
              <div className="form-group">
                {[{ label: "Draw Signature", value: "draw" }, { label: "Upload Signature", value: "initials" }].map((option) => (
                  <div key={option.value} className="react-radio radio-inline mr-15">
                    <label className="form-check-label">
                      <input
                        type="radio"
                        className="form-check-input"
                        checked={signatureOptionSelected === option.value}
                        value={option.value}
                        onChange={handleRadioChange} // pass event to handler
                      />
                      <span>{option.label}</span>
                    </label>
                  </div>
                ))}
              </div>
              {
                signatureOptionSelected === "draw" && (
                  <div>
                    <div className="my-3">
                      <SignatureCanvas ref={signatureRef} penColor='black'
                        canvasProps={{ className: 'sigCanvas' }} />
                    </div>
                    <div className="text-center row">
                      <div className="col">
                        <button
                          className={`red-btn w-100`}
                          onClick={() => {
                            signatureRef?.current?.clear()
                          }}
                        >
                          Clear
                        </button>
                      </div>

                      <div className="col">
                        <button
                          className={`green-btn w-100`}
                          onClick={handleSignature}
                        >
                          Place
                        </button>
                      </div>
                    </div>
                  </div>
                )
              }
              {
                signatureOptionSelected === "initials" && (
                  <div className="mt-3">
                    <div className="custom-upload">
                      <button className="custom-upload-btn">
                        Upload Signature Image
                      </button>
                      <div>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange}
                          multiple={false}
                        />
                        <>
                          {selectedFiles.map((selectedFile, index) => (
                            <div
                              className="uploaded-imgs-block w-100"
                              key={index}
                            >
                              <div className="uploaded-box position-relative">
                                <img
                                  src={
                                    selectedFile?.url ? selectedFile?.url : selectedFile?.blob
                                  }
                                  alt="Uploaded"
                                />
                                <div
                                  className="cross-icon cursor-pointer"
                                  onClick={() => {
                                    let newFiles = selectedFiles.filter((_, i) => i != index);
                                    setSelectedFiles(newFiles);
                                  }}
                                >
                                  <img src={crossImg} alt="cross" />
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      </div>
                    </div>
                    {selectedFiles.length > 0 && <div className="text-center row mt-2">

                      <div className="col">
                        <button
                          className={`green-btn w-100`}
                          onClick={handleSignatureUpload}
                        >
                          Place
                        </button>
                      </div>
                    </div>}
                  </div>
                )
              }
            </div>
          </>
        }
      >
      </Modal>
    </>
  );
};

export const CallForMoney = ({ dealData, refetchData, unallocatedAmount }) => {
  const { data: userData, isLoading: isDocsLoading } = GetUserDetailsUpdated();
  const [isLoading, setIsLoading] = useState(false);
  const [commonModalShow, setCommonModalShow] = useState(false);
  const [isInterested, setIsInterested] = useState(
    dealData?.meetings[0]?.is_interested?.is_interested ?? "No"
  );

  const [instructionsModalShow, setInstructionsModalShow] = useState(false);
  const instructionsModalClose = () => setInstructionsModalShow(false);
  const instructionsHandleShow = () => {
    setInstructionsModalShow(true);
  };

  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const confirmModalClose = () => setConfirmModalShow(false);
  const confirmHandleShow = () => {
    setConfirmModalShow(true);
  };
  const [checkUnallocatedMessage, setCheckUnallocatedMessage] = useState(false);
  const checkUSerType = useSelector((state) => state.userDetails);
  const navigate = useNavigate()
  const [finalData, setFinalData] = useState(null)

  useEffect(() => {
    setFinalData(userData)
  }, [userData])

  const { data: instruction } = GetDealBankAccountDetails({
    dealId: dealData._id,
  });

  const confirmPayment = (resetForm, values = {}) => {
    if (!!!values?.transcationID && !!!values?.document) {
      toast.error("Please enter transaction ID or upload document");
      return;
    }
    setIsLoading(true);

    const formData = new FormData();

    if (values?.document != "") {
      // formData.append("name", "Payment Screenshot");
      formData.append("document", values?.document[0]);
    }

    if (values?.transcationID != "") {
      formData.append("transcationID", values?.transcationID);
    }

    formData.append("is_unallocated", values?.is_unallocated);
    formData.append("startup_deal_id", dealData._id);

    client
      .post("/payment", formData, {
        headers: { Authorization: `Bearer ${getAuthToken()}` },
      })
      .then((res) => {
        res = res.data;

        if (res.statusCode === 200) {
          toast.success(res.message);
          setConfirmModalShow(false);
        } else {
          toast.error(res.message);
        }
        setIsLoading(false);
        refetchData();
      })
      .catch((error) => {
        // toast.error(error.response);
        toast.error(error.response.data.message);
        //console.error("API Catch", error);

        setIsLoading(false);
      });
  };
  return (
    <>
      {dealData?.meetings[0]?.transcation?.status ? (
        <>
          <div className="white-right-box join-box pt-0 pb-0">
            <div className="d-flex align-items-center justify-content-between">
              {dealData?.meetings[0]?.transcation?.status == "pending" ? (
                dealData?.deal_status == 'completed' ? null : isInterested != '' || isInterested != 'No' &&
                  <>
                    {instruction && (<div className="w-50">

                      <Link
                        to=""
                        onClick={(e) => {
                          e.preventDefault();
                          instructionsHandleShow();
                        }}
                        className="blue-text text-nowrap  me-1"
                      >
                        <span className="blueInfoIcon"></span> <u>Instructions</u>
                      </Link>
                    </div>
                    )}
                    <div className="w-100">
                      <button className="d-green-btn ps-3 pe-3 w-100" disabled={true}>
                        Processing
                      </button>
                    </div>
                  </>
              ) : dealData.deal_status != "completed" ? (
                <div className="w-100">
                  {/* {dealData.deal_status} */}
                  <Link
                    onClick={() => {
                      if (finalData.data.master_status_id[0]?.values[0]?.name === "Approved") {

                      } else {
                        redirectToKYC(finalData, navigate, checkUSerType)
                      }
                    }}
                    to={"/portfolio"}>
                    <button
                      className={`d-green-btn ps-3 pe-3 w-100 "is-interested"}`}
                    >
                      Fund Received
                    </button>
                  </Link>
                </div>
              ) : (
                <div className="w-100">
                  <Link
                    onClick={() => {
                      if (finalData.data.master_status_id[0]?.values[0]?.name === "Approved") {

                      } else {
                        redirectToKYC(finalData, navigate, checkUSerType)
                      }
                    }}
                    to={"/portfolio"}>
                    <button
                      className={"d-green-btn ps-3 pe-3 w-100 is-interested"}
                    >
                      View portfolio
                    </button>
                  </Link>
                </div>
              )}
            </div>
            <span className="info-amount mt-2 font-14 text-start d-block">
              <img className="me-2" src={infoIcon} alt="info" /> Interested shown by{" "}
              {dealData.last_updated_by} :{" "}
              {dealData?.meetings[0]?.is_interested?.amount ?? ""}
            </span>
            <span className="received-amount mt-2 text-start d-block">
              <img className="me-2" src={dealReceivedIcon} alt="info" /> {getConstants()?.DEAL_INTEREST_TEXT}: &nbsp;
              {dealData?.total_interested_amount ?? ""}
            </span>
          </div>
        </>
      ) : (
        dealData?.deal_status == 'completed' ? null : isInterested == 'Yes' &&
          <div className="white-right-box join-box">
            <div className="d-flex align-items-center justify-content-between">
              {/* <div className="sm-title text-start black-text fw-600">
              Call for Money
            </div> */}

              <TimeDiff
                displayText="Closing in"
                deadline={dealData.closing_deal_date}
              />
            </div>

            <>
              <div className="text-center d-flex align-items-center justify-content-between mb-1">
                <>
                  {instruction && (
                    <div>
                      <Link
                        to=""
                        onClick={(e) => {
                          e.preventDefault();
                          instructionsHandleShow();
                        }}
                        className="blue-text text-nowrap me-1"
                      >
                        <span className="blueInfoIcon"></span> <u>Instructions</u>
                      </Link>
                    </div>
                  )}
                </>
                <div>
                  <button
                    className="d-green-btn ps-3 pe-3 confirm-amount-btn w-100"
                    onClick={() => {
                      if (finalData.data.master_status_id[0]?.values[0]?.name === "Approved") {
                        confirmHandleShow()
                      } else {
                        redirectToKYC(finalData, navigate, checkUSerType)
                      }
                    }}
                  >
                    Confirm Payment
                  </button>
                </div>
              </div>
              <span className="info-amount mt-2 text-start d-block">
                <img src={infoIcon} alt="info" /> Interested shown by{" "}
                {dealData.last_updated_by} :{" "}
                {dealData?.meetings[0]?.is_interested?.amount ?? ""}
              </span>
              <span className="received-amount mt-2 text-start d-block">
                <img src={dealReceivedIcon} alt="info" /> {getConstants()?.DEAL_INTEREST_TEXT}: &nbsp;
                {dealData?.total_interested_amount ?? ""}
              </span>
            </>
          </div>
      )}

      {/* confirm amount popup */}

      <Modal
        commonModalTitle="Confirm Payment"
        commonModalShow={confirmModalShow}
        commonModalClose={confirmModalClose}
        commonModalData={
          <>
            <div className='d-flex align-items-center justify-content-between mb-4'>
              <h5 className='md-title mb-0' id='documentModalLabel'>Confirm Payment</h5>
              <button type='button' className='btn-close' onClick={confirmModalClose}></button>
            </div>
            <div className="cp-mxh-calc">
              <Formik
                initialValues={{
                  is_unallocated: "No",
                  transcationID: "",
                  document: "",
                }}
                onSubmit={(values, { resetForm }) => {
                  confirmPayment(resetForm, values);
                }}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form className="custom-form">
                    <div className="form-group">
                      {unallocatedAmount != "₹0" && (
                        <>
                          <label>
                            {" "}
                            We have some of your unallocated funds with us which you
                            can use to confirm for this deal
                          </label>
                          <div className="d-flex align-items-center balance-fund-box mt-2">
                            <div className="wallet-icon"></div>
                            <div>Balance funds with us</div>
                            <div className="orange-text ml-auto">
                              {unallocatedAmount}
                            </div>
                          </div>
                          <div className="mt-3">
                            <input
                              type="checkbox"
                              id="is_unallocated"
                              className="form-check-input me-2"
                              onChange={(e) => {
                                setFieldValue(
                                  "is_unallocated",
                                  e.target.checked ? "Yes" : "No"
                                );
                                setCheckUnallocatedMessage(e.target.checked);
                              }}
                            />{" "}
                            Use Balance funds
                          </div>
                          {checkUnallocatedMessage && (
                            <span className="unallocated-text">
                              {
                                dealData?.meetings[0]?.is_interested
                                  ?.unallocated_message
                              }{" "}
                            </span>
                          )}
                        </>
                      )}
                    </div>

                    {unallocatedAmount == "₹0" && <label>
                      {" "}
                      Please provide the transaction ID or attach transaction screen for the confirmation.
                    </label>}
                    <div className="form-group">
                      <label>Transaction ID</label>
                      <Field
                        type="test"
                        className="form-control"
                        name="transcationID"
                      />
                    </div>
                    <div className="form-group">
                      <div className="custom-upload">
                        Attach Transaction Screenshot
                        <button className="custom-upload-btn">Choose file</button>
                        <Field
                          name="document"
                          component={SingleFileInputComponent}
                          label="Choose file"
                          desc=""
                        />
                      </div>
                    </div>

                    <div>
                      {isLoading ? (
                        <BtnSpinner />
                      ) : (
                        <button className="theme-btn btn w-100" type="submit">
                          Submit
                        </button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
      />

      {/* instractions popup */}

      {instruction && (
        <Modal
          commonModalTitle="Instructions"
          commonModalShow={instructionsModalShow}
          commonModalClose={instructionsModalClose}
          commonModalData={
            <>
              <div className='d-flex align-items-center justify-content-between mb-4'>
                <h5 className='md-title mb-0' id='documentModalLabel'>Instructions</h5>
                <button type='button' className='btn-close' onClick={instructionsModalClose}></button>
              </div>
              <span className="text-sm text-muted">
                Please use the following details for transferring the funds.
              </span>

              {instruction?._id && (
                <table
                  key={instruction._id}
                  className="table table-bordered table-light instructions-table mt-2"
                >
                  <tbody>
                    {instruction?.account_holder_name && (
                      <tr>
                        <td>Account name:</td>
                        <td>
                          <strong>{instruction?.account_holder_name}</strong>
                        </td>
                      </tr>
                    )}

                    {instruction?.bank_account_number && (
                      <tr>
                        <td>Account number:</td>
                        <td>
                          <strong>{instruction?.bank_account_number}</strong>
                        </td>
                      </tr>
                    )}

                    {instruction?.micr_code && (
                      <tr>
                        <td>MICR code:</td>
                        <td>
                          <strong>{instruction?.micr_code}</strong>
                        </td>
                      </tr>
                    )}

                    {instruction?.ifsc_code && (
                      <tr>
                        <td>IFSC Code:</td>
                        <td>
                          <strong>{instruction?.ifsc_code}</strong>
                        </td>
                      </tr>
                    )}

                    {instruction?.account_type_value && (
                      <tr>
                        <td>Account type:</td>
                        <td>
                          <strong>{instruction?.account_type_value}</strong>
                        </td>
                      </tr>
                    )}

                    {(instruction?.bank_name || instruction?.branch_name) && (
                      <tr>
                        <td>Branch address:</td>
                        <td>
                          <strong>
                            {instruction?.bank_name +
                              ", " +
                              instruction?.branch_name}
                          </strong>
                        </td>
                      </tr>
                    )}

                    {instruction?.accounts[0] && (
                      <>
                        <tr>
                          <td colSpan={2} className="text-center">
                            <strong>{instruction.account_heading}</strong>
                          </td>
                        </tr>

                        {instruction?.accounts[0]?.receiving_bank_name && (
                          <tr>
                            <td>Receiving Bank Name:</td>
                            <td>
                              <strong>
                                {instruction?.accounts[0]?.receiving_bank_name}
                              </strong>
                            </td>
                          </tr>
                        )}

                        {instruction?.accounts[0]
                          ?.receiving_bank_swift_code && (
                            <tr>
                              <td>Receiving Bank SWIFT Code:</td>
                              <td>
                                <strong>
                                  {
                                    instruction?.accounts[0]
                                      ?.receiving_bank_swift_code
                                  }
                                </strong>
                              </td>
                            </tr>
                          )}

                        {instruction?.accounts[0]
                          ?.receiver_correspondent_bank && (
                            <tr>
                              <td>Receiver's Correspondent Bank:</td>
                              <td>
                                <strong>
                                  {
                                    instruction?.accounts[0]
                                      ?.receiver_correspondent_bank
                                  }
                                </strong>
                              </td>
                            </tr>
                          )}

                        {instruction?.accounts[0]
                          ?.receiver_correspondent_bank_swift_code && (
                            <tr>
                              <td>Receiver's Correspondent Bank SWIFT Code:</td>
                              <td>
                                <strong>
                                  {
                                    instruction?.accounts[0]
                                      ?.receiver_correspondent_bank_swift_code
                                  }
                                </strong>
                              </td>
                            </tr>
                          )}

                        {instruction?.accounts[0]?.bank_clearing_code && (
                          <tr>
                            <td>Bank Clearing Code:</td>
                            <td>
                              <strong>
                                {instruction?.accounts[0]?.bank_clearing_code}
                              </strong>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              )}
            </>
          }
        />
      )}
    </>
  );
};

export const convertMoney = ({ }) => { };
