import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { clearGetMyBackrrDeals, getMyBackrrDeals } from '../../store/actions/getMyBackrrDealsAction'
import { clearSaveMyBackrrInterest, saveMyBackrrInterest } from '../../store/actions/saveMyBackrrInterestAction'
import backArrow from '../../assets/img/back.svg'
import noListingImg from '../../assets/img/no-listing-found.png'
import portfolioIcon from '../../assets/img/portfolio-icon.svg'
import Modal from '../../utils/Modal'
import { Field, Form, Formik } from 'formik'
import * as Yup from "yup";
import BtnSpinner from '../../utils/BtnSpinner'
import DashboardLayout from '../../views/main/deals/DashboardLayout'

export default function MyBackrrsDeals() {

    const { state } = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [data, setData] = useState([])
    const [showConfirmInvestment, setShowConfirmInvestment] = useState(false)
    const [selectedDeal, setSelectedDeal] = useState(null)
    const [page, setPage] = useState(1)

    const getMyBackrrDealsResponse = useSelector(state => state.getMyBackrrDealsReducer.data);
    const saveMyBackrrInterestResponse = useSelector(state => state.saveMyBackrrInterestReducer.data)
    const saveMyBackrrInterestLoading = useSelector(state => state.saveMyBackrrInterestReducer.loading)

    useEffect(() => {
        setPage(1)
        fetchDeals(1)
    }, [])

    const fetchDeals = (page_no) => {
        dispatch(getMyBackrrDeals({ page: page_no, investor_id: state?._id }));
    }

    useEffect(() => {
        if (getMyBackrrDealsResponse != null) {
            if (getMyBackrrDealsResponse.data != null) {
                let deals = []
                if (page == 1) {
                    deals = getMyBackrrDealsResponse.data
                } else {
                    deals = [...data, ...getMyBackrrDealsResponse.data]
                }
                let liveDeals = []
                let interestedDeals = []
                let nonInterestedDeals = []
                liveDeals = deals.filter(item => { return item.deal_status != "completed" })
                interestedDeals = liveDeals.filter(item => { return item.meetings[0]?.is_interested?.is_interested == "Yes" })
                nonInterestedDeals = liveDeals.filter(item => { return item.meetings[0]?.is_interested?.is_interested != "Yes" })
                let sortedDeals = []
                sortedDeals = [...sortedDeals, ...interestedDeals]
                sortedDeals = [...sortedDeals, ...nonInterestedDeals]
                setData(sortedDeals)
                setPage(page => page + 1)
                if (getMyBackrrDealsResponse.data.length != 0 && getMyBackrrDealsResponse.data.length < getMyBackrrDealsResponse.count) {
                    fetchDeals(page + 1)
                }
                dispatch(clearGetMyBackrrDeals());
            }
        }
    }, [getMyBackrrDealsResponse]);

    const saveInterestAPI = (values, response) => {
        let request = {
            investor_id: state?._id,
            dealRequest: {
                startup_deal_id: selectedDeal?._id,
                amount: values.amount ? values.amount?.toString() : "0",
                is_interested: response
            }
        }
        dispatch(saveMyBackrrInterest(request))
    }

    useEffect(() => {
        if (saveMyBackrrInterestResponse != null) {
            if (saveMyBackrrInterestResponse.data != null) {
                console.log("saveInterestResponse", saveMyBackrrInterestResponse)
                setShowConfirmInvestment(false)
                toast.success(saveMyBackrrInterestResponse.message)
                setSelectedDeal(null)
                setPage(1)
                fetchDeals(1)
                dispatch(clearSaveMyBackrrInterest())
            }
        }
    }, [saveMyBackrrInterestResponse])

    return (
        <div>
            <DashboardLayout>
                <div className='header-mobile d-md-none border-b'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div>
                            <Link className="back-btn blue-text d-block" to="/my-backrrs">
                                <img src={backArrow} alt="arrow" /> {state?.name}
                            </Link>
                        </div>
                        <div>
                            <button className="back-btn" type="button" onClick={() => { navigate("/my-backrr-portfolio", { state: state }) }}><img src={portfolioIcon} alt="arrow" className='d-inline-block' /></button>
                        </div>
                    </div>
                </div>
                <div className='addround-header header-desktop'>
                    <div className='container'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div>
                                <div className="md-title text-start fw-700 cursor-pointer d-flex align-items-center">
                                    <button className="back-btn" type="button" onClick={() => { navigate("/my-backrrs") }}><img src={backArrow} alt="arrow" className='d-inline-block' /></button>
                                    <div className='pt-1 blue-text'>{state?.name}</div>
                                </div>
                            </div>
                            <div>
                                <button className="back-btn" type="button" onClick={() => { navigate("/my-backrr-portfolio", { state: state }) }}><img src={portfolioIcon} alt="arrow" className='d-inline-block' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-4 pb-4 ps-5 pe-5">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className='text-lgray'>Total Commitments: {state?.commitment_amount}</div>
                        <div className='text-lgray'>Total Invested: {state?.interestedAmount}</div>
                    </div>
                    <div className='favourite-list'>
                        {
                            data.length > 0 ?
                                data.map((item) => (
                                    <li className="p-0" key={item._id}>
                                        <div className="d-flex align-items-center justify-content-between favourite-box pt-3 pb-3">
                                            <div className="circle-box circle-text">
                                                {
                                                    item.startups.image ?
                                                        <img
                                                            src={item.startups.image}
                                                            alt=""
                                                        /> :
                                                        <span className="ct-name">{item.startups?.company_name?.split(" ")[0]?.substring(0, 1)}{item.startups?.company_name?.split(" ")[1]?.substring(0, 1)}</span>
                                                }
                                            </div>
                                            <div className="ml-15 d-flex align-items-center justify-content-between" style={{ width: "calc(100% - 60px)" }}>
                                                <div>
                                                    <div className="md-title mb-6">
                                                        {item.startups.company_name}
                                                    </div>
                                                    <div className="sm-title font-0-8em text-start">
                                                        <span className="black-text">{item.startups.sector[0]}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    {item.meetings[0]?.is_interested?.is_interested == "Yes" && <div className={`theme-text ${item.meetings[0]?.is_interested?.is_signatured == "Pending" ? 'text-center' : "text-center"}`}>{item.meetings[0]?.is_interested?.amount}</div>}
                                                    {
                                                        item?.deal_status != "completed" && <div>
                                                            {
                                                                item.meetings[0]?.is_interested?.is_interested == "Yes" ?
                                                                    (
                                                                        // item.meetings[0]?.is_interested?.is_signatured == "Pending" ? <div className='waiting-text'>Waiting for consent</div> :
                                                                        <button type='button' className='fill-gray-btn text-decoration-underline' onClick={() => { setShowConfirmInvestment(true); setSelectedDeal(item) }}>Update</button>
                                                                    )
                                                                    :
                                                                    <button type='button' className='theme-btn  small-btn text-decoration-underline' onClick={() => { setShowConfirmInvestment(true); setSelectedDeal(item) }}>Add Amount</button>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))
                                :
                                <div className="no-listing-card text-center pt-4">
                                    <img src={noListingImg} alt="no list" />
                                    <div className="xs-title text-center mt-4 text-lgray">No listing found</div>
                                </div>
                        }
                    </div>
                </div>
            </DashboardLayout>

            <Modal
                commonModalTitle={state?.name}
                commonModalShow={showConfirmInvestment}
                commonModalClose={() => { setShowConfirmInvestment(false) }}
                commonModalData={
                    <>
                        <div className='d-flex align-items-center justify-content-between mb-4'>
                            <h5 className='md-title mb-0' id='documentModalLabel'>{state?.name}</h5>
                            <button type='button' className='btn-close' onClick={() => { setShowConfirmInvestment(false) }}></button>
                        </div>
                        <Formik
                            initialValues={{
                                amount: "",
                            }}
                            validationSchema={Yup.object().shape({
                                amount: Yup.number().integer().required("Amount is required"),
                            })}
                            onSubmit={(values) => {
                                saveInterestAPI(values, "Yes");
                            }}
                        >
                            {({ errors, touched }) => (
                                <Form className="custom-form">
                                    <div className="form-group">
                                        <label>Enter Investment Amount</label>

                                        <Field
                                            type="number"
                                            className="form-control"
                                            name="amount"
                                            min="0"
                                        />
                                        {errors.amount && touched.amount && (
                                            <small className="text-danger">{errors.amount}</small>
                                        )}

                                        {(selectedDeal?.ref_min_investment_amount !== "" ||
                                            selectedDeal?.ref_max_investment_amount !== "") && (
                                                <small className="d-block text-muted">
                                                    {selectedDeal?.ref_min_investment_amount && (
                                                        <span>
                                                            {" "}
                                                            Min: {selectedDeal?.ref_min_investment_amount ?? 0}{" "}
                                                        </span>
                                                    )}

                                                    {selectedDeal?.ref_max_investment_amount && (
                                                        <span>
                                                            {" "}
                                                            and Max: {selectedDeal?.ref_max_investment_amount}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                    </div>

                                    <div>
                                        {saveMyBackrrInterestLoading ? (
                                            <BtnSpinner />
                                        ) : (
                                            <button className="theme-btn btn w-100" type="submit">
                                                Submit
                                            </button>
                                        )}
                                    </div>

                                    {selectedDeal?.meetings[0]?.is_interested?.is_interested == "Yes" && (
                                        <div
                                            className="col-12 text-center not-interest-text mt-4 cursor-pointer"
                                            onClick={() => { saveInterestAPI({}, "No") }}
                                        >
                                            <u>Not interested to invest </u>
                                        </div>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </>
                }
            />
        </div>
    )
}
