import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import faq from "../../../assets/img/faq_blue.png";
import webImg from "../../../assets/img/web.svg";
import backArrow from "../../../assets/img/back.svg";
import documentImg from "../../../assets/img/document.svg";
import { GetDealLists, GetUserDetailsUpdated } from "../../../api";
import ShareDealComponent from "../../../components/deal/ShareDealComponent";
import { CallForMoney, InvestmentInterest, ShowCase } from "./PitchCall";
import moment from "moment/moment";
import { Pdf, ViewIcon } from "../../../assets/img";
import Modal from "../../../utils/Modal";
import pitchRecording from "../../../assets/img/pitch-recording.svg";
import DashboardLayout from "./DashboardLayout";
import { clearSaveFavourite, saveFavourite } from "../../../store/actions/saveFavouriteAction";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import noListingImg from '../../../assets/img/no-listing-found.png'
import { getFounderNames, getLeadInvestorNames } from "../../../utils/helpers";


const DealLists = () => {

  const dispatch = useDispatch()

  const [searchParams] = useSearchParams();

  const getCordinatesLoader = useSelector(state => state.getCordinatesReducer.loading)

  const listId = searchParams.get("deal-id");
  const dealTypeShow = searchParams.get("deal-type");
  console.log(dealTypeShow);

  const {
    data: listData,
    isLoading: isListLoading,
    refetch: refetchData,
  } = GetDealLists({
    listId: listId,
    page: 1,
  });

  const { data: userData } = GetUserDetailsUpdated();

  const finaldata = listData ? listData.data : [];
  const unallocatedAmount = listData ? listData.unallocated_amount : 0;

  console.log(listData, "listDatalistDatalistDatalistDatalistDatalistDatalistData");

  //   document

  const [commonModalShow, setCommonModalShow] = useState(false);
  const [set_pich_url, setOpenPitchRecordingUrl] = useState(false);


  const saveFavouriteResponse = useSelector(state => state.saveFavouriteReducer.data)


  const commonModalClose = () => setCommonModalShow(false);

  const [dealData, setdealData] = useState([]);

  const commonHandleShow = (dealDetailsData) => {
    setdealData(dealDetailsData);
    setCommonModalShow(true);
  };

  const [open_document, setOpenPitchRecording] = useState(false);

  const pitchRecoringClose = () => setOpenPitchRecording(false);

  const handleOnDocumentClick = (data) => {

    const urlParams = data.split('?');
    let videoId = null;
    console.log(urlParams, "urlParamsurlParamsurlParamsurlParamsurlParams")
    for (const param of urlParams) {
      if (param.startsWith('v=')) {
        // Extract the video ID if the parameter starts with 'v='.
        videoId = param.substring(2);
        break;
      }
    }
    console.log(`https://www.youtube.com/embed/${videoId}`, "`https://www.youtube.com/embed/${videoId}`")

    setOpenPitchRecordingUrl(`https://www.youtube.com/embed/${videoId}`);
    setOpenPitchRecording(true)
  }

  useEffect(() => {
    if (userData) {
      console.log("user Detail", userData)
    }
  }, [userData])

  const addBookmark = (dealDeatils) => {
    console.log("is Fav", dealDeatils?.is_fav)
    let request = {
      startup_deal_id: dealDeatils._id
    }

    dispatch(saveFavourite(request))
  }

  useEffect(() => {
    if (saveFavouriteResponse != null) {
      if (saveFavouriteResponse.data != null) {
        console.log("saveFavouriteResponse", saveFavouriteResponse)
        toast.success(saveFavouriteResponse.message)
        refetchData()
        dispatch(clearSaveFavourite())
      }
    }
  }, [saveFavouriteResponse])

  const boxCLass = (dealDeatils) => {
    let classname = "";
    if (dealDeatils?.deal_flow[0]?.value == "call_for_money") {
      if (dealDeatils?.meetings[0]?.transcation?.status) {
        if (dealDeatils?.meetings[0]?.transcation?.status == "pending") {
          if (dealDeatils?.deal_status == 'completed') {
            classname = "pe-3"
          } else {
            if (dealDeatils?.meetings[0]?.is_interested?.is_interested != '' || dealDeatils?.meetings[0]?.is_interested?.is_interested != 'No') {
              classname = "dib-left-box pe-3"
            } else {
              classname = "pe-3"
            }
          }
        } else {
          classname = "dib-left-box pe-3"
        }
      } else {
        if (dealDeatils?.deal_status == 'completed') {
          classname = "pe-3"
        } else {
          if (dealDeatils?.meetings[0]?.is_interested?.is_interested == 'Yes') {
            classname = "dib-left-box pe-3"
          } else {
            classname = "pe-3"
          }
        }
      }
    } else {
      classname = "dib-left-box pe-3"
    }
    return classname
  }


  return (
    <>
      <DashboardLayout>
        <div className="header-mobile d-md-none border-b">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <Link class="back-btn blue-text d-block" to="/dashboard">
                <img src={backArrow} alt="arrow" /> {dealTypeShow ? dealTypeShow : "Deals"}
              </Link>
            </div>
            <div className="text-lgray"> {listData ? `Total ${listData.count}` : ""}</div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between cr-heading header-desktop">
          <div className="md-title text-start fw-700">{dealTypeShow ? dealTypeShow : "Deals"}</div>
          <div>Total {listData ? listData.count : ""}</div>
        </div>
        <div className="pt-4 pb-4 ps-5 pe-5 detail-dtl-list">
          {finaldata?.length > 0 ?
            finaldata.map((dealDeatils, index) => (
              <div className="card form-card ps-4 pe-4" key={index}>
                <div className="extra-link-actions">
                  <Link to={"/deal-details?slug=" + dealDeatils.random_deal_id} className="ela-action"><i class="bi bi-eye"></i></Link>
                  {dealDeatils?.faq?.length > 0 && <Link to={`/deal-details?slug=${dealDeatils.random_deal_id}&page=faq`} className="ela-action faq-tag"><img src={faq} alt="faq" /></Link>}
                  <button type="button" className={dealDeatils?.is_fav == "Yes" ? "ela-action wishlist-action selected" : "ela-action"} onClick={() => { addBookmark(dealDeatils) }}><i class={dealDeatils?.is_fav == "Yes" ? "bi bi-star-fill" : "bi bi-star"}></i></button>
                  {/* {userData?.show_mybackkr == "Yes" && dealDeatils?.deal_flow[0]?.value !== "startup_showcase" && <div class="btn-group custom-dropdown">
                    <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="bi bi-three-dots-vertical"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li><button class="dropdown-item" type="button">Manage Backrrs</button></li>
                    </ul>
                  </div>} */}

                </div>
                <Link
                  to={`/deal-details?slug=${dealDeatils.random_deal_id}`}
                >
                  <div className="d-flex align-items-center justify-content-between border-b pt-3 pb-3">
                    <div className="d-flex align-items-center">
                      <div className="circle-box circle-text">
                        {dealDeatils.startups.image ? (
                          <img src={dealDeatils.startups.image} />
                        ) : (<span className="ct-name">{dealDeatils?.company_name?.split(" ")[0]?.substring(0, 1)}{dealDeatils?.company_name?.split(" ")[1]?.substring(0, 1)}</span>)}
                      </div>
                      <div className="ml-15">
                        {dealDeatils.company_name && (
                          <div className="md-title mb-6">
                            {dealDeatils.company_name}
                          </div>
                        )}

                        {dealDeatils?.startups?.description != "" && (
                          <div className="sm-title font-0-8em text-start">
                            <span className="text-lgray">
                              {dealDeatils?.startups?.description}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* ********* call deal share component********* */}

                    <>
                      <ShareDealComponent DealData={dealDeatils} />
                    </>

                    {/* ********* end  call deal share component********* */}
                  </div>
                </Link>

                <div className="row pt-4 pb-3 border-b deal-info-block ">
                  <div className="col-xxl-6 col-lg-7 col-md-6 pe-3">
                    <div
                      className={
                        boxCLass(dealDeatils)
                        // dealDeatils?.deal_flow[0]?.value === "call_for_money" ?
                        //   dealDeatils?.meetings[0]?.transcation?.status ?
                        //     dealDeatils?.meetings[0]?.transcation?.status == "pending" ?
                        //       dealDeatils?.deal_status == 'completed' ?
                        //         "pe-3" :
                        //         dealDeatils?.meetings[0]?.is_interested?.is_interested != '' || dealDeatils?.meetings[0]?.is_interested?.is_interested != 'No' ?
                        //           "dib-left-box pe-3" :
                        //           "pe-3" :
                        //       "dib-left-box pe-3" :
                        //     dealDeatils?.deal_status == 'completed' ?
                        //       "pe-3" :
                        //       "dib-left-box pe-3" :
                        //   "dib-left-box pe-3"
                      }>

                      {dealDeatils?.startups?.founders?.length > 0 && (
                        <div className="mb-3">
                          <div className="di-sub-label mb-2">Founders:</div>
                          <div className="di-sub-value">
                            {getFounderNames(dealDeatils?.startups?.founders, true)}
                          </div>
                        </div>
                      )}

                      {dealDeatils?.startups?.lead_investors?.length > 0 && (
                        <div className="mb-3">
                          <div className="di-sub-label mb-2">
                            Lead Investors:
                          </div>
                          <div className="di-sub-value">
                            {getLeadInvestorNames(dealDeatils?.startups?.lead_investors, true)}
                          </div>
                        </div>
                      )}

                      {dealDeatils?.fixed_details?.security_type && (
                        <div className="mb-3">
                          <div className="di-sub-label mb-2">
                            Security type:
                          </div>
                          <div className="di-sub-value">
                            {dealDeatils?.fixed_details?.security_type}

                          </div>
                        </div>
                      )}

                      <div className="dtl-info d-flex ">

                        {dealDeatils.startups.website_url != "" && (
                          <div className="dtl-info-box mb-2 me-2">
                            <Link
                              to={dealDeatils?.startups?.website_url}
                              target="_blank"
                            >
                              <div className="gray-box text-center">
                                <div className="icon">
                                  <img src={webImg} alt="web" />
                                </div>
                                <div className="sm-title">Website</div>
                              </div>
                            </Link>
                          </div>
                        )}

                        {dealDeatils?.startups?.document?.length > 0 && (
                          <div className="dtl-info-box mb-2 me-2">
                            <button
                              type="button"
                              onClick={() => commonHandleShow(dealDeatils)}
                              className="gray-box d-block text-center w-100"
                            >
                              <div className="icon">
                                <img src={documentImg} alt="document" />
                              </div>
                              <div className="sm-title">Documents</div>
                            </button>
                          </div>
                        )}

                        {/* pitch recording */}

                        {(dealDeatils?.deal_flow[0]?.value ===
                          "investment_interest" ||
                          dealDeatils?.deal_flow[0]?.value ===
                          "call_for_money") &&
                          dealDeatils.meetings[0]?.pitch_recording && (
                            <div className="dtl-info-box pitch-info-box mb-2">
                              {/* <Link
                                to={
                                  dealDeatils?.meetings[0]?.pitch_recording
                                }
                                target="_blank"
                                className="gray-box d-block text-center w-100"
                              > */}
                              <button className="pitch-recording gray-box d-block text-center w-100" type="button" onClick={() => handleOnDocumentClick(dealDeatils.meetings[0]?.pitch_recording)}>
                                <div className="icon">
                                  <img
                                    src={pitchRecording}
                                    alt="document"
                                  />
                                </div>
                                <div className="sm-title">
                                  Pitch Recording
                                </div>
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  {/* ********* call join pitch deal component********* */}

                  {dealDeatils?.deal_flow && (
                    <div className="col-xxl-6 col-lg-5 col-md-6">
                      {dealDeatils?.deal_flow[0]?.value ===
                        "startup_showcase" && (
                          <ShowCase dealData={dealDeatils} />
                        )}

                      {dealDeatils?.deal_flow[0]?.value ===
                        "investment_interest" && (
                          <InvestmentInterest
                            dealData={dealDeatils}
                            refetchData={refetchData}
                            unallocatedAmount={unallocatedAmount}
                          />
                        )}

                      {dealDeatils?.deal_flow[0]?.value ===
                        "call_for_money" && (
                          <CallForMoney
                            dealData={dealDeatils}
                            refetchData={refetchData}
                            unallocatedAmount={unallocatedAmount}
                          />
                        )}
                    </div>
                  )}

                  {/* ********* all join pitch deal component********* */}
                </div>
              </div>
            )) :
            <div className="no-listing-card text-center pt-4">
              <img src={noListingImg} alt="no list" />
              <div className="xs-title text-center mt-4 text-lgray">No listing found</div>
            </div>
          }
        </div>
      </DashboardLayout>

      {/* Documents Modal */}
      <Modal
        commonModalTitle="Documents"
        commonModalShow={commonModalShow}
        commonModalClose={commonModalClose}
        crossHide={true}
        isDocumentModal={true}
        commonModalData={
          <>
            <div className='d-flex align-items-center justify-content-between mb-4'>
              <h5 className='md-title mb-0' id='documentModalLabel'>{dealData?.company_name} - Documents</h5>
              <button type='button' className='btn-close' onClick={commonModalClose}></button>
            </div>
            {/* <button className='btn-close close-pos-btn' type="button" onClick={commonModalClose}></button> */}
            <ul className="document-list mt-2">
              {dealData?.startups &&
                dealData?.startups?.document?.length > 0 &&
                dealData?.startups?.document.map((doc) => (
                  <Link to={doc.url} target="_blank" key={doc.name}>
                    <li className="d-flex align-items-center">
                      <div className="icon">
                        <img src={Pdf} alt="pdf" />
                      </div>

                      <div className="ml-15">
                        <div className="md-title text-start">{doc.name}</div>
                        <div className="sm-title text-start mt-1">
                          {moment(doc.date).format("DD-MMM-YYYY")}
                        </div>
                      </div>

                      <div className="ml-auto">
                        <span className="view-icon">
                          <img src={ViewIcon} alt="view" />
                        </span>
                      </div>
                    </li>
                  </Link>
                ))}
            </ul>
          </>
        }
      />

      <Modal commonModalShow={open_document} crossHide={true} hideHeading={true}
        commonModalData={
          <>
            <button className='btn-close close-pos-btn' type="button" onClick={pitchRecoringClose}></button>
            {/* <iframe width="100%" height="450" src="https://www.youtube.com/embed/yAoLSRbwxL8?si=f2W-a85rz41BMErW" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}

            <iframe width="100%" height="450" src={set_pich_url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </>
        }>
      </Modal>
      {
        getCordinatesLoader && <div className={`modal fade common-modal ${getCordinatesLoader ? 'show' : ''}`}><div className='spinner text-center'><span className='spinner-border spinner-border-xl text-warning loader'></span></div></div>
      }
    </>
  );
};

export default DealLists;
