import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import BtnSpinner from "../../../../utils/BtnSpinner";
import { Back } from "../../../../assets/img";
import {
  CustomInputComponent,
  FileInputComponent,
} from "../../../../components/formik/FieldComponents";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { SET_PAGE } from "../../../../store/actions";
import { UploadInvestorDocuments, InvestorDocs, GetUserDetailsUpdated } from "../../../../api";

const UploadDocuments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const checkUSerType = useSelector((state) => state.userDetails);
  const { data: userData } = GetUserDetailsUpdated();
  const [documentType, setDocumentType] = useState("");


  const personalInformation = useSelector(
    (state) => state.indianCitizenship.personalInformation
  );

  const [ischeckValidate, setcheckValidate] = React.useState(true);
  const [ischeckValidatefileAddressProof, setcheckValidatefileAddressProof] =
    React.useState(true);

  const [ischeckValidateLaws, setcheckValidateLaws] = React.useState(true);
  const [ischeckValidateCetificate, setcheckValidateCetificate] = React.useState(true);
  const [ischeckValidateMoa, setcheckValidateMoa] = React.useState(true);


  useEffect(() => {
    if (userData?.data?.user_individual_type === "indian_corporate") {
      setDocumentType("indian-corporate");
    } else if (userData?.data?.user_individual_type === "foreign_citizen") {
      setDocumentType("foreign-citizenship");
    } else if (userData?.data?.user_individual_type === "foreign_corporate") {
      setDocumentType("foreign-corporate");
    }
    else {
      setDocumentType("indian-citizenship");
    }
  }, [userData]);

  const { mutateAsync: uploadDocs, isLoading: isUploadDocsLoading } =
    UploadInvestorDocuments();

  const { data: docs, isLoading: isDocsLoading } = InvestorDocs({
    citizenship_type: documentType,

  });


  useEffect(() => {
    if (
      !isDocsLoading &&
      docs.fileIdentityProof &&
      docs.fileIdentityProof.length > 0
    ) {
      setcheckValidate(false);
    } else {
      setcheckValidate(true);
    }

    if (
      !isDocsLoading &&
      docs.fileAddressProof &&
      docs.fileAddressProof.length > 0
    ) {
      setcheckValidatefileAddressProof(false);
    } else {
      setcheckValidatefileAddressProof(true);
    }
  }, [isDocsLoading, docs]);

  const UploadDocumentsValidationIndian = Yup.object().shape({
    panNumber: Yup.string().required("Pan number is required"),
    fileIdentityProof: ischeckValidate
      ? Yup.array()
        .min(1, "Identity proof required")
        .of(Yup.string().trim().required())
      : Yup.array(),
    fileAddressProof: ischeckValidatefileAddressProof
      ? Yup.array()
        .min(1, "Identity proof required")
        .of(Yup.string().trim().required())
      : Yup.array(),
  });

  const UploadDocumentsValidationForeignCorporate = Yup.object().shape({
    byLawsCertificate: ischeckValidateLaws ? Yup.array().min(1, "By-Laws Certificate is required").of(Yup.string().trim().required()) : Yup.array(),
    certificateOfIncorporation: ischeckValidateCetificate ? Yup.array().min(1, "Certificate is required").of(Yup.string().trim().required()) : Yup.array(),
    fileAddressProof: ischeckValidatefileAddressProof ? Yup.array().min(1, "Address proof required").of(Yup.string().trim().required()) : Yup.array(),
  });

  const UploadDocumentsValidationIndianCorporate = Yup.object().shape({
    moaAndAoa: ischeckValidateMoa
      ? Yup.array()
        .min(1, "Moa and Aoa is required")
        .of(Yup.string().trim().required())
      : Yup.array(),
    certificateOfIncorporation: ischeckValidateCetificate
      ? Yup.array()
        .min(1, "Certificate is required")
        .of(Yup.string().trim().required())
      : Yup.array(),
    fileAddressProof: ischeckValidatefileAddressProof
      ? Yup.array()
        .min(1, "Address proof required")
        .of(Yup.string().trim().required())
      : Yup.array(),
  });

  const UploadDocumentsValidationForeignCitizenship = Yup.object().shape({
    fileIdentityProof: ischeckValidate ? Yup.array().min(1, "Identity proof required").of(Yup.string().trim().required()) : Yup.array(),
    fileAddressProof: ischeckValidatefileAddressProof ? Yup.array().min(1, "Address proof required").of(Yup.string().trim().required()) : Yup.array(),
  });

  const getValidationSchema = () => {
    switch (documentType) {
      case 'indian-citizenship': {
        return UploadDocumentsValidationIndian
      }
      case 'indian-corporate': {
        return UploadDocumentsValidationIndianCorporate

      }
      case 'foreign-corporate': {
        return UploadDocumentsValidationForeignCorporate

      }
      case 'foreign-citizenship': {
        return UploadDocumentsValidationForeignCitizenship

      }
      default: {
        return UploadDocumentsValidationIndian

      }
    }
  }

  if (!isDocsLoading) {
    console.log("pppAPI", docs);
  }
  let documentHeading = 'Indian Citizen'

  if (userData?.data?.user_individual_type && userData?.data?.user_individual_type == 'indian_citizen') {
    if (userData?.data?.country_citizenship_id != '101') {
      documentHeading = 'NRI'
    }
  } else if (userData?.data?.user_individual_type == 'indian_corporate') {
    documentHeading = 'Indian Corporate/Firm'
  } else if (userData?.data?.user_individual_type == 'huf') {
    documentHeading = 'Indian HUF'
  } else if (userData?.data?.user_individual_type == 'foreign_citizen') {
    documentHeading = 'Foreign Citizen'
  } else if (userData?.data?.user_individual_type == 'foreign_corporate') {
    documentHeading = 'Foreign Corporate'
  }

  return (
    <section className="mid-wrap">
      <div className="forms-header border-b pt-3 pb-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-2 col-2">
              <Link
                to="/bank-details"
                className="back-arrow"
              >
                <img src={Back} alt="back" />
              </Link>
            </div>
            <div className="col-md-8 col-8">
              <div className="lg-title text-center">Complete your Profile</div>
            </div>
            <div className="col-md-2 col-2 text-end">
              <span className="pl-15 sm-title">{(checkUSerType.userType == 'indian_citizen' || checkUSerType.userType == 'huf' || checkUSerType.userType == 'foreign_citizen') ? '4/6' : '4/5'}</span>
            </div>
          </div>
          <div className="text-center step-process mt-1">
            <div className="d-inline-flex">
              <span className="process-step active"></span>
              <span className="process-step active"></span>
              <span className="process-step active"></span>
              <span className="process-step selected"></span>
              <span className="process-step"></span>
              {(checkUSerType.userType == 'indian_citizen' || checkUSerType.userType == 'huf' || checkUSerType.userType == 'foreign_citizen') && <span className="process-step"></span>}

            </div>
          </div>
        </div>
      </div>
      <div className="pt-0 pb-5 kyc-form-block">
        <div className="container">
          <div className="card form-card mt-4">
            <div className="card-body">
              {isDocsLoading ? (
                <div className="text-center">
                  <span className="spinner-border spinner-border-lg text-primary"></span>
                </div>
              ) : (
                <>
                  <h5 className="card-title md-title mb-4 text-center heading-fordesktop">
                    Upload Document ({documentHeading})
                  </h5>
                  <Formik
                    initialValues={
                      docs
                        ? docs
                        : {
                          panNumber: "",
                          fileIdentityProof: [],
                          fileAddressProof: [],
                          byLawsCertificate: [],
                          certificateOfIncorporation: [],
                          moaAndAoa: [],
                        }
                    }
                    enableReinitialize={true}
                    validationSchema={getValidationSchema}
                    onSubmit={(values) => {

                      const formData = new FormData();
                      if (documentType == 'indian-corporate') {
                        for (
                          let i = 0;
                          i < values.certificateOfIncorporation.length;
                          i++
                        ) {
                          let file = values.certificateOfIncorporation[i];
                          if (file?.size) {
                            formData.append("name", "certificate Of Incorporation");
                            formData.append("documents", file);
                            formData.append(
                              "upload_type",
                              "certificate_of_incorporation"
                            );
                          } else {
                            formData.append("name", file.name);
                            formData.append(`documents[${file.document_id}]`, "");
                            formData.append("upload_type", file.upload_type);
                          }
                        }

                        for (let i = 0; i < values.moaAndAoa.length; i++) {
                          let file = values.moaAndAoa[i];
                          if (file?.size) {
                            formData.append("name", "MOA & AOA");
                            formData.append("documents", file);
                            formData.append("upload_type", "moa_and_aoa");
                          } else {
                            formData.append("name", file.name);
                            formData.append(`documents[${file.document_id}]`, "");
                            formData.append("upload_type", file.upload_type);
                          }
                        }

                        for (let i = 0; i < values.fileAddressProof.length; i++) {
                          let file = values.fileAddressProof[i];
                          if (file?.size) {
                            formData.append("name", "Address Proof");
                            formData.append("documents", file);
                            formData.append("upload_type", "address_proof");
                          } else {
                            formData.append("name", file.name);
                            formData.append(`documents[${file.document_id}]`, "");
                            formData.append("upload_type", file.upload_type);
                          }
                        }

                      }
                      if (documentType == 'indian-citizenship') {
                        if (values.fileIdentityProof) {
                          for (
                            let i = 0;
                            i < values.fileIdentityProof.length;
                            i++
                          ) {
                            let file = values.fileIdentityProof[i];
                            if (file?.size) {
                              formData.append("name", "Pan Card");
                              formData.append("documents", file);
                              // formData.append("group_id", values.group_id);
                              formData.append("upload_type", "identity_proof");
                            } else {
                              formData.append("name", file.name);
                              formData.append(
                                `documents[${file.document_id}]`,
                                ""
                              );
                              // formData.append("group_id", file.group_id);
                              formData.append("upload_type", file.upload_type);
                            }
                          }
                        }

                        if (values.fileAddressProof) {
                          for (
                            let i = 0;
                            i < values.fileAddressProof.length;
                            i++
                          ) {
                            let file = values.fileAddressProof[i];
                            if (file?.size) {
                              formData.append("name", "Address Proof");
                              formData.append("documents", file);
                              // formData.append("group_id", values.group_id);
                              formData.append("upload_type", "address_proof");
                            } else {
                              formData.append("name", file.name);
                              formData.append(
                                `documents[${file.document_id}]`,
                                ""
                              );
                              // formData.append("group_id", file.group_id);
                              formData.append("upload_type", file.upload_type);
                            }
                          }
                        }

                        formData.append("pan_number", values.panNumber);
                      }
                      if (documentType == 'foreign-citizenship') {

                        if (values.fileIdentityProof) {
                          for (
                            let i = 0;
                            i < values.fileIdentityProof.length;
                            i++
                          ) {
                            let file = values.fileIdentityProof[i];
                            if (file?.size) {
                              formData.append("name", "Identity proof");
                              formData.append("documents", file);
                              formData.append("upload_type", "identity_proof");
                            } else {
                              formData.append("name", file.name);
                              formData.append(
                                `documents[${file.document_id}]`,
                                ""
                              );
                              formData.append("upload_type", file.upload_type);
                            }
                          }
                        }

                        if (values.fileAddressProof) {
                          for (
                            let i = 0;
                            i < values.fileAddressProof.length;
                            i++
                          ) {
                            let file = values.fileAddressProof[i];
                            if (file?.size) {
                              formData.append("name", "Address Proof");
                              formData.append("documents", file);
                              // formData.append("group_id", values.group_id);
                              formData.append("upload_type", "address_proof");
                            } else {
                              formData.append("name", file.name);
                              formData.append(
                                `documents[${file.document_id}]`,
                                ""
                              );
                              // formData.append("group_id", file.group_id);
                              formData.append("upload_type", file.upload_type);
                            }
                          }
                        }
                      }
                      if (documentType == 'foreign-corporate') {
                        if (values.certificateOfIncorporation) {
                          for (
                            let i = 0;
                            i < values.certificateOfIncorporation.length;
                            i++
                          ) {
                            let file = values.certificateOfIncorporation[i];
                            if (file?.size) {
                              formData.append(
                                "name",
                                "Certificate of Incorporation"
                              );
                              formData.append("documents", file);
                              // formData.append("group_id", values.group_id);
                              formData.append(
                                "upload_type",
                                "certificate_of_incorporation"
                              );
                            } else {
                              formData.append("name", file.name);
                              formData.append(
                                `documents[${file.document_id}]`,
                                ""
                              );
                              // formData.append("group_id", file.group_id);
                              formData.append("upload_type", file.upload_type);
                            }
                          }
                        }

                        if (values.fileAddressProof) {
                          for (
                            let i = 0;
                            i < values.fileAddressProof.length;
                            i++
                          ) {
                            let file = values.fileAddressProof[i];
                            if (file?.size) {
                              formData.append("name", "Address of the Entity");
                              formData.append("documents", file);
                              // formData.append("group_id", values.group_id);
                              formData.append(
                                "upload_type",
                                "address_proof"
                              );
                            } else {
                              formData.append("name", file.name);
                              formData.append(
                                `documents[${file.document_id}]`,
                                ""
                              );
                              // formData.append("group_id", file.group_id);
                              formData.append("upload_type", file.upload_type);
                            }
                          }
                        }

                        if (values.byLawsCertificate) {
                          for (
                            let i = 0;
                            i < values.byLawsCertificate.length;
                            i++
                          ) {
                            let file = values.byLawsCertificate[i];
                            if (file?.size) {
                              formData.append("name", "By-Laws Certificate");
                              formData.append("documents", file);
                              // formData.append("group_id", values.group_id);
                              formData.append(
                                "upload_type",
                                "by_laws_certificate"
                              );
                            } else {
                              formData.append("name", file.name);
                              formData.append(
                                `documents[${file.document_id}]`,
                                ""
                              );
                              // formData.append("group_id", file.group_id);
                              formData.append("upload_type", file.upload_type);
                            }
                          }
                        }
                      }


                      // setidValidation(false);
                      formData.append("page", "4");
                      uploadDocs(
                        { payload: formData },
                        {
                          onSuccess: () => {
                            dispatch({ type: SET_PAGE, payload: 5 });
                            // navigate("/indian-citizenship/more-about-you");
                            if (checkUSerType.userType == 'indian_citizen' || checkUSerType.userType == 'huf' || checkUSerType.userType == 'foreign_citizen') {
                              navigate("/nominee-details");
                            } else {
                              navigate("/personalize-your-profile")
                            }
                          },
                        }
                      );
                    }
                    }
                  >
                    {({
                      errors,
                      touched,
                      setFieldValue,
                      initialValues,
                      values,
                    }) => (
                      <Form className="custom-form">
                        {console.log("valuesAPI", values)}
                        {documentType == 'indian-citizenship' && <div className="row">
                          {/* PAN number */}
                          <div className="col-md-12 form-group">
                            <label>
                              {" "}
                              {personalInformation.individual_type === "huf"
                                ? "HUF "
                                : ""}{" "}
                              PAN Number <span className="required"></span>{" "}
                            </label>
                            <Field
                              name="panNumber"
                              component={CustomInputComponent}
                              type="text"
                              className="form-control"
                            />
                          </div>

                          <div className="col-md-6 form-group">
                            <label>
                              Proof of Identity
                              <span className="required"></span>{" "}
                              <span className="xs-title">
                                (PAN Card, Passport, Driving License, Voter ID)
                              </span>
                            </label>
                            <div className="custom-upload">
                              <button className="custom-upload-btn">
                                Upload Identity Proof
                              </button>
                              <Field
                                name="fileIdentityProof"
                                component={FileInputComponent}
                                label="Proof of Identity"
                                desc="*Upload self attested document"
                                checkFileExixtsORNot={(fileIdentityProofDocs) => {
                                  if (
                                    fileIdentityProofDocs &&
                                    fileIdentityProofDocs.length > 0
                                  ) {
                                    setcheckValidate(false);
                                  } else {
                                    setcheckValidate(true);
                                  }
                                }}

                              />
                            </div>
                          </div>
                          <div className="col-md-6 form-group">
                            <label>
                              Address Proof<span className="required"></span>{" "}
                              <span className="xs-title">
                                (Passport, Addhar Card, Driving License)
                              </span>
                            </label>
                            <div className="custom-upload">
                              <button className="custom-upload-btn">
                                Upload Address Proof
                              </button>
                              <Field
                                name="fileAddressProof"
                                component={FileInputComponent}
                                label="Address proof"
                                desc="*Upload self attested document"
                                checkFileExixtsORNot={(fileAddressProofDocs) => {
                                  if (
                                    fileAddressProofDocs &&
                                    fileAddressProofDocs.length > 0
                                  ) {
                                    setcheckValidatefileAddressProof(false);
                                  } else {
                                    setcheckValidatefileAddressProof(true);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>}
                        {documentType == 'indian-corporate' && <div className="row">
                          <div className="col-md-6 form-group">
                            <label>
                              Certificate of Incorporation
                              <span className="required"></span>
                            </label>
                            <div className="custom-upload">
                              <button className="custom-upload-btn">
                                Upload Certificate
                              </button>
                              <Field
                                name="certificateOfIncorporation"
                                component={FileInputComponent}
                                label="Upload Certificate"
                                desc="*Upload attestes copy"
                                checkFileExixtsORNot={(certificateOfIncorporationDocs) => {
                                  if (
                                    certificateOfIncorporationDocs &&
                                    certificateOfIncorporationDocs.length > 0
                                  ) {
                                    setcheckValidateCetificate(false);
                                  } else {
                                    setcheckValidateCetificate(true);
                                  }
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-6 form-group">
                            <label>
                              MOA & AOA<span className="required"></span>
                            </label>
                            <div className="custom-upload">
                              <button className="custom-upload-btn">Upload</button>
                              <Field
                                name="moaAndAoa"
                                component={FileInputComponent}
                                label="Upload"
                                desc="*Upload attestes copy"
                                checkFileExixtsORNot={(moaAndAoa) => {
                                  if (
                                    moaAndAoa &&
                                    moaAndAoa.length > 0
                                  ) {
                                    setcheckValidateMoa(false);
                                  } else {
                                    setcheckValidateMoa(true);
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 form-group">
                            <label>
                              Registered Address of the entity
                              <span className="required"></span>
                            </label>
                            <div className="custom-upload">
                              <button className="custom-upload-btn">
                                Upload Address Proof
                              </button>
                              <Field
                                name="fileAddressProof"
                                component={FileInputComponent}
                                label="Upload Address proof"
                                desc="*Upload attestes copy"
                                checkFileExixtsORNot={(setcheckValidatefileAddressProofDocs) => {
                                  if (
                                    setcheckValidatefileAddressProofDocs &&
                                    setcheckValidatefileAddressProofDocs.length > 0
                                  ) {
                                    setcheckValidatefileAddressProof(false);
                                  } else {
                                    setcheckValidatefileAddressProof(true);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>}
                        {documentType == 'foreign-citizenship' && <div className="row">
                          <div className="col-md-6 form-group">
                            <label>
                              Proof of Identity
                              <span className="required"></span>{" "}
                              <span className="xs-title">
                                (Passport, National ID)
                              </span>
                            </label>
                            <div className="custom-upload">
                              <button className="custom-upload-btn">
                                Upload Identity Proof
                              </button>
                              <Field
                                name="fileIdentityProof"
                                component={FileInputComponent}
                                label="Proof of Identity"
                                desc="*Upload self attested document"
                                checkFileExixtsORNot={(fileIdentityProofDocs) => {
                                  if (
                                    fileIdentityProofDocs &&
                                    fileIdentityProofDocs.length > 0
                                  ) {
                                    setcheckValidate(false);
                                  } else {
                                    setcheckValidate(true);
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 form-group">
                            <label>
                              Address proof<span className="required"></span>{" "}
                              <span className="xs-title">
                                (Driving License)
                              </span>
                            </label>
                            <div className="custom-upload">
                              <button className="custom-upload-btn">
                                Upload Address Proof
                              </button>
                              <Field
                                name="fileAddressProof"
                                component={FileInputComponent}
                                label="Address proof"
                                desc="*Upload self attested document"
                                checkFileExixtsORNot={(fileAddressProofDocs) => {
                                  if (
                                    fileAddressProofDocs &&
                                    fileAddressProofDocs.length > 0
                                  ) {
                                    setcheckValidatefileAddressProof(false);
                                  } else {
                                    setcheckValidatefileAddressProof(true);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>}
                        {documentType == 'foreign-corporate' && <div className="row">
                          <div className="col-md-6 form-group">
                            <label>
                              Certificate of Incorporation
                              <span className="required"></span>
                            </label>
                            <div className="custom-upload">
                              <button className="custom-upload-btn">
                                Upload Certificate
                              </button>
                              <Field
                                name="certificateOfIncorporation"
                                component={FileInputComponent}
                                label="Proof of Identity"
                                desc="*Upload self attested document"

                                checkFileExixtsORNot={(certificateOfIncorporationDocs) => {
                                  if (
                                    certificateOfIncorporationDocs &&
                                    certificateOfIncorporationDocs.length > 0
                                  ) {
                                    setcheckValidateCetificate(false);
                                  } else {
                                    setcheckValidateCetificate(true);
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 form-group">
                            <label>
                              By-Laws Certificate
                              <span className="required"></span>
                            </label>
                            <div className="custom-upload">
                              <button className="custom-upload-btn">
                                Upload
                              </button>
                              <Field
                                name="byLawsCertificate"
                                component={FileInputComponent}
                                label="Upload"
                                desc="*Upload self attested document"
                                checkFileExixtsORNot={(byLawsCertificateDocs) => {
                                  if (
                                    byLawsCertificateDocs &&
                                    byLawsCertificateDocs.length > 0
                                  ) {
                                    setcheckValidateLaws(false);
                                  } else {
                                    setcheckValidateLaws(true);
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 form-group">
                            <label>
                              Registered Address of the Entity
                              <span className="required"></span>
                            </label>
                            <div className="custom-upload">
                              <button className="custom-upload-btn">
                                Upload Address Proof
                              </button>
                              <Field
                                name="fileAddressProof"
                                component={FileInputComponent}
                                label="Address proof"
                                desc="*Upload self attested document"
                                checkFileExixtsORNot={(fileAddressProofDocs) => {
                                  if (
                                    fileAddressProofDocs &&
                                    fileAddressProofDocs.length > 0
                                  ) {
                                    setcheckValidatefileAddressProof(false);
                                  } else {
                                    setcheckValidatefileAddressProof(true);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>}

                        {isUploadDocsLoading ? (
                          <BtnSpinner />
                        ) : (
                          <div className="mt-3 text-center">
                            <button
                              type="submit"
                              className="theme-btn btn w-25 ml-15"
                            >
                              Next
                            </button>
                          </div>
                        )}
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UploadDocuments;
