import React, { useEffect, useState } from "react";
import {
  Field,
  Form,
  FormikProvider,
  useFormik,
} from "formik";
import * as Yup from "yup";
import BtnSpinner from "../../../../utils/BtnSpinner";
import { Back } from "../../../../assets/img";
import {
  CustomInputComponent,
  CustomSelectComponent,
  CustomCheckbox,
} from "../../../../components/formik/FieldComponents";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { UPDATE_NOMINEE_DETAILS, SET_PAGE } from "../../../../store/actions";
import {
  FetchAllCountries,
  FetchAllStates,
  FetchAllCities,
  SaveQuestions,
} from "../../../../api";

import Cookies from "js-cookie";
import { toast } from "react-toastify";
import Modal from "../../../../utils/Modal";

function NomineeDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const nomineeDetails = useSelector(
    (state) => state.indianCitizenship.nomineeDetails
  );

  const { mutateAsync: saveNominee, isLoading: isSaveNomineeDetailsLoading } =
    SaveQuestions();

  const [isMinor, setIsMinor] = React.useState(false);
  const [index, setIndex] = useState(
    Boolean(nomineeDetails.minorNomineeCheckbox) ? 1 : 0
  );
  const [isNomineeAdded, setIsNomineeAdded] = useState(nomineeDetails.isNomineeAdded)
  const [showConfirmation, setShowConfirmation] = useState(false)

  const onNomineeCheckboxChanged = () => {
    setIsMinor(!isMinor);
  };

  useEffect(() => {
    if (isNomineeAdded) {
      if (isNomineeAdded == 'Yes') {
        // setShowConfirmation(false)
      } else {
        setShowConfirmation(true)
      }
    } else {
      setShowConfirmation(true)
    }
  }, [isNomineeAdded])

  const NomineeDetailsValidationSchema = {
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    dateOfBirth: Yup.date().required("Date is required"),
    addressLineOne: Yup.string().required("First name is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    postalCode: Yup.string().required("Postal code is required"),
    nomineePanNumber: Yup.string().required("Nominee PAN number is required"),
    relationshipWithInvestor: Yup.string().required(
      "Relationship with investor is required"
    ),
  };

  const GuardianDetailsValidationSchema = {
    firstNameGuardian: Yup.string().required("First name is required"),
    lastNameGuardian: Yup.string().required("Last name is required"),
    addressLineOneGuardian: Yup.string().required(
      "Address line one is required"
    ),
    countryGuardian: Yup.string().required("Country is required"),
    stateGuardian: Yup.string().required("State is required"),
    cityGuardian: Yup.string().required("City is required"),
    postalCodeGuardian: Yup.string().required("Postal code is required"),
  };

  const theValidationSchema = !isMinor
    ? NomineeDetailsValidationSchema
    : {
      ...NomineeDetailsValidationSchema,
      ...GuardianDetailsValidationSchema,
    };

  const [checkDirectNominee, setcheckDirectNominee] = React.useState(true);

  useEffect(() => {
    if (Cookies.get("redirectPath") != null) {
      const redirectPath = Cookies.get("redirectPath");
      if (redirectPath === "nominee") {
        let checkDirNominee = Cookies.get("checkNominee");
        if (checkDirNominee === "" || checkDirNominee !== "Yes") {
          toast.error("Please verify mobile otp first");
          navigate("/verify-mobile-number");
        }

        setcheckDirectNominee(false);
        setIndex(0);

        Cookies.remove("checkNominee");
        Cookies.remove("redirectPath");
      }
    }
  }, []);
  const checkNomineeMinor = (nominee_dob) => {
    let today = new Date();
    let birthDate = new Date(nominee_dob);
    let age_now = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    console.log(age_now);
    if (age_now < 18) {
      setIsMinor(true)
      formik.values.minorNomineeCheckbox = true
    } else {
      setIsMinor(false)
      formik.values.minorNomineeCheckbox = false
    }
  }

  const formik = useFormik({
    initialValues: nomineeDetails,
    enableReinitialize: true,
    validationSchema: isNomineeAdded == "Yes" ? Yup.object().shape(theValidationSchema) : Yup.object().shape({}),
    onSubmit: (values) => {
      let nomineeAddress = [];
      nomineeAddress.push({
        country_id: values.country,
        state_id: values.state,
        city_id: values.city,
        address_line_1: values.addressLineOne,
        address_line_2: values.addressLineTwo,
        zip_code: values.postalCode,
        address_type: "residential",
        user_type: "nominee",
      });
      if (values.minorNomineeCheckbox) {
        nomineeAddress.push({
          country_id: values.countryGuardian,
          state_id: values.stateGuardian,
          city_id: values.cityGuardian,
          address_line_1: values.addressLineOneGuardian,
          address_line_2: values.addressLineTwoGuardian,
          zip_code: values.postalCodeGuardian,
          address_type: "residential",
          user_type: "gaurdian",
        });
      }

      saveNominee(
        {
          payload: isNomineeAdded == "Yes" ? {
            nominee_details: {
              first_name: values.firstName,
              middle_name: values.middleName,
              last_name: values.lastName,
              dob: values.dateOfBirth,
              nominee_pan_number: values.nomineePanNumber,
              relation_of_nominee: values.relationshipWithInvestor,
              gurdian_first_name: values.firstNameGuardian,
              gurdian_last_name: values.lastNameGuardian,
            },
            address: nomineeAddress,
            page: "6",
            is_nominee_added: 'Yes',
            re_submit: false,
          } : {
            page: "6",
            is_nominee_added: 'No',
            re_submit: false,
          },
        },
        {
          onSuccess: () => {
            dispatch({
              type: UPDATE_NOMINEE_DETAILS,
              payload: {
                firstName: values.firstName,
                middleName: values.middleName,
                lastName: values.lastName,
                addressLineOne: values.addressLineOne,
                addressLineTwo: values.addressLineTwo,
                country: values.country,
                state: values.state,
                city: values.city,
                postalCode: values.postalCode,
                dateOfBirth: values.dateOfBirth,
                nomineePanNumber: values.nomineePanNumber,
                relationshipWithInvestor: values.relationshipWithInvestor,
                minorNomineeCheckbox: values.minorNomineeCheckbox,
                firstNameGuardian:
                  (values.minorNomineeCheckbox && values.firstNameGuardian) ||
                  "",
                lastNameGuardian:
                  (values.minorNomineeCheckbox && values.lastNameGuardian) ||
                  "",
                addressLineOneGuardian:
                  (values.minorNomineeCheckbox &&
                    values.addressLineOneGuardian) ||
                  "",
                addressLineTwoGuardian:
                  (values.minorNomineeCheckbox &&
                    values.addressLineTwoGuardian) ||
                  "",
                countryGuardian:
                  (values.minorNomineeCheckbox && values.countryGuardian) || "",
                stateGuardian:
                  (values.minorNomineeCheckbox && values.stateGuardian) || "",
                cityGuardian:
                  (values.minorNomineeCheckbox && values.cityGuardian) || "",
                postalCodeGuardian:
                  (values.minorNomineeCheckbox && values.postalCodeGuardian) ||
                  "",
                isNomineeAdded: isNomineeAdded,
              },
            });
            dispatch({ type: SET_PAGE, payload: 7 });
            if (checkDirectNominee) {
              navigate("/personalize-your-profile", {state: {isNomineeAdded: isNomineeAdded}});
            } else {
              navigate("/registration-status");
            }
             
          },
        }
      );
    },
  });
  const { data: countries } = FetchAllCountries();
  const { data: states } = FetchAllStates(
    formik.values?.country || ""
  );
  const { data: cities } = FetchAllCities(
    formik.values?.state || ""
  );
  const { data: states2 } = FetchAllStates(
    formik.values?.countryGuardian || ""
  );
  const { data: cities2 } = FetchAllCities(
    formik.values?.stateGuardian || ""
  );

  useEffect(() => {
    checkNomineeMinor(formik.values.dateOfBirth)
  }, [formik.values.dateOfBirth])

  return (
    <section className="mid-wrap">
      <div className="forms-header border-b pt-3 pb-3">
        <div className="container">
          {checkDirectNominee ? (
            <>
              <div className="row align-items-center">
                <div className="col-md-2 col-2">
                  {index === 0 ? (
                    <Link
                      to="/upload-documents"
                      className="back-arrow"
                    >
                      <img src={Back} alt="back" />
                    </Link>
                  ) : (
                    <div className="back-arrow cursor-pointer" onClick={() => setIndex(0)}>
                      <img src={Back} alt="back" />
                    </div>
                  )}
                </div>

                <div className="col-md-8 col-8">
                  <div className="lg-title text-center">Complete Your KYC</div>
                </div>
                <div className="col-md-2 col-2 text-end">
                  <span className="pl-15 sm-title">5/6</span>
                </div>
              </div>
              <div className="text-center step-process mt-1">
                <div className="d-inline-flex">
                  <span className="process-step active"></span>
                  <span className="process-step active"></span>
                  <span className="process-step active"></span>
                  <span className="process-step active"></span>

                  <span className="process-step selected"></span>
                  <span className="process-step"></span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row align-items-center">
                <div className="col-md-2 col-2">
                  <div className="col-md-2 col-2">
                    {index === 1 && (
                      <div className="back-arrow cursor-pointer" onClick={() => setIndex(0)}>
                        <img src={Back} alt="back" />
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-8 col-8">
                  <div className="lg-title text-center">Complete Your KYC</div>
                </div>
                <div className="col-md-2 col-2 text-end"></div>
              </div>
              <div className="text-center step-process mt-1"></div>
            </>
          )}

          {index === 0 && (
            <>
              <h5 className="card-title md-title mt-2 mb-0 text-center heading-formobile">
                Nominee Details
              </h5>
            </>
          )}
          {index === 1 && formik.values.minorNomineeCheckbox && (
            <h5 className="card-title md-title mt-2 mb-0 text-center heading-formobile">
              Nominee Guardian Details
            </h5>
          )}
        </div>
      </div>
      <div className="pt-0 pb-5 kyc-form-block">
        <div className="container">
          <div className="card form-card mt-4">
            <div className="card-body">
              <FormikProvider value={formik} sx={{ flex: 1 }}>
                <Form className="custom-form" onSubmit={formik.handleSubmit}>
                  {index === 0 && (
                    <>
                      <h5 className="card-title md-title mb-4 text-center heading-fordesktop">
                        Nominee Details
                      </h5>
                      <div className="row">
                        {/* First Name */}
                        <div className="col-md-6 form-group">
                          <label className="required">Nominee First Name</label>
                          <Field
                            name="firstName"
                            component={CustomInputComponent}
                            type="text"
                            className="form-control"
                          />
                        </div>

                        {/* Middle Name */}
                        <div className="col-md-6 form-group">
                          <label>Nominee Middle Name</label>
                          <Field
                            name="middleName"
                            component={CustomInputComponent}
                            type="text"
                            className="form-control"
                          />
                        </div>

                        {/* Last Name */}
                        <div className="col-md-6 form-group">
                          <label className="required">Nominee Last Name</label>
                          <Field
                            name="lastName"
                            component={CustomInputComponent}
                            type="text"
                            className="form-control"
                          />
                        </div>

                        {/* Date of Birth */}
                        <div className="col-md-6 form-group">
                          <label className="required">Date of Birth</label>
                          <Field
                            name="dateOfBirth"
                            component={CustomInputComponent}
                            type="date"
                            className="form-control"
                          />
                        </div>

                        {/* Address line one */}
                        <div className="col-md-6 form-group">
                          <label className="required">Address Line 1</label>
                          <Field
                            name="addressLineOne"
                            component={CustomInputComponent}
                            type="text"
                            className="form-control"
                          />
                        </div>

                        {/* Address line two */}
                        <div className="col-md-6 form-group">
                          <label>Address Line 2</label>
                          <Field
                            name="addressLineTwo"
                            component={CustomInputComponent}
                            type="text"
                            className="form-control"
                          />
                        </div>

                        {/* Country */}
                        <div className="col-md-6 form-group">
                          <label className="required">Country</label>
                          <Field
                            name="country"
                            component={CustomSelectComponent}
                            options={countries}
                            className="form-control"
                          />
                        </div>

                        {/* State */}
                        <div className="col-md-6 form-group">
                          <label className="required">State</label>
                          <Field
                            name="state"
                            component={CustomSelectComponent}
                            options={states}
                            className="form-control"
                          />
                        </div>

                        {/* City */}
                        <div className="col-md-6 form-group">
                          <label className="required">City</label>
                          <Field
                            name="city"
                            component={CustomSelectComponent}
                            options={cities}
                            className="form-control"
                          />
                        </div>

                        {/* Postal code */}
                        <div className="col-md-6 form-group">
                          <label className="required">Zip / Postal Code</label>
                          <Field
                            name="postalCode"
                            component={CustomInputComponent}
                            type="text"
                            className="form-control"
                          />
                        </div>

                        {/* Nominee PAN number */}
                        <div className="col-md-6 form-group">
                          <label className="required">Nominee PAN Number</label>
                          <Field
                            name="nomineePanNumber"
                            component={CustomInputComponent}
                            type="text"
                            className="form-control"
                          />
                          <div className="xs-title text-start mt-1">
                            *if nominee is minor mention Aadhar Card Number
                          </div>
                        </div>

                        {/* Relationship with investor */}
                        <div className="col-md-6 form-group">
                          <label className="required">
                            Relationship With Investor
                          </label>
                          <Field
                            name="relationshipWithInvestor"
                            component={CustomInputComponent}
                            type="text"
                            className="form-control"
                          />
                        </div>

                        {/* Is the nominee minor? */}
                        <div className="col-12 form-group">
                          <Field
                            name="minorNomineeCheckbox"
                            component={CustomCheckbox}
                            label="Nominee is minor?"
                            onChange={onNomineeCheckboxChanged}
                            className="react-checkbox"
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {index === 1 && formik.values.minorNomineeCheckbox && (
                    <>
                      <h5 className="card-title md-title  mt-4 mb-4 text-center heading-fordesktop">
                        Nominee Guardian Details
                      </h5>
                      <div className="row">
                        {/* First Name Guardian */}
                        <div className="col-md-6 form-group">
                          <label className="required">
                            Guardian First Name
                          </label>
                          <Field
                            name="firstNameGuardian"
                            component={CustomInputComponent}
                            type="text"
                            className="form-control"
                          />
                        </div>

                        {/* Last Name Guardian */}
                        <div className="col-md-6 form-group">
                          <label className="required">Guardian Last Name</label>
                          <Field
                            name="lastNameGuardian"
                            component={CustomInputComponent}
                            type="text"
                            className="form-control"
                          />
                        </div>

                        {/* Address line one guardian */}
                        <div className="col-md-6 form-group">
                          <label className="required">Address Line 1</label>
                          <Field
                            name="addressLineOneGuardian"
                            component={CustomInputComponent}
                            type="text"
                            className="form-control"
                          />
                        </div>

                        {/* Address line two guardian */}
                        <div className="col-md-6 form-group">
                          <label>Address Line 2</label>
                          <Field
                            name="addressLineTwoGuardian"
                            component={CustomInputComponent}
                            type="text"
                            className="form-control"
                          />
                        </div>

                        {/* Country Guardian */}
                        <div className="col-md-6 form-group">
                          <label className="required">Country</label>
                          <Field
                            name="countryGuardian"
                            component={CustomSelectComponent}
                            options={countries}
                            className="form-control"
                          />
                        </div>

                        {/* State guardian */}
                        <div className="col-md-6 form-group">
                          <label className="required">State</label>
                          <Field
                            name="stateGuardian"
                            component={CustomSelectComponent}
                            options={states2}
                            className="form-control"
                          />
                        </div>

                        {/* City Guardian */}
                        <div className="col-md-6 form-group">
                          <label className="required">City</label>
                          <Field
                            name="cityGuardian"
                            component={CustomSelectComponent}
                            options={cities2}
                            className="form-control"
                          />
                        </div>

                        {/* Postal code guardian */}
                        <div className="col-md-6 form-group">
                          <label className="required">Zip / Postal Code</label>
                          <Field
                            name="postalCodeGuardian"
                            component={CustomInputComponent}
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {isSaveNomineeDetailsLoading ? (
                    <BtnSpinner />
                  ) : (
                    <div className="mt-3 text-center">
                      {/* {checkDirectNominee && (
                        <button
                          type="submit"
                          className="gray-btn btn idil-btn"
                          onClick={() => {
                            dispatch({ type: SET_PAGE, payload: 4 });
                            navigate(
                              "/indian-citizenship/personalize-your-profile"
                            );
                          }}
                        >
                          I'LL DO IT LATER
                        </button>
                      )} */}
                      {index === 0 && formik.values.minorNomineeCheckbox ? (
                        <button
                          type="button"
                          onClick={() => setIndex(1)}
                          className="theme-btn btn w-25 ml-15"
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="theme-btn btn w-25 ml-15"
                          onClick={() => formik.submitForm()}
                        >
                          {checkDirectNominee ? "Next" : "Submit"}
                        </button>
                      )}
                    </div>
                  )}
                </Form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </div>
      <Modal
        commonModalTitle={"Nominee Details"}
        commonModalShow={showConfirmation}
        commonModalData={
          <>
            <div className='d-flex align-items-center justify-content-between mb-4'>
              <h5 className='md-title mb-0' id='documentModalLabel'>Nominee Details</h5>
            </div>
            <div className='d-flex align-items-center justify-content-between mb-4'>
              <div className='md-title mb-0' id='documentModalLabel'>Do you want to add Nominee details ?</div>
            </div>
            <div className="text-center row">
              <div className="col">
                <button
                  className={`red-btn w-100 ${isNomineeAdded === "No" && "is-not-interested"}`}
                  onClick={() => {
                    setIsNomineeAdded("No")
                  }}
                >
                  NO
                </button>
              </div>

              <div className="col">
                <button
                  className={`green-btn w-100 ${isNomineeAdded === "Yes" && "is-interested"}`}
                  onClick={() => {
                    setIsNomineeAdded("Yes")
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
            <div className="text-center mt-5">
              <button type="button" className="theme-btn w-100 px-4 terms-accept-btn" onClick={() => { isNomineeAdded == "Yes" ? setShowConfirmation(false) : formik.submitForm() }}>Next</button>
            </div>
          </>
        }
      />
    </section>
  );
}

export default NomineeDetails;
