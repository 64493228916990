import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import DashboardLayout from '../../views/main/deals/DashboardLayout'
import { clearGetFeedbacksList, getFeedbacksList } from '../../store/actions/getFeedbacksListAction'
import { formateDateWithTimeChat } from '../../utils/utils'
import backArrow from '../../assets/img/back.svg'
import noListingImg from '../../assets/img/no-listing-found.png'

export default function FeedbackList() {

    const { state } = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [feedbacks, setFeedbacks] = useState([])

    const getFeedbacksListResponse = useSelector(state => state.getFeedbacksListReducer.data);

    console.log("state", state)

    useEffect(() => {
        dispatch(getFeedbacksList({ page: 1, master_feedback_category_id: state?._id }))
    }, [])

    useEffect(() => {
        if (getFeedbacksListResponse != null) {
            if (getFeedbacksListResponse.data != null) {
                setFeedbacks(getFeedbacksListResponse?.data)
                dispatch(clearGetFeedbacksList());
            }
        }
    }, [getFeedbacksListResponse]);

    const singularize = (word) => {
        const endings = {
            ves: 'fe',
            ies: 'y',
            i: 'us',
            zes: 'ze',
            ses: 's',
            es: 'e',
            s: ''
        };
        return word.replace(
            new RegExp(`(${Object.keys(endings).join('|')})$`),
            r => endings[r]
        );
    }

    return (
        <div>
            <div className='header-mobile d-md-none border-b'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div>
                        <Link className="back-btn blue-text d-block" to="/rate-and-feedback">
                            <img src={backArrow} alt="arrow" /> {state?.values[0]?.name}
                        </Link>
                    </div>
                </div>
            </div>
            <div className='addround-header header-desktop'>
                <div className='container'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div>
                            <div class="md-title text-start fw-700 cursor-pointer d-flex align-items-center">
                                <button class="back-btn" type="button" onClick={() => { navigate("/rate-and-feedback") }}><img src={backArrow} alt="arrow" className='d-inline-block' /></button>
                                <div className='pt-1 blue-text'>{state?.values[0]?.name}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-4 pb-4 ps-5 pe-5">
                <div className='favourite-list'>
                    {
                        feedbacks.length > 0 ?
                            feedbacks.map((item) => (
                                <li className="p-0" key={item._id}>
                                    <div className="d-flex align-items-center justify-content-between favourite-box pt-3 pb-3 cursor-pointer" onClick={() => {navigate("/feedback-detail", {state: {category: state, feedback_id: item._id}})}}>
                                        <div className="circle-box">
                                            <img
                                                src={item.icon}
                                                alt=""
                                            />
                                        </div>
                                        <div className="ml-15 d-flex align-items-end justify-content-between" style={{ width: "calc(100% - 60px)" }}>
                                            <div>
                                                <div className="md-title mb-6">
                                                    {item.message}
                                                </div>
                                                <div className="sm-title font-0-8em text-start">
                                                    <span className="black-text">{formateDateWithTimeChat(item.createdAt)}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='transparent-btn text-decoration-underline'>{item.status}</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))
                            :
                            <div className="no-listing-card text-center pt-4">
                                <img src={noListingImg} alt="no list" />
                                <div className="xs-title text-center mt-4 text-lgray">No feedback found</div>
                            </div>
                    }
                    <div className="mt-3 text-center">
                        <button type="button" className="theme-btn btn w-25" onClick={() => {navigate("/rate-and-feedback?add=" + state?.values[0]?.name)}}>
                            {"Add new " + singularize(state?.values[0]?.name)}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
