import { types } from './types';

export function getPortfolio(data) {
  return {
    type: types.SEND_REQUEST_GET_PORTFOLIO,
    payload: data,
  };
}

export function getPortfolioSuccess(data) {
  return {
    type: types.SEND_REQUEST_GET_PORTFOLIO_SUCCESS,
    payload: data,
  };
}

export function getPortfolioFailure(error) {
  return {
    type: types.SEND_REQUEST_GET_PORTFOLIO_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearGetPortfolio() {
  return {
    type: types.CLEAR_REQUEST_GET_PORTFOLIO,
    payload: {},
  };
}
