import {types} from './types';

export function saveMyBackrrInterest(data) {
  return {
    type: types.SEND_REQUEST_SAVE_MY_BACKRR_INTEREST,
    payload: data,
  };
}

export function saveMyBackrrInterestSuccess(data) {
  return {
    type: types.SEND_REQUEST_SAVE_MY_BACKRR_INTEREST_SUCCESS,
    payload: data,
  };
}

export function saveMyBackrrInterestFailure(error) {
  return {
    type: types.SEND_REQUEST_SAVE_MY_BACKRR_INTEREST_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearSaveMyBackrrInterest() {
  return {
    type: types.CLEAR_REQUEST_SAVE_MY_BACKRR_INTEREST,
    payload: {},
  };
}
