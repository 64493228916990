import { types } from './types';

export function getMyAllBackrrsPortfolio(data) {
  return {
    type: types.SEND_REQUEST_GET_MY_ALL_BACKRR_PORTFOLIO,
    payload: data,
  };
}

export function getMyAllBackrrsPortfolioSuccess(data) {
  return {
    type: types.SEND_REQUEST_GET_MY_ALL_BACKRR_PORTFOLIO_SUCCESS,
    payload: data,
  };
}

export function getMyAllBackrrsPortfolioFailure(error) {
  return {
    type: types.SEND_REQUEST_GET_MY_ALL_BACKRR_PORTFOLIO_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearGetMyAllBackrrsPortfolio() {
  return {
    type: types.CLEAR_REQUEST_GET_MY_ALL_BACKRR_PORTFOLIO,
    payload: {},
  };
}
