import moment from "moment";
import { Backrr } from "../../assets/img";

const Footer = () => {
  return (
    <footer className="footer-wrap">
      <div className="container">
        &copy; {moment().format("Y")} Backrr. All Rights Reserved. &nbsp;
        <img className="realtime-logo" src={Backrr} alt="realtime" />
      </div>
    </footer>
  );
};

export default Footer;
