import React, { useEffect, useState } from "react";
import DashboardLayout from '../../deals/DashboardLayout'
import {
  Field,
  Form,
  FormikProvider,
  useFormik,
} from "formik";
import * as Yup from "yup";
import BtnSpinner from "../../../../utils/BtnSpinner";
import {
  CustomInputComponent,
  CustomSelectComponent,
  CustomCheckbox,
} from "../../../../components/formik/FieldComponents";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  UPDATE_RESIDENTIAL_ADDRESS, UPDATE_RESIDENTIAL_ADDRESS_IC, UPDATE_RESIDENTIAL_ADDRESS_HUF, UPDATE_RESIDENTIAL_ADDRESS_FC, UPDATE_RESIDENTIAL_ADDRESS_FCorp
} from "../../../../store/actions";
import {
  SaveAddress,
  FetchAllCountries,
  FetchAllStates,
  FetchAllCities,
  GetUserDetailsUpdated,
} from "../../../../api";
import backArrow from '../../../../assets/img/back.svg'

const PermanentAddress = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { mutateAsync: saveAddress, isLoading: isSaveAddressLoading } =
    SaveAddress();
  const [is_editable, setIsEditable] = useState(null);
  const [edit_access, setEditAccess] = useState(true);
  const [address, setAddress] = useState(null)

  const residentialAddress = useSelector(
    (state) => state.indianCitizenship.residentialAddress
  );
  const residentialAddressIc = useSelector(
    (state) => state.indianCorporate.residentialAddress
  );
  const residentialAddressHuf = useSelector(
    (state) => state.indianhuf.residentialAddress
  );
  const residentialAddressFC = useSelector(
    (state) => state.foreignCitizenship.residentialAddress
  );
  const residentialAddressFCorp = useSelector(
    (state) => state.foreignCorporate.residentialAddress
  );
  const { data: userData, isLoading: isDocsLoading } = GetUserDetailsUpdated();

  const [isCurrentAddressDifferent, setIsCurrentAddressDifferent] =
    useState(false);

  const [index, setIndex] = useState(0);

  useEffect(() => {
    setIsEditable(userData?.kyc_editable)
  }, [isDocsLoading])

  useEffect(() => {
    if (userData != null) {
      if (userData.data != null) {
        let data = userData;
        console.log("UserdATA", data)
        if (data?.addresses?.length > 0) {
          let addresses = data?.addresses;
          let residentialAddresses = addresses?.filter(item => { return item.address_type == 'residential' });
          let currentAddresses = addresses?.filter(item => { return item.address_type == 'current' });
          let current = null;
          let residential = null;
          if (currentAddresses?.length > 0) {
            let currentInvestor = currentAddresses?.filter(item => { return item.user_type == 'investor' })
            if (currentInvestor?.length > 0) {
              current = currentInvestor[currentInvestor.length - 1];
            }
          }
          if (residentialAddresses?.length > 0) {
            let currentInvestor = residentialAddresses?.filter(item => { return item.user_type == 'investor' })
            if (currentInvestor?.length > 0) {
              residential = currentInvestor[currentInvestor.length - 1];
            }
          }
          if (current != null) {
            setIsCurrentAddressDifferent(true);
          }

          if (checkUSerType.userType == "indian_citizen") {
            dispatch({
              type: UPDATE_RESIDENTIAL_ADDRESS,
              payload: {
                addressLineOne: residential?.address_line_1,
                addressLineTwo: residential?.address_line_2,
                country: residential?.country_id,
                state: residential?.state_id,
                city: residential?.city_id,
                postalCode: residential?.zip_code,
                currentAddressDifferentCheckbox: current != null,
                addressLineOneCurrent: current?.address_line_1,
                addressLineTwoCurrent: current?.address_line_2,
                countryCurrent: current?.country_id,
                stateCurrent: current?.state_id,
                cityCurrent: current?.city_id,
                postalCodeCurrent: current?.zip_code,
              },
            });
          } else if (checkUSerType.userType == "indian_corporate") {

            dispatch({
              type: UPDATE_RESIDENTIAL_ADDRESS_IC,
              payload: {
                addressLineOne: residential?.address_line_1,
                addressLineTwo: residential?.address_line_2,
                country: residential?.country_id,
                state: residential?.state_id,
                city: residential?.city_id,
                postalCode: residential?.zip_code,
                currentAddressDifferentCheckbox: current != null,
                addressLineOneCurrent: current?.address_line_1,
                addressLineTwoCurrent: current?.address_line_2,
                countryCurrent: current?.country_id,
                stateCurrent: current?.state_id,
                cityCurrent: current?.city_id,
                postalCodeCurrent: current?.zip_code,
              },
            });
          } else if (checkUSerType.userType == "huf") {
            dispatch({
              type: UPDATE_RESIDENTIAL_ADDRESS_HUF,
              payload: {
                addressLineOne: residential?.address_line_1,
                addressLineTwo: residential?.address_line_2,
                country: residential?.country_id,
                state: residential?.state_id,
                city: residential?.city_id,
                postalCode: residential?.zip_code,
                currentAddressDifferentCheckbox: current != null,
                addressLineOneCurrent: current?.address_line_1,
                addressLineTwoCurrent: current?.address_line_2,
                countryCurrent: current?.country_id,
                stateCurrent: current?.state_id,
                cityCurrent: current?.city_id,
                postalCodeCurrent: current?.zip_code,
              },
            });
          } else if (checkUSerType.userType == "foreign_citizen") {
            dispatch({
              type: UPDATE_RESIDENTIAL_ADDRESS_FC,
              payload: {
                addressLineOne: residential?.address_line_1,
                addressLineTwo: residential?.address_line_2,
                country: residential?.country_id,
                state: residential?.state_id,
                city: residential?.city_id,
                postalCode: residential?.zip_code,
                currentAddressDifferentCheckbox: current != null,
                addressLineOneCurrent: current?.address_line_1,
                addressLineTwoCurrent: current?.address_line_2,
                countryCurrent: current?.country_id,
                stateCurrent: current?.state_id,
                cityCurrent: current?.city_id,
                postalCodeCurrent: current?.zip_code,
              },
            });
          } else {
            dispatch({
              type: UPDATE_RESIDENTIAL_ADDRESS_FCorp,
              payload: {
                addressLineOne: residential?.address_line_1,
                addressLineTwo: residential?.address_line_2,
                country: residential?.country_id,
                state: residential?.state_id,
                city: residential?.city_id,
                postalCode: residential?.zip_code,
                currentAddressDifferentCheckbox: current != null,
                addressLineOneCurrent: current?.address_line_1,
                addressLineTwoCurrent: current?.address_line_2,
                countryCurrent: current?.country_id,
                stateCurrent: current?.state_id,
                cityCurrent: current?.city_id,
                postalCodeCurrent: current?.zip_code,
              },
            });
          }
        }
      }
    }
  }, [userData]);

  useEffect(() => {
    if (checkUSerType.userType == "indian_citizen") {
      setAddress(residentialAddress)
    } else if (checkUSerType.userType == "indian_corporate") {
      setAddress(residentialAddressIc)
    } else if (checkUSerType.userType == "huf") {
      setAddress(residentialAddressHuf)
    } else if (checkUSerType.userType == "foreign_citizen") {
      setAddress(residentialAddressFC)
    } else {
      setAddress(residentialAddressFCorp)
    }
  }, [residentialAddress, residentialAddressIc, residentialAddressHuf, residentialAddressFC, residentialAddressFCorp])

  const toggleEdit = () => {
    setEditAccess(edit_access => !edit_access);
  };

  useEffect(() => {
    if (searchParams.has("editable")) {
      setEditAccess(searchParams.get("editable") == 'true')
    }
  }, [searchParams])

  const checkUSerType = useSelector((state) => state.userDetails);

  const onCurrentAddressDifferentChecked = () => {
    setIsCurrentAddressDifferent(!isCurrentAddressDifferent);
    console.log(isCurrentAddressDifferent);
  };

  const ResidentialAddressValidationSchema = {
    addressLineOne: Yup.string().required("Address line one is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    postalCode: Yup.string().required("Postal code is required"),
  };

  const CurrentAddressValidationSchema = {
    addressLineOneCurrent: Yup.string().required("Address line one is required"),
    countryCurrent: Yup.string().required("Country is required"),
    stateCurrent: Yup.string().required("State is required"),
    cityCurrent: Yup.string().required("City is required"),
    postalCodeCurrent: Yup.string().required("Postal code is required"),
  };

  const theValidationSchema = !isCurrentAddressDifferent
    ? ResidentialAddressValidationSchema
    : {
      ...ResidentialAddressValidationSchema,
      ...CurrentAddressValidationSchema,
    };

  const formik = useFormik({
    initialValues: address,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(theValidationSchema),
    onSubmit: (values) => {
      saveAddress(
        {
          payload: {
            page: "2",
            re_submit: false,
            address: Boolean(values.currentAddressDifferentCheckbox)
              ? [
                {
                  address_line_1: values.addressLineOne,
                  address_line_2: values.addressLineTwo,
                  country_id: values.country,
                  state_id: values.state,
                  city_id: values.city,
                  zip_code: values.postalCode,
                  address_type: "residential",
                  user_type: "investor",
                },
                {
                  address_line_1: values.addressLineOneCurrent,
                  address_line_2: values.addressLineTwoCurrent,
                  country_id: values.countryCurrent,
                  state_id: values.stateCurrent,
                  city_id: values.cityCurrent,
                  zip_code: values.postalCodeCurrent,
                  address_type: "current",
                  user_type: "investor",
                },
              ]
              : [
                {
                  address_line_1: values.addressLineOne,
                  address_line_2: values.addressLineTwo,
                  country_id: values.country,
                  state_id: values.state,
                  city_id: values.city,
                  zip_code: values.postalCode,
                  address_type: "residential",
                  user_type: "investor",
                },
              ],
          },
        },
        {
          onSuccess: () => {

            if (checkUSerType.userType == "indian_citizen") {
              dispatch({
                type: UPDATE_RESIDENTIAL_ADDRESS,
                payload: {
                  addressLineOne: values.addressLineOne,
                  addressLineTwo: values.addressLineTwo,
                  country: values.country,
                  state: values.state,
                  city: values.city,
                  postalCode: values.postalCode,
                  currentAddressDifferentCheckbox:
                    values.currentAddressDifferentCheckbox,
                  addressLineOneCurrent:
                    (values.currentAddressDifferentCheckbox &&
                      values.addressLineOneCurrent) ||
                    "",
                  addressLineTwoCurrent:
                    (values.currentAddressDifferentCheckbox &&
                      values.addressLineTwoCurrent) ||
                    "",
                  countryCurrent:
                    (values.currentAddressDifferentCheckbox &&
                      values.countryCurrent) ||
                    "",
                  stateCurrent:
                    (values.currentAddressDifferentCheckbox &&
                      values.stateCurrent) ||
                    "",
                  cityCurrent:
                    (values.currentAddressDifferentCheckbox &&
                      values.cityCurrent) ||
                    "",
                  postalCodeCurrent:
                    (values.currentAddressDifferentCheckbox &&
                      values.postalCodeCurrent) ||
                    "",
                },
              });
            } else if (checkUSerType.userType == "indian_corporate") {

              dispatch({
                type: UPDATE_RESIDENTIAL_ADDRESS_IC,
                payload: {
                  addressLineOne: values.addressLineOne,
                  addressLineTwo: values.addressLineTwo,
                  country: values.country,
                  state: values.state,
                  city: values.city,
                  postalCode: values.postalCode,
                  currentAddressDifferentCheckbox:
                    values.currentAddressDifferentCheckbox,
                  addressLineOneCurrent: values.addressLineOneCurrent,
                  addressLineTwoCurrent: values.addressLineTwoCurrent,
                  countryCurrent: values.countryCurrent,
                  stateCurrent: values.stateCurrent,
                  cityCurrent: values.cityCurrent,
                  postalCodeCurrent: values.postalCodeCurrent,
                },
              });
            } else if (checkUSerType.userType == "huf") {
              dispatch({
                type: UPDATE_RESIDENTIAL_ADDRESS_HUF,
                payload: {
                  addressLineOne: values.addressLineOne,
                  addressLineTwo: values.addressLineTwo,
                  country: values.country,
                  state: values.state,
                  city: values.city,
                  postalCode: values.postalCode,
                  currentAddressDifferentCheckbox:
                    values.currentAddressDifferentCheckbox,
                  addressLineOneCurrent:
                    (values.currentAddressDifferentCheckbox &&
                      values.addressLineOneCurrent) ||
                    "",
                  addressLineTwoCurrent:
                    (values.currentAddressDifferentCheckbox &&
                      values.addressLineTwoCurrent) ||
                    "",
                  countryCurrent:
                    (values.currentAddressDifferentCheckbox &&
                      values.countryCurrent) ||
                    "",
                  stateCurrent:
                    (values.currentAddressDifferentCheckbox &&
                      values.stateCurrent) ||
                    "",
                  cityCurrent:
                    (values.currentAddressDifferentCheckbox &&
                      values.cityCurrent) ||
                    "",
                  postalCodeCurrent:
                    (values.currentAddressDifferentCheckbox &&
                      values.postalCodeCurrent) ||
                    "",
                },
              });
            } else if (checkUSerType.userType == "foreign_citizen") {
              dispatch({
                type: UPDATE_RESIDENTIAL_ADDRESS_FC,
                payload: {
                  addressLineOne: values.addressLineOne,
                  addressLineTwo: values.addressLineTwo,
                  country: values.country,
                  state: values.state,
                  city: values.city,
                  postalCode: values.postalCode,
                  currentAddressDifferentCheckbox:
                    values.currentAddressDifferentCheckbox,
                  addressLineOneCurrent: values.addressLineOneCurrent,
                  addressLineTwoCurrent: values.addressLineTwoCurrent,
                  countryCurrent: values.countryCurrent,
                  stateCurrent: values.stateCurrent,
                  cityCurrent: values.cityCurrent,
                  postalCodeCurrent: values.postalCodeCurrent,
                },
              });
            } else {
              dispatch({
                type: UPDATE_RESIDENTIAL_ADDRESS_FCorp,
                payload: {
                  addressLineOne: values.addressLineOne,
                  addressLineTwo: values.addressLineTwo,
                  country: values.country,
                  state: values.state,
                  city: values.city,
                  postalCode: values.postalCode,
                  currentAddressDifferentCheckbox:
                    values.currentAddressDifferentCheckbox,
                  addressLineOneCurrent: values.addressLineOneCurrent,
                  addressLineTwoCurrent: values.addressLineTwoCurrent,
                  countryCurrent: values.countryCurrent,
                  stateCurrent: values.stateCurrent,
                  cityCurrent: values.cityCurrent,
                  postalCodeCurrent: values.postalCodeCurrent,
                },
              });
            }
            navigate("/my-profile/bank-details?editable=" + edit_access);
          },
        }
      );
    },
  });

  const { data: countries, isLoading: isCountryLoading } = FetchAllCountries();
  const { data: states, isLoading: isStateLoading } = FetchAllStates(
    formik.values?.country || ""
  );
  const { data: cities, isLoading: isCityLoading } = FetchAllCities(
    formik.values?.state || ""
  );
  const { data: states2, isLoading: isStateLoading2 } = FetchAllStates(
    formik.values?.countryCurrent || ""
  );
  const { data: cities2, isLoading: isCityLoading2 } = FetchAllCities(
    formik.values?.stateCurrent || ""
  );





  return (
    <>
      <DashboardLayout>
        <div className='header-mobile d-md-none border-b'>
          <div className='d-flex align-items-center justify-content-between'>
            <div>
              <Link className="back-btn blue-text d-block" to={"/my-profile/personal-information?editable=" + edit_access}>
                <img src={backArrow} alt="arrow" /> KYC Details
              </Link>
            </div>
            {is_editable && <div>
              {console.log("edit_access", edit_access)}
              <button type="button" onClick={toggleEdit} className='theme-btn btn btn-sm' >{edit_access ? 'Edit' : 'Cancel'}</button>
            </div>}
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-between cr-heading header-desktop'>
          <div className="md-title text-start fw-700">
            <button className='back-btn' onClick={() => index === 1 ? setIndex(0) : navigate("/my-profile/personal-information?editable=" + edit_access)} type='button'><img src={backArrow} alt='arrow' /></button>   KYC Details
          </div>

          {is_editable && <div>
            {console.log("edit_access", edit_access)}
            <button type="button" onClick={toggleEdit} className='theme-btn btn btn-sm' >{edit_access ? 'Edit' : 'Cancel'}</button>
          </div>}

        </div>
        <div className="pt-4 pb-4 ps-5 pe-5">
          <FormikProvider value={formik} sx={{ flex: 1 }}>
            <Form className="custom-form" onSubmit={formik.handleSubmit}>
              <div className="row">
                {/* Address line one */}
                {index === 0 && (
                  <>
                    <h5 className="card-title md-title mb-4 text-center fw-700">
                      Permanent Address
                    </h5>
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Address line 1</label>
                      <Field
                        name="addressLineOne"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Address line two */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label>Address line 2</label>
                      <Field
                        name="addressLineTwo"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Country */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Country</label>
                      <Field
                        name="country"
                        component={CustomSelectComponent}
                        options={countries}
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* State */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">State</label>
                      <Field
                        name="state"
                        component={CustomSelectComponent}
                        options={states}
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* City */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">City</label>
                      <Field
                        name="city"
                        component={CustomSelectComponent}
                        options={cities}
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Postal code */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className="required">Zip / Postal Code</label>
                      <Field
                        name="postalCode"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>

                    {/* Is the current address different? */}
                    <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <div className="react-checkbox">
                        <Field
                          name="currentAddressDifferentCheckbox"
                          component={CustomCheckbox}
                          label="Current address is different"
                          onChange={onCurrentAddressDifferentChecked}
                          disabled={edit_access}
                          className="react-checkbox"
                        />
                      </div>
                    </div>
                  </>
                )}
                {index === 1 &&
                  formik?.values?.currentAddressDifferentCheckbox && (
                    <>
                      <h5 className="card-title md-title mb-4 text-center  fw-700">
                        Current Address
                      </h5>
                      {/* Address line one */}
                      <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                        <label className="required">Address line 1</label>
                        <Field
                          name="addressLineOneCurrent"
                          component={CustomInputComponent}
                          type="text"
                          disabled={edit_access}
                          className="form-control"

                        />
                      </div>

                      {/* Address line two */}
                      <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                        <label>Address line 2</label>
                        <Field
                          name="addressLineTwoCurrent"
                          component={CustomInputComponent}
                          type="text"
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* Country */}
                      <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                        <label className="required">Country</label>
                        <Field
                          name="countryCurrent"
                          component={CustomSelectComponent}
                          options={countries}
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* State */}
                      <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                        <label className="required">State</label>
                        <Field
                          name="stateCurrent"
                          component={CustomSelectComponent}
                          options={states2}
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* City */}
                      <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                        <label className="required">City</label>
                        <Field
                          name="cityCurrent"
                          component={CustomSelectComponent}
                          options={cities2}
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* Postal code */}
                      <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                        <label className="required">
                          Zip / Postal Code
                        </label>
                        <Field
                          name="postalCodeCurrent"
                          component={CustomInputComponent}
                          type="text"
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>
                    </>
                  )}
              </div>
              {isSaveAddressLoading ? (
                <BtnSpinner />
              ) : (
                <div className="mt-3 text-center">
                  {/* <button
                        className="gray-btn btn idil-btn"
                        onClick={() => {
                          dispatch({ type: SET_PAGE, payload: 4 });
                          navigate("/indian-citizenship/personalize-your-profile");
                        }}
                      >
                        I'LL DO IT LATER
                      </button> */}

                  {edit_access == false ? index === 0 &&
                    formik?.values?.currentAddressDifferentCheckbox ? (
                    <button
                      type="button"
                      onClick={() => setIndex(1)}
                      className="theme-btn btn w-25 ml-15"
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="theme-btn btn w-25 ml-15"
                      onClick={() => formik.submitForm()}
                    >
                      Next
                    </button>
                  ) : index === 0 &&
                    formik?.values?.currentAddressDifferentCheckbox ? (
                    <div className="mt-3 text-center">
                      <button
                        type="button"
                        onClick={() => setIndex(1)}
                        className="theme-btn btn w-25 ml-15"
                      >
                        Next
                      </button>
                    </div>) : (
                    <div className="mt-3 text-center">
                      <button type="button" onClick={() => navigate("/my-profile/bank-details?editable=" + edit_access)} className="theme-btn btn w-25">
                        Next
                      </button>
                    </div>
                  )
                  }


                </div>
              )}
            </Form>
          </FormikProvider>
        </div>
      </DashboardLayout>
    </>
  )
}

export default PermanentAddress