import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import avtarImg from "../../assets/img/dummy-profile.png";
import hamIcon from "../../assets/img/ham-icon.svg";
import crossIcon from "../../assets/img/close-icon.svg";
import { useEffect, useState } from "react";
import DealLeaftMenu from "../../views/main/deals/DealLeaftMenu";
import backrrLogo from '../../assets/img/backrr-home-logo.png'
import { useDispatch, useSelector } from "react-redux";
import { saveProfileImage } from "../../store/actions/saveProfileImageAction";
import { kycPaths } from "../../utils/utils";

// window.location.hostname

const Header = ({ getDomainDetails }) => {
  // console.log("hhh", getDomainDetails);

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const profileImage = useSelector(state => state.saveProfileImageReducers.data)

  const [udActive, setUdActive] = useState(false);
  const [profile, setProfile] = useState(null);
  const [profileSrc, setProfileSrc] = useState(avtarImg)

  let loggdenInClass = "";
  if (Cookies.get("jwtToken") === "" && Cookies.get("jwtToken") === undefined) {
    loggdenInClass = "logo-login-class";
  }

  const uDropdown = () => {
    setUdActive(!udActive);
  };

  const [hamActive, SetHamActive] = useState(false);
  const hamButton = () => {
    SetHamActive(!hamActive);
  };
  const closeHmButton = () => {
    SetHamActive(false);
  };

  const userDetail = Cookies.get("userDetail")
    ? JSON.parse(Cookies.get("userDetail"))
    : {};

  console.log("userDetail", userDetail)

  const profileDetail = Cookies.get("profileImage")

  useEffect(() => {
    if (profileImage != null) {
      setProfile(profileImage)
    } else {
      setProfile(Cookies.get("profileImage"))
      dispatch(saveProfileImage(Cookies.get("profileImage")))
    }
  }, [profileDetail])

  useEffect(() => {
    if (profile == null) {
      setProfileSrc(avtarImg)
    } else {
      console.log("profile src", profile)
      setProfileSrc(profile)
    }
  }, [profile])

  const renderRightComponent = () => {
    return (
      <div className="d-flex align-items-center">
        {
          Cookies.get("jwtToken") !== "" && Cookies.get("jwtToken") !== undefined ? renderRightOptions() : <div />
        }
      </div>
    )
  }

  const renderRightOptions = () => {
    return (
      (userDetail?.name?.trim()?.length > 0 || (window.location.pathname !== '/' && kycPaths.find(path => window.location.pathname.includes(path)) == undefined)) ?
        renderDropdown()
        : renderLogout()
    )
  }

  const renderDropdown = () => {
    return (
      <div className="btn-group ms-4 ud-block">
        <button
          type="button"
          className="btn dropdown-toggle user-dropdown d-flex align-items-center" data-bs-toggle="dropdown" id="defaultDropdown" aria-expanded="false" data-bs-auto-close="true">
          <div className="profile-circle-header">
            <img src={profileSrc} alt="" />
          </div>
          <div className="ud-name">
            {userDetail.name ? userDetail.name : ""}
          </div>
          <span className="ud-arrow"></span>
        </button>
        <ul className={`dropdown-menu dropdown-menu-end ud-menu`} aria-labelledby="defaultDropdown">
          <li>
            <Link to="/my-profile" onClick={uDropdown}>
              <span className="lmenu-icon svg-icon">
                <i className="bi bi-person"></i>
              </span> My Profile
            </Link>
          </li>
          <li>
            <Link onClick={() => {
              uDropdown();
              setTimeout(() => { navigate("/logout") }, 150);
            }}>
              <span className="lmenu-icon logout-icon"></span> Logout
            </Link>
          </li>
        </ul>
      </div>
    )
  }

  const renderLogout = () => {
    return (
      <div className="btn-group ms-4 ud-block">
        <button
          type="button"
          className="btn dropdown-toggle user-dropdown d-flex align-items-center"
        >
          <Link to="/logout">
            <span className="lmenu-icon logout-icon"></span> Logout
          </Link>
        </button>
      </div>
    )
  }

  return (
    <>
      {process.env.REACT_APP_SERVER == "demo" && (
        <div className="demo-server-block">
          YOU ARE USING BACKRR SENDBOX ENVIRONMENT
        </div>
      )}

      <header className="header-wrap">
        <div className="container">
          <div className={`header-block d-flex align-items-center justify-content-between ${loggdenInClass}`}>
            <div className="d-flex align-items-center">
              {Cookies.get("jwtToken") !== "" && Cookies.get("jwtToken") !== undefined && (userDetail?.name?.trim()?.length > 0 || (window.location.pathname !== '/' && kycPaths.find(path => window.location.pathname.includes(path)) == undefined)) && <button
                type="button"
                className="mobile-menuicon"
                onClick={hamButton}
              >
                <img src={hamIcon} alt="icon" />
              </button>}
              <div>
                <Link to={window.location.pathname !== '/' && kycPaths.find(path => window.location.pathname.includes(path)) == undefined ? "/dashboard" : "/"}>
                  {process.env.REACT_APP_SERVER === "demo" ? <img src={backrrLogo} alt="Backrr" /> : <img src={getDomainDetails} alt="Backrr" />}
                </Link>
              </div>
            </div>
            {
              renderRightComponent()
            }
          </div>
        </div>
      </header>
      <div
        className={`overflow-bg ${hamActive ? "show" : ""}`}
        onClick={closeHmButton}
      ></div>
      <div className={`ham-menu-block d-none ${hamActive ? "show" : ""}`}>
        <div
          className="text-end close-icon cursor-pointer"
          onClick={closeHmButton}
        >
          <img src={crossIcon} alt="" />
        </div>
        <DealLeaftMenu
          closeHmButton={closeHmButton}
          showMyBackrr={userDetail?.showMyBackrr}
        />
      </div>

    </>
  );
};

export default Header;
