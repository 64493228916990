import moment from 'moment';
import React from 'react'
import { Link } from 'react-router-dom';
import { GetUserDetailsUpdated } from '../../../../api';
import DashboardLayout from '../../deals/DashboardLayout';
import { Pdf, ViewIcon } from "../../../../assets/img";
import backArrow from '../../../../assets/img/back.svg'

export default function ProfileDocuments() {

    const { data: userData, isLoading: isDocsLoading } = GetUserDetailsUpdated();
    let dealData = userData?.data?.documents

    return (
        <DashboardLayout>
            <div className='header-mobile d-md-none border-b'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div>
                        <Link className="back-btn blue-text d-block" to="/my-profile">
                            <img src={backArrow} alt="arrow" /> Documents
                        </Link>
                    </div>
                </div>
            </div>
            <div className="md-title text-start fw-700 cr-heading header-desktop">
             Documents
            </div>

            <div className="pt-4 pb-4 ps-5 pe-5">
                <div className="pt-0 pb-5 kyc-form-block pyp-block">
                    {
                        dealData?.map(item => {
                            return (
                                <div className='mb-4'>
                                    <div className='card-title md-title mb-3 text-start'>{item.group_name}</div>
                                    <ul className="document-list">
                                        {item.documents.length > 0 &&
                                            item.documents.map((doc) => (
                                                <li className="p-0">
                                                    <Link to={doc.url} target="_blank" key={doc.name} className='d-flex align-items-center pt-3 pb-3'>
                                                        <div className="hm-icon">
                                                            <img src={doc.icon} alt="pdf" />
                                                        </div>
                                                        <div className="ml-15">
                                                            <div className="md-title text-start font-1em">{doc.name}</div>
                                                            <div className="sm-title text-start mt-1">
                                                                {moment(doc.date).format("DD-MMM-YYYY")}
                                                            </div>
                                                        </div>
                                                        <div className="ml-auto">
                                                            <span className="view-icon">
                                                                {/* <img src={ViewIcon} alt="view" /> */}
                                                                <i className='bi bi-eye font-1-2em'></i>
                                                            </span>
                                                        </div>
                                                    </Link>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </DashboardLayout>
    )
}
