import { put, call, takeEvery } from 'redux-saga/effects';
import { types } from '../actions/types';
import { saveRating, getFeedbackCategories, saveFavourite, getPortfolio, changePIN, updateProfile, getDynamicMessages, getFavourites, saveFeedback, getFeedbacksList, getFeedbacksDetail, saveExternalInvestment, updateExternalInvestment, getExternalInvestment, deleteExternalInvestment, getStages, getSectors, getIndustriesTypes, getMyBackrrs, getMyBackrrDeals, saveMyBackrrInterest, getMyBackrrsPortfolio, getDealWiseMyBackrr, getMyAllBackrrsPortfolio, agreeTerms, getKycStatus, getUserInfo, saveComplaint, saveSignedPdf, getCordinates, requestKYCUpdation } from '../apis/Api';

function* saveRatingSaga({ payload, error }) {
  try {
    const response = yield call(saveRating, payload);
    yield put({
      type: types.SEND_REQUEST_SAVE_RATING_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_SAVE_RATING_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* saveFeedbackSaga({ payload, error }) {
  try {
    const response = yield call(saveFeedback, payload);
    yield put({
      type: types.SEND_REQUEST_SAVE_FEEDBACK_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_SAVE_FEEDBACK_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* getFeedbackCategoriesSaga({ payload, error }) {
  try {
    const response = yield call(getFeedbackCategories, payload);
    yield put({
      type: types.SEND_REQUEST_GET_FEEDBACK_CATEGORIES_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_GET_FEEDBACK_CATEGORIES_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* getFeedbacksListSaga({ payload, error }) {
  try {
    const response = yield call(getFeedbacksList, payload);
    yield put({
      type: types.SEND_REQUEST_GET_FEEDBACKS_LIST_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_GET_FEEDBACKS_LIST_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* getFeedbacksDetailSaga({ payload, error }) {
  try {
    const response = yield call(getFeedbacksDetail, payload);
    yield put({
      type: types.SEND_REQUEST_GET_FEEDBACKS_DETAILS_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_GET_FEEDBACKS_DETAILS_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* saveFavouriteSaga({ payload, error }) {
  try {
    const response = yield call(saveFavourite, payload);
    yield put({
      type: types.SEND_REQUEST_SAVE_FAVOURITE_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_SAVE_FAVOURITE_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* getPortfolioSaga({ payload, error }) {
  try {
    const response = yield call(getPortfolio, payload);
    yield put({
      type: types.SEND_REQUEST_GET_PORTFOLIO_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_GET_PORTFOLIO_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* changePINSaga({ payload, error }) {
  try {
    const response = yield call(changePIN, payload);
    yield put({
      type: types.SEND_REQUEST_CHANGE_PIN_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_CHANGE_PIN_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* updateProfileSaga({ payload, error }) {
  try {
    const response = yield call(updateProfile, payload);
    yield put({
      type: types.SEND_REQUEST_UPDATE_PROFILE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_UPDATE_PROFILE_FAILURE,
      payload: error,
    });
    console.log(err);
  }
}

function* getDynamicMessagesSaga({ payload, error }) {
  try {
    const response = yield call(getDynamicMessages, payload);
    yield put({
      type: types.SEND_REQUEST_DYNAMIC_MESSAGES_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_DYNAMIC_MESSAGES_FAILURE,
      payload: error,
    });
    console.log(err);
  }
}

function* getFavouritesSaga({ payload, error }) {
  try {
    const response = yield call(getFavourites, payload);
    yield put({
      type: types.SEND_REQUEST_GET_FAVOURITES_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_GET_FAVOURITES_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* saveExternalInvestmentSaga({ payload, error }) {
  try {
    const response = yield call(saveExternalInvestment, payload);
    yield put({
      type: types.SEND_REQUEST_SAVE_EXTERNAL_INVESTMENT_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_SAVE_EXTERNAL_INVESTMENT_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* saveComplaintSaga({ payload, error }) {
  try {
    const response = yield call(saveComplaint, payload);
    yield put({
      type: types.SEND_REQUEST_SAVE_COMPLAINT_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_SAVE_COMPLAINT_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* updateExternalInvestmentSaga({ payload, error }) {
  try {
    const response = yield call(updateExternalInvestment, payload);
    yield put({
      type: types.SEND_REQUEST_UPDATE_EXTERNAL_INVESTMENT_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_UPDATE_EXTERNAL_INVESTMENT_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* getExternalInvestmentSaga({ payload, error }) {
  try {
    const response = yield call(getExternalInvestment, payload);
    yield put({
      type: types.SEND_REQUEST_GET_EXTERNAL_INVESTMENT_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_GET_EXTERNAL_INVESTMENT_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* deleteExternalInvestmentSaga({ payload, error }) {
  try {
    const response = yield call(deleteExternalInvestment, payload);
    yield put({
      type: types.SEND_REQUEST_DELETE_EXTERNAL_INVESTMENT_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_DELETE_EXTERNAL_INVESTMENT_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* getStagesSaga({ payload, error }) {
  try {
    const response = yield call(getStages, payload);
    yield put({
      type: types.SEND_REQUEST_GET_STAGES_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_GET_STAGES_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* getSectorsSaga({ payload, error }) {
  try {
    const response = yield call(getSectors, payload);
    yield put({
      type: types.SEND_REQUEST_GET_SECTORS_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_GET_SECTORS_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* getIndustriesTypesSaga({ payload, error }) {
  try {
    const response = yield call(getIndustriesTypes, payload);
    yield put({
      type: types.SEND_REQUEST_GET_INDUSTRIES_TYPES_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_GET_INDUSTRIES_TYPES_FAILURE,
      payload: error,
    });
    console.log(err);
  }
}

function* getMyBackrrsSaga({ payload, error }) {
  try {
    const response = yield call(getMyBackrrs, payload);
    yield put({
      type: types.SEND_REQUEST_GET_MY_BACKRRS_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_GET_MY_BACKRRS_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* getMyBackrrDealsSaga({ payload, error }) {
  try {
    const response = yield call(getMyBackrrDeals, payload);
    yield put({
      type: types.SEND_REQUEST_GET_MY_BACKRR_DEALS_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_GET_MY_BACKRR_DEALS_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* saveMyBackrrInterestSaga({ payload, error }) {
  try {
    const response = yield call(saveMyBackrrInterest, payload);
    yield put({
      type: types.SEND_REQUEST_SAVE_MY_BACKRR_INTEREST_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_SAVE_MY_BACKRR_INTEREST_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* getMyBackrrsPortfolioSaga({ payload, error }) {
  try {
    const response = yield call(getMyBackrrsPortfolio, payload);
    yield put({
      type: types.SEND_REQUEST_GET_MY_BACKRR_PORTFOLIO_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_GET_MY_BACKRR_PORTFOLIO_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* getDealWiseMyBackrrSaga({ payload, error }) {
  try {
    const response = yield call(getDealWiseMyBackrr, payload);
    yield put({
      type: types.SEND_REQUEST_GET_DEAL_WISE_MY_BACKRR_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_GET_DEAL_WISE_MY_BACKRR_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* getMyAllBackrrsPortfolioSaga({ payload, error }) {
  try {
    const response = yield call(getMyAllBackrrsPortfolio, payload);
    yield put({
      type: types.SEND_REQUEST_GET_MY_ALL_BACKRR_PORTFOLIO_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_GET_MY_ALL_BACKRR_PORTFOLIO_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* agreeTermsSaga({ payload, error }) {
  try {
    const response = yield call(agreeTerms, payload);
    yield put({
      type: types.SEND_REQUEST_AGREE_TERMS_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_AGREE_TERMS_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* getKycStatusSaga({ payload, error }) {
  try {
    const response = yield call(getKycStatus, payload);
    yield put({
      type: types.SEND_REQUEST_GET_KYC_STATUS_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_GET_KYC_STATUS_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* getUserInfoSaga({ payload, error }) {
  try {
    const response = yield call(getUserInfo, payload);
    yield put({
      type: types.SEND_REQUEST_GET_USER_INFO_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_GET_USER_INFO_FAILURE,
      payload: error,
    });
    console.log(err);
  }
}

function* sendSignedPdfSaga({ payload, error }) {
  try {
    const response = yield call(saveSignedPdf, payload);
    yield put({
      type: types.SEND_REQUEST_SIGNED_DOCUMENTS_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_SIGNED_DOCUMENTS_FAILURE,
      payload: error
    });
    console.log(err);
  }
}

function* getCordinatesSaga({ payload, error }) {
  try {
    const response = yield call(getCordinates, payload);
    yield put({
      type: types.SEND_REQUEST_GET_COORDINATES_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_GET_COORDINATES_FAILURE,
      payload: error,
    });
    console.log(err);
  }
}

function* requestKYCUpdationSaga({ payload, error }) {
  try {
    const response = yield call(requestKYCUpdation, payload);
    yield put({
      type: types.SEND_REQUEST_KYC_UPDATION_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: types.SEND_REQUEST_KYC_UPDATION_FAILURE,
      payload: error,
    });
    console.log(err);
  }
}

export default function* saga() {
  yield takeEvery(types.SEND_REQUEST_SAVE_RATING, saveRatingSaga);
  yield takeEvery(types.SEND_REQUEST_SAVE_FEEDBACK, saveFeedbackSaga);
  yield takeEvery(types.SEND_REQUEST_GET_FEEDBACK_CATEGORIES, getFeedbackCategoriesSaga);
  yield takeEvery(types.SEND_REQUEST_GET_FEEDBACKS_LIST, getFeedbacksListSaga);
  yield takeEvery(types.SEND_REQUEST_GET_FEEDBACKS_DETAILS, getFeedbacksDetailSaga);
  yield takeEvery(types.SEND_REQUEST_SAVE_FAVOURITE, saveFavouriteSaga);
  yield takeEvery(types.SEND_REQUEST_GET_PORTFOLIO, getPortfolioSaga);
  yield takeEvery(types.SEND_REQUEST_CHANGE_PIN, changePINSaga);
  yield takeEvery(types.SEND_REQUEST_UPDATE_PROFILE, updateProfileSaga);
  yield takeEvery(types.SEND_REQUEST_GET_DYNAMIC_MESSAGES, getDynamicMessagesSaga);
  yield takeEvery(types.SEND_REQUEST_GET_FAVOURITES, getFavouritesSaga);
  yield takeEvery(types.SEND_REQUEST_SAVE_EXTERNAL_INVESTMENT, saveExternalInvestmentSaga);
  yield takeEvery(types.SEND_REQUEST_UPDATE_EXTERNAL_INVESTMENT, updateExternalInvestmentSaga);
  yield takeEvery(types.SEND_REQUEST_DELETE_EXTERNAL_INVESTMENT, deleteExternalInvestmentSaga);
  yield takeEvery(types.SEND_REQUEST_GET_EXTERNAL_INVESTMENT, getExternalInvestmentSaga);
  yield takeEvery(types.SEND_REQUEST_GET_STAGES, getStagesSaga);
  yield takeEvery(types.SEND_REQUEST_GET_SECTORS, getSectorsSaga);
  yield takeEvery(types.SEND_REQUEST_GET_INDUSTRIES_TYPES, getIndustriesTypesSaga);
  yield takeEvery(types.SEND_REQUEST_GET_MY_BACKRRS, getMyBackrrsSaga);
  yield takeEvery(types.SEND_REQUEST_GET_MY_BACKRR_DEALS, getMyBackrrDealsSaga);
  yield takeEvery(types.SEND_REQUEST_SAVE_MY_BACKRR_INTEREST, saveMyBackrrInterestSaga);
  yield takeEvery(types.SEND_REQUEST_GET_MY_BACKRR_PORTFOLIO, getMyBackrrsPortfolioSaga);
  yield takeEvery(types.SEND_REQUEST_GET_DEAL_WISE_MY_BACKRR, getDealWiseMyBackrrSaga);
  yield takeEvery(types.SEND_REQUEST_GET_MY_ALL_BACKRR_PORTFOLIO, getMyAllBackrrsPortfolioSaga);
  yield takeEvery(types.SEND_REQUEST_AGREE_TERMS, agreeTermsSaga);
  yield takeEvery(types.SEND_REQUEST_GET_KYC_STATUS, getKycStatusSaga);
  yield takeEvery(types.SEND_REQUEST_GET_USER_INFO, getUserInfoSaga);
  yield takeEvery(types.SEND_REQUEST_SAVE_COMPLAINT, saveComplaintSaga);
  yield takeEvery(types.SEND_REQUEST_SIGNED_DOCUMENTS, sendSignedPdfSaga);
  yield takeEvery(types.SEND_REQUEST_GET_COORDINATES, getCordinatesSaga);
  yield takeEvery(types.SEND_REQUEST_KYC_UPDATION, requestKYCUpdationSaga);

}
