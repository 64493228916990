import React from "react";
import MainLayout from "../layouts/MainLayout";
import ChooseOption from "../views/main/pages/ChooseOption";
import DetailsSubmit from "../views/main/pages/DetailsSubmit";

// new folder userregistration
import PersonalInformation from "../views/main/pages/userRegistration/PersonalInformation";
import ResidentialAddress from "../views/main/pages/userRegistration/ResidentialAddress";
import BankDetails from "../views/main/pages/userRegistration/BankDetails";
import UploadDocuments from "../views/main/pages/userRegistration/UploadDocuments";
import NomineeDetails from "../views/main/pages/userRegistration/NomineeDetails";
import PersonalizeYourProfile from "../views/main/pages/userRegistration/PersonalizeYourProfile";

// verify otp
import OtpMobileVerification from '../views/user/OtpMobileVerification';
import VerifyMobile from '../views/user/VerifyMobile';
import Logout from "../views/auth/Logout";

const KYCRoutes = {
  path: "",
  element: <MainLayout />,
  children: [
    {
      path: "*",
      element: <ChooseOption />,
    },
    {
      path: "",
      element: <ChooseOption />,
    },
    {
      path: "login",
      element: <Logout />,
    },
    {
      path: "choose-option",
      element: <ChooseOption />,
    },
    {
      path: "registration-status",
      element: <DetailsSubmit />,
    },
    {
      path:"personal-information",
      element: <PersonalInformation/>

    },
    {
      path:"residential-address",
      element:<ResidentialAddress/>

    },
    {
      path:"bank-details",
      element:<BankDetails/>
    },
    {
      path:"upload-documents",
      element:<UploadDocuments/>

    },
    {
      path:"nominee-details",
      element:<NomineeDetails/>

    },
    { 
      path:"personalize-your-profile",
      element:<PersonalizeYourProfile/>

    },
    {
      path: "verify-mobile-number",
      element: <VerifyMobile />,
    },
    {
      path: "verify-mobile-otp",
      element: <OtpMobileVerification />,
    },
  ],
};

export default KYCRoutes;
