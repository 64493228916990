import {types} from './types';

export function updateExternalInvestment(data) {
  return {
    type: types.SEND_REQUEST_UPDATE_EXTERNAL_INVESTMENT,
    payload: data,
  };
}

export function updateExternalInvestmentSuccess(data) {
  return {
    type: types.SEND_REQUEST_UPDATE_EXTERNAL_INVESTMENT_SUCCESS,
    payload: data,
  };
}

export function updateExternalInvestmentFailure(error) {
  return {
    type: types.SEND_REQUEST_UPDATE_EXTERNAL_INVESTMENT_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearUpdateExternalInvestment() {
  return {
    type: types.CLEAR_REQUEST_UPDATE_EXTERNAL_INVESTMENT,
    payload: {},
  };
}
