import { combineReducers } from 'redux';

// reducer import
import indianCitizenship from './indianCitizenship';
import foreignCitizenship from './foreignCitizenship';
import indianCorporate from './indianCorporate';
import foreignCorporate from './foreignCorporate';
import indianhuf from './indianhuf';

import userDetails from './userDetails';

import saveRatingReducer from './reducers/saveRatingReducers';
import getFeedbackCategoriesReducer from './reducers/getFeedbackCategoriesReducer';
import saveFavouriteReducer from './reducers/saveFavouriteReducer';
import getPortfolioReducer from './reducers/getPortfolioReducer';
import changePINReducer from './reducers/changePINReducer';
import updateProfileReducer from './reducers/updateProfileReducer';
import saveProfileImageReducers from './reducers/saveProfileImageReducers';
import getDynamicMessagesReducer from './reducers/getDynamicMessagesReducer';
import getFavouritesReducer from './reducers/getFavouritesReducer';
import getFeedbacksDetailReducer from './reducers/getFeedbacksDetailReducer';
import getFeedbacksListReducer from './reducers/getFeedbacksListReducer';
import saveFeedbackReducer from './reducers/saveFeedbackReducer';
import deleteExternalInvestmentReducer from './reducers/deleteExternalInvestmentReducer';
import getExternalInvestmentReducer from './reducers/getExternalInvestmentReducer';
import getSectorsReducer from './reducers/getSectorsReducer';
import getStagesReducer from './reducers/getStagesReducer';
import saveExternalInvestmentReducer from './reducers/saveExternalInvestmentReducer';
import updateExternalInvestmentReducer from './reducers/updateExternalInvestmentReducer';
import getIndustriesTypesReducer from './reducers/getIndustriesTypesReducer';
import getMyBackrrDealsReducer from './reducers/getMyBackrrDealsReducer';
import getMyBackrrsReducer from './reducers/getMyBackrrsReducer';
import getMyAllBackrrsPortfolioReducer from './reducers/getMyAllBackrrsPortfolioReducer';
import getMyBackrrsPortfolioReducer from './reducers/getMyBackrrsPortfolioReducer';
import getDealWiseMyBackrrReducer from './reducers/getDealWiseMyBackrrReducer';
import saveMyBackrrInterestReducer from './reducers/saveMyBackrrInterestReducer';
import agreeTermsReducer from './reducers/agreeTermsReducer';
import getKycStatusReducer from './reducers/getKycStatusReducer';
import getUserInfoReducer from './reducers/getUserInfoReducer';
import complaintReducer from './reducers/complaintReducer';
import sendSignedPdfReducer from './reducers/sendSignedPdfReducer';
import getCordinatesReducer from './reducers/getCordinatesReducer';
import requestKYCUpdatationReducer from './reducers/requestKYCUpdatationReducer';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    userDetails: userDetails,
    indianCitizenship: indianCitizenship,
    foreignCitizenship: foreignCitizenship,
    indianCorporate: indianCorporate,
    foreignCorporate: foreignCorporate,
    indianhuf: indianhuf,
    saveRatingReducer: saveRatingReducer,
    getFeedbackCategoriesReducer: getFeedbackCategoriesReducer,
    saveFavouriteReducer: saveFavouriteReducer,
    getPortfolioReducer: getPortfolioReducer,
    changePINReducer: changePINReducer,
    updateProfileReducer: updateProfileReducer,
    saveProfileImageReducers: saveProfileImageReducers,
    getDynamicMessagesReducer: getDynamicMessagesReducer,
    getFavouritesReducer: getFavouritesReducer,
    getFeedbacksDetailReducer: getFeedbacksDetailReducer,
    getFeedbacksListReducer: getFeedbacksListReducer,
    saveFeedbackReducer: saveFeedbackReducer,
    deleteExternalInvestmentReducer: deleteExternalInvestmentReducer,
    getExternalInvestmentReducer: getExternalInvestmentReducer,
    getSectorsReducer: getSectorsReducer,
    getStagesReducer: getStagesReducer,
    saveExternalInvestmentReducer: saveExternalInvestmentReducer,
    updateExternalInvestmentReducer: updateExternalInvestmentReducer,
    getIndustriesTypesReducer: getIndustriesTypesReducer,
    getMyBackrrDealsReducer: getMyBackrrDealsReducer,
    getMyBackrrsReducer: getMyBackrrsReducer,
    getMyAllBackrrsPortfolioReducer: getMyAllBackrrsPortfolioReducer,
    getMyBackrrsPortfolioReducer: getMyBackrrsPortfolioReducer,
    getDealWiseMyBackrrReducer: getDealWiseMyBackrrReducer,
    saveMyBackrrInterestReducer: saveMyBackrrInterestReducer,
    agreeTermsReducer: agreeTermsReducer,
    getKycStatusReducer: getKycStatusReducer,
    getUserInfoReducer: getUserInfoReducer,
    complaintReducer: complaintReducer,
    sendSignedPdfReducer: sendSignedPdfReducer,
    getCordinatesReducer: getCordinatesReducer,
    requestKYCUpdatationReducer: requestKYCUpdatationReducer,
});

export default reducer;