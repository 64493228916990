import {types} from '../actions/types';
const initialState = {
  loading: false,
  data: {},
  error: {},
};

const updateProfileReducer = (
  state = initialState,
  {type, payload},
) => {
  switch (type) {
    case types.SEND_REQUEST_UPDATE_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case types.SEND_REQUEST_UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case types.SEND_REQUEST_UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        data: {},
        error: payload,
        loading: false,
      };
    case types.CLEAR_SEND_REQUEST_UPDATE_PROFILE:
      return {
        ...state,
        data: {},
        loading: false,
      };
    default:
      return state;
  }
};

export default updateProfileReducer;
