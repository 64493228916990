import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  INTITIALIZE_INDIAN_CITIZEN,
  SET_USER_TYPE,
  INTITIALIZE_INDIAN_CORPORATE,
  INTITIALIZE_FOREIGN_CITIZEN,
  INTITIALIZE_FOREIGN_CORPORATE,
  INTITIALIZE_INDIAN_CITIZEN_HUF,
} from "../../store/actions";
import { saveProfileImage } from "../../store/actions/saveProfileImageAction";

export const GetUserDetails = ({ isApiSuccess }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiName = "Get-User-Details";
  return useQuery(
    [apiName, isApiSuccess],
    async () => {
      return await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_BASE_URL_WEB}/investor/user-info`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtToken")}`,
        },
      });
    },
    {
      enabled: isApiSuccess,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
      },
      onError: (err) => {
      },
      onSuccess: (data) => {
        console.log("data.data.data", data);
        const finalData = data.data;
        const fName = finalData.data.first_name
          ? finalData.data.first_name
          : "";

        const lName = finalData.data.last_name ? finalData.data.last_name : "";
        const mName = finalData.data.middle_name ? finalData.data.middle_name : "";

        const saveUserData = {
          name: fName + " " + lName,
          mob: finalData.data.mobile_number || "",
          email: finalData.data.email ? finalData.data.email : "",
          fullName: fName + " " + mName + " " + lName,
          showMyBackrr: finalData.show_mybackkr,
          isAdminLoggedin: finalData.isAdminLoggedin
        };

        console.log("saveUserData", saveUserData)

        Cookies.set("userDetail", JSON.stringify(saveUserData));

        Cookies.set("syndicateName", finalData.syndicate_name);

        Cookies.set("profileImage", finalData.data.profile_image)

        Cookies.set("validationFields", data.validation_fields)

        dispatch(saveProfileImage(finalData.data.profile_image))

        const checkUSerType = finalData.data.user_individual_type;

        dispatch({ type: SET_USER_TYPE, payload: checkUSerType });
        const checkStatus = finalData.data.master_status_id[0]?.values[0]?.name;
        if (checkStatus !== "Approved" || Cookies.get("redirectPath") != null) {
        switch (checkUSerType) {
          case "indian_citizen":
            dispatch({
              type: INTITIALIZE_INDIAN_CITIZEN,
              payload: finalData,
            });
            break;

          case "indian_corporate":
            dispatch({
              type: INTITIALIZE_INDIAN_CORPORATE,
              payload: finalData,
            });
            break;

          case "foreign_citizen":
            dispatch({
              type: INTITIALIZE_FOREIGN_CITIZEN,
              payload: finalData,
            });
            break;

          case "foreign_corporate":
            dispatch({
              type: INTITIALIZE_FOREIGN_CORPORATE,
              payload: finalData,
            });
            break;
          case "huf":
            dispatch({
              type: INTITIALIZE_INDIAN_CITIZEN_HUF,
              payload: finalData,
            });
            break;
          default:
        }
        }

        if (Cookies.get("redirectPath") != null) {
          const redirectPath = Cookies.get("redirectPath");
          if (redirectPath == "nominee") {
            Cookies.set("checkUSerType", checkUSerType);
            navigate("/verify-mobile-number");
          } else if (redirectPath == "demat") {
            Cookies.set("checkUSerType", checkUSerType);
            navigate("/my-profile/demat-account");
          } else {
            navigate("/");
          }
        } else {
          if (data.data.isAdminLoggedin) {
            // return data.isAdminLoggedin
          } else if (checkStatus === "Approved" || fName !== "") {
            // } else if (checkStatus === "Approved") {
            navigate("/dashboard");
          } else {
            const redi = RedirectUser({
              checkUSerType: checkUSerType,
              page: finalData.data.page ? finalData.data.page : 0,
            });
            console.log(redi);

            if (redi) {
              if (redi === '/choose-option') {
                navigate(redi, { replace: true })
              } else {
                navigate(redi);
              }
            }
          }
        }
      },
    }
  );
};

export const RedirectUser = ({ checkUSerType, page }) => {
  let redirect = "";
  if (page == "0") {
    redirect = "/choose-option";
    return redirect;
  } else {
    page = parseInt(page) + 1;
  }
  switch (checkUSerType) {
    case "indian_citizen":
      if (page == "1") {
        redirect = "/personal-information";
      } else if (page == "2") {
        redirect = "/residential-address";
      } else if (page == "3") {
        redirect = "/bank-details";
      } else if (page == "4") {
        redirect = "/upload-documents";
      } else if (page == "5") {
        redirect = "/indian-citizenship/more-about-you";
      } else if (page == "6") {
        redirect = "/nominee-details";
      } else if (page == "7") {
        redirect = "/personalize-your-profile";
      } else {
        redirect = "/registration-status";
      }
      break;
    case "huf":
      if (page == "1") {
        redirect = "/personal-information";
      } else if (page == "2") {
        redirect = "/residential-address";
      } else if (page == "3") {
        redirect = "bank-details";
      } else if (page == "4") {
        redirect = "/upload-documents";
      } else if (page == "5") {
        redirect = "/huf/more-about-you";
      } else if (page == "6") {
        redirect = "/nominee-details";
      } else if (page == "7") {
        redirect = "/personalize-your-profile";
      } else {
        redirect = "/registration-status";
      }
      break;


    case "indian_corporate":
      if (page == "1") {
        redirect = "/personal-information";
      } else if (page == "2") {
        redirect = "/registered-address";
      } else if (page == "3") {
        redirect = "/bank-details";
      } else if (page == "4") {
        redirect = "/upload-documents";
      } else if (page == "5") {
        redirect = "/indian-corporate/more-about-you";
      } else if (page == "6") {
        redirect = "/personalize-your-profile";
      } else {
        redirect = "/registration-status";
      }

      break;

    case "foreign_citizen":
      if (page == "1") {
        redirect = "/personal-information";
      } else if (page == "2") {
        redirect = "/residential-address";
      } else if (page == "3") {
        redirect = "/bank-details";
      } else if (page == "4") {
        redirect = "/upload-documents";
      } else if (page == "5") {
        redirect = "/foreign-citizenship/more-about-you";
      } else if (page == "6") {
        redirect = "/nominee-details";
      } else if (page == "7") {
        redirect = "/personalize-your-profile";
      } else {
        redirect = "/registration-status";
      }
      break;

    case "foreign_corporate":
      if (page == "1") {
        redirect = "/personal-information";
      } else if (page == "2") {
        redirect = "/residential-address";
      } else if (page == "3") {
        redirect = "/bank-details";
      } else if (page == "4") {
        redirect = "/upload-documents";
      } else if (page == "5") {
        redirect = "/foreign-corporate/more-about-you";
      } else if (page == "6") {
        redirect = "/personalize-your-profile";
      } else {
        redirect = "/registration-status";
      }
      break;
    default:
      redirect = "/choose-option";
  }

  return redirect;
};


/* ******** update user details *********** */


export const GetUserDetailsUpdated = () => {
  return useQuery(
    ["investor-user-info-updatedooooee"],
    async () => {
      return await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_BASE_URL_WEB}/investor/user-info`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtToken")}`,
        },
      });
    },
    {
      select: (data) => {
        const user_info = data.data;
        return user_info;
      },
      refetchOnWindowFocus: false,
    }
  );
};
